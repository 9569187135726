var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserCompanyTariffs",
      attrs: { "data-test-id": "user_company_tariffs-view" },
    },
    [
      _c("h3", { staticClass: "flex-grow-1 mb-2" }, [
        _vm._v(" Company tariff "),
      ]),
      _c(
        "ui-card",
        {
          staticClass: "w-100 mb-4",
          attrs: {
            title: "Current tariffs",
            "data-test-id": "current_tariffs-card",
          },
        },
        [
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm.userTariffsStatus.LOADING
                ? _c("ui-loader", {
                    attrs: { absolute: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c("TableComponent", {
                staticClass: "mt-1",
                attrs: {
                  schema: _vm.tariffSchema(),
                  data: _vm.currentTariffs,
                  "empty-label": "No tariff",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }