<script>
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import { ContentCellComponent } from '@/components';
import UserCoverContractForm from './UserCoverContractForm';

export default {
  name: 'UserCover',
  components: {
    ContentCellComponent,
    UserCoverContractForm,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      insurance: state => state.insurance.data,
      insuranceStatus: state => state.insurance.STATUS,
    }),
    isInsuranceAutomaticRenewal() {
      return get(this.insurance, 'autoRenew');
    },
    hasAnnualCover() {
      return get(this.user, 'notes.hasAnnualCover');
    },
  },
  async created() {
    await this.getUserInsurance({ userUuid: this.user.uuid });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getUser',
      'getUserInsurance',
    ]),
    get,
    async onCoverContract() {
      await this.getUser(this.user.uuid);
      await this.getUserInsurance({ userUuid: this.user.uuid });
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_cover-view"
    class="UserCover"
  >
    <h1 class="flex-grow-1 mb-2">
      Cover
    </h1>
    <ui-card
      class="w-100 mb-4 position-relative"
      data-test-id="cover-card"
    >
      <ui-loader
        v-if="insuranceStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center">
        <h3
          class="emobg-color-ink emobg-font-weight-semibold"
          data-test-id="title"
        >
          Annual cover
        </h3>

        <ui-button
          :disabled="hasAnnualCover"
          @clickbutton="isModalOpen = true"
        >
          Contract annual cover
        </ui-button>
      </div>

      <div class="row d-flex mt-3">
        <ContentCellComponent
          :value="hasAnnualCover ? get(insurance, 'createdAt') : FALLBACK_MESSAGE.dash"
          label="Start date"
          class="col-md-6 col-lg-3"
        />
        <div class="col-md-6 col-lg-3">
          <ContentCellComponent
            :value="hasAnnualCover ? get(insurance, 'expiresAt') : FALLBACK_MESSAGE.dash"
            :label="isInsuranceAutomaticRenewal ? 'Renewal date' : 'End date'"
            class="pb-1"
          />
          <span
            v-if="hasAnnualCover && insurance && !isInsuranceAutomaticRenewal"
            class="emobg-color-ink-light"
          >
            Renewal cancelled
          </span>
        </div>

        <div
          v-if="isInsuranceAutomaticRenewal"
          class="col-md-6 col-lg-3 align-self-center"
        >
          <ui-button
            :color="COLORS.danger"
            @clickbutton="isModalOpen = true"
          >
            Cancel automatic renewal
          </ui-button>
        </div>
      </div>
    </ui-card>

    <UserCoverContractForm
      v-if="isModalOpen"
      :is-cancel-renewal="isInsuranceAutomaticRenewal"
      :callback="onCoverContract"
      @closeModal="isModalOpen = false"
    />
  </div>
</template>
