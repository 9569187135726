<script>
import filter from 'lodash/filter';
import upperFirst from 'lodash/upperFirst';
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import {
  contentCells,
  userListFacets,
} from './config/usersTable';

export default {
  name: 'UsersView',
  components: {
    MuiAlgoliaList,
    PageView,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells.bind(this)();
    this.userListFacets = userListFacets.bind(this)();
    this.exportColumns = filter(this.contentCells, field => field.title !== 'Alerts');
  },
  methods: {
    upperFirst,
  },
};
</script>

<template>
  <PageView class="UsersView">
    <h1 class="mb-3">
      {{ upperFirst($t("Common.Business.users")) }}
    </h1>

    <MuiAlgoliaList
      :facets="userListFacets"
      :table-config="contentCells"
      :export-columns="exportColumns"
      :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.users })"
      :index="ALGOLIA_INDEXES.users"
      is-export-enabled
    />
  </PageView>
</template>
