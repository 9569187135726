var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "UserDetailsView d-flex flex-column flex-fill",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { label: "Loading user..." } })
        : _c(
            "div",
            [
              _c(
                "PageView",
                { staticClass: "emobg-background-color-white" },
                [
                  _c("UserDetailsHeader", {
                    attrs: { "on-success": _vm.initialLoad },
                  }),
                ],
                1
              ),
              _vm.areTabsReady
                ? _c("PageTabs", {
                    attrs: { tabs: _vm.tabs, "has-overview": "" },
                  })
                : _vm._e(),
              _c(
                "PageView",
                { attrs: { "is-tab-content": "" } },
                [
                  _c(
                    "Transition",
                    { attrs: { name: "page", mode: "out-in" } },
                    [_c("RouterView")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }