import { render, staticRenderFns } from "./UserDetailsHeader.vue?vue&type=template&id=6cee013e&"
import script from "./UserDetailsHeader.vue?vue&type=script&lang=js&"
export * from "./UserDetailsHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6cee013e')) {
      api.createRecord('6cee013e', component.options)
    } else {
      api.reload('6cee013e', component.options)
    }
    module.hot.accept("./UserDetailsHeader.vue?vue&type=template&id=6cee013e&", function () {
      api.rerender('6cee013e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/CRM/Users/Details/components/UserDetailsHeader/UserDetailsHeader.vue"
export default component.exports