var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UserAccount", attrs: { "data-test-id": "user_account" } },
    [
      _c("h1", { staticClass: "flex-grow-1 mb-2" }, [_vm._v(" User account ")]),
      _c("AccountSettings", { staticClass: "d-block mb-4" }),
      _c("PersonalSettings", { staticClass: "mb-4" }),
      _c("UserBadges", { staticClass: "mb-4" }),
      _c("DrivingLicenseCard", { staticClass: "mb-4" }),
      _c("DocumentsCard", { staticClass: "mb-4" }),
      _c("AuthenticationCard"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }