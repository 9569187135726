<script>
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import toUpper from 'lodash/toUpper';
import upperCase from 'lodash/upperCase';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiDatePicker } from '@emobg/vue-base';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  COUNTRIES_ISO_CODES,
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  ITALY_COMPANY_VALIDATION_PATTERN,
  sentenceCase,
} from '@emobg/web-utils';
import { IDENTITY_TYPES_SCOPES } from '@domains/CRM/IdentityTypes/store/IdentityTypesModule';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton,
  GenericModalComponent,
  GoogleLocationSelect,
  StoreNotificationComponent,
} from '@/components';
import { GOOGLE_ADDRESS_VALIDATIONS, PATTERN_INPUT_VALIDATIONS } from '@/utils';
import {
  CODICE_FISCALE_MAX_LENGTH,
  IDENTITY_CARD_KEY_ERRORS,
  IDENTITY_TYPE_KEY_MAPPER,
  IDENTITY_TYPE_VALIDATIONS_BY_COUNTRY,
} from './const/personalSettings.const';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'EditPersonalSettings',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiDatePicker,
    MuiInputText,
    GoogleLocationSelect,
    MuiValidationWrapper,
    MuiAlgoliaSelect,
    MuiSelect,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isInputItaly: false,
      inputs: {
        titleId: null,
        firstName: null,
        lastName: null,
        birthdate: null,
        addressStreet: null,
        addressStreet2: null,
        extraDocuments: {
          codiceFiscale: null,
        },
        identityIssuingCountryUuid: null,
        identityType: null,
        identityNumber: '',
      },
      countrySelected: '',
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state[USER_SCOPES.detail].data,
      editSettingsStatus: state => state[USER_SCOPES.editSettings].STATUS,
      editSettingsErrorKey: state => get(state[USER_SCOPES.editSettings], 'error.key'),
    }),
    ...mapState(DOMAINS_MODEL.crm.identityTypes, {
      identityTypes: state => state[IDENTITY_TYPES_SCOPES.byCountry].data,
    }),
    hasSameValues() {
      return isEqual(this.formatData(this.inputs), this.formatData(this.originalInputs));
    },
    showCodiceFiscale() {
      const addressCountryCode = get(this, 'user.info.addressCountryCode');
      const codiceFiscale = get(this, 'user.info.extraDocuments.codiceFiscale');
      return codiceFiscale && addressCountryCode === COUNTRIES_ISO_CODES.italy;
    },
    identityTypeValidation() {
      const countryCode = get(this.countrySelected, 'code');
      const validationByCountry = get(IDENTITY_TYPE_VALIDATIONS_BY_COUNTRY, countryCode, {});
      return get(validationByCountry, this.inputs.identityType, {});
    },
    identityNumberValidation() {
      const { format, message } = this.identityTypeValidation;

      let patternValidation = {};
      if (format) {
        patternValidation = {
          isPattern: {
            pattern: format,
            message,
          },
        };
      }

      return {
        ...patternValidation,
        ...this.validationsForHospedajesFields,
      };
    },
    validationsForHospedajesFields() {
      return this.inputs.identityIssuingCountryUuid
        ? { isRequired: true }
        : {};
    },
  },
  async created() {
    this.titlesIndex = ALGOLIA_INDEXES.titles;
    this.countriesIndex = ALGOLIA_INDEXES.countries;
    this.IDENTITY_CARD_KEY_ERRORS = IDENTITY_CARD_KEY_ERRORS;
    this.IDENTITY_TYPE_KEY_MAPPER = IDENTITY_TYPE_KEY_MAPPER;
    this.CODICE_FISCALE_MAX_LENGTH = CODICE_FISCALE_MAX_LENGTH;
    this.ITALY_COMPANY_VALIDATION_PATTERN = ITALY_COMPANY_VALIDATION_PATTERN;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.GOOGLE_ADDRESS_VALIDATIONS = GOOGLE_ADDRESS_VALIDATIONS;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    const birthday = get(this, 'user.info.birthdate');
    this.inputs.titleId = get(this, 'user.info.title.id');
    this.inputs.firstName = get(this, 'user.info.firstName');
    this.inputs.lastName = get(this, 'user.info.lastName');
    this.inputs.birthdate = birthday ? moment(birthday) : '';
    this.inputs.addressStreet = get(this, 'user.info.addressStreet1');
    this.inputs.addressStreet2 = get(this, 'user.info.addressStreet2');
    this.inputs.identityIssuingCountryUuid = get(this, 'user.info.identityIssuingCountryUuid');
    this.inputs.identityType = get(this, 'user.info.identityType');
    this.inputs.identityNumber = get(this, 'user.info.identityNumber');
    if (this.showCodiceFiscale) {
      this.inputs.extraDocuments.codiceFiscale = get(this, 'user.info.extraDocuments.codiceFiscale');
    }
    this.originalInputs = cloneDeep(this.inputs);
    this.resetState({ scopes: [USER_SCOPES.editSettings] });

    if (this.inputs.identityIssuingCountryUuid) {
      await this.getIdentityTypesByCountry(this.inputs.identityIssuingCountryUuid);
    }

    this.$watch('inputs.identityIssuingCountryUuid', async function () {
      this.inputs.identityType = null;
      this.setIdentityTypeData({
        scope: IDENTITY_TYPES_SCOPES.byCountry,
        value: [],
      });

      this.$nextTick(() => {
        this.$refs.identityTypeSelect.$el.MuiValidationManager.reset();
      });

      await this.getIdentityTypesByCountry(this.inputs.identityIssuingCountryUuid);
    });
    this.$watch('inputs.identityType', () => {
      this.inputs.identityNumber = '';
      this.$nextTick(() => {
        this.$refs.identityNumberInput.$el.MuiValidationManager.reset();
      });
    });
  },
  onBeforeUnmount() {
    this.setIdentityTypeData({
      scope: IDENTITY_TYPES_SCOPES.byCountry,
      value: [],
    });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.identityTypes, {
      setIdentityTypeData: 'setData',
    }),
    ...mapActions(DOMAINS_MODEL.crm.users, ['patchUserInformation']),
    ...mapActions(DOMAINS_MODEL.crm.identityTypes, ['getIdentityTypesByCountry']),
    get,
    map,
    sentenceCase,
    toUpper,
    updateLocation(place) {
      const addressComponents = get(place, 'addressComponents');
      this.isInputItaly = !!find(addressComponents, { shortName: COUNTRIES_ISO_CODES.italy });
      if (this.isInputItaly) {
        this.$nextTick(() => {
          this.$refs.codiceFiscaleInput.$emit('input', this.inputs.extraDocuments.codiceFiscale);
        });
      }
    },
    formatData(data) {
      const request = cloneDeep(data);
      request.birthdate = moment(request.birthdate).format(DATE_FORMAT.date);
      if (!this.isInputItaly) {
        request.extraDocuments = {};
      }
      return request;
    },
    isValidBirthdate(date) {
      return {
        isValid: moment.isMoment(date) ? date.isBefore(moment()) : false,
        message: 'Birthdate should not be in the future',
      };
    },
    setCodiceFiscale(value) {
      this.inputs.extraDocuments.codiceFiscale = upperCase(value).replace(/\s/g, '');
    },
    async saveSettings() {
      await this.patchUserInformation({
        userUuid: this.user.uuid,
        data: this.formatData(this.inputs),
      });

      if (!this.editSettingsStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Personal information successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    title="Edit personal information"
    :header="{ isClosable: true }"
    class="EditPersonalSettings"
    data-test-id="edit_personal_settings-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.editSettings"
        :action="IDENTITY_CARD_KEY_ERRORS.invalidCardValue === editSettingsErrorKey
          ? 'save personal information because the entered ID number is not correct'
          : ''
        "
        is-editing
        element="personal information"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="mb-4">
          <MuiAlgoliaSelect
            v-model="inputs.titleId"
            v-validate="{
              isRequired: true,
            }"
            :title="
              title => `${get(title.translations, `${user.info.language.code}`, sentenceCase(title.internal_name))} (${sentenceCase(title.internal_name)})`
            "
            :index="titlesIndex"
            label="Title*"
            path-value="id"
            class="w-100"
            placeholder="Select"
            name="title"
            data-test-id="title-select"
          />
        </div>
        <div class="mb-4">
          <MuiInputText
            v-model="inputs.firstName"
            v-validate="{
              isRequired: true,
            }"
            label="First name*"
            placeholder="Enter"
            data-test-id="first_name-input"
            name="first_name"
            class="w-100"
          />
        </div>

        <div class="mb-4">
          <MuiInputText
            v-model="inputs.lastName"
            v-validate="{
              isRequired: true,
            }"
            label="Last name*"
            placeholder="Enter"
            data-test-id="last_name-input"
            name="last_name"
            class="w-100"
          />
        </div>

        <div class="mb-4">
          <MuiDatePicker
            v-model="inputs.birthdate"
            v-validate="{
              isRequired: true,
              isValidBirthdate,
            }"
            :date-format-key="DATE_FORMAT_KEYS.dob"
            skip-time-step
            data-test-id="birthdate-select"
            label="Date of birth*"
            placeholder="Enter date of birth"
            name="birthdate"
            class="w-100"
          />
        </div>

        <div class="mb-4">
          <MuiAlgoliaSelect
            v-model="inputs.identityIssuingCountryUuid"
            :index="countriesIndex"
            :title="country => country.name"
            label="ID issuing country"
            path-value="uuid"
            class="w-100"
            placeholder="Select ID issuing country"
            name="identityIssuingCountryUuid"
            data-test-id="issuing_country_uuid-select"
            @selected="country => countrySelected = country"
          />
        </div>

        <div class="row mb-4">
          <div class="col-6">
            <MuiSelect
              ref="identityTypeSelect"
              v-model="inputs.identityType"
              v-validate="validationsForHospedajesFields"
              :options="map(
                identityTypes,
                identityType => ({
                  label: get(
                    IDENTITY_TYPE_KEY_MAPPER,
                    identityType.translationKey,
                    sentenceCase(identityType.translationKey),
                  ),
                  value: identityType.identityType,
                }),
              )"
              :disabled="!inputs.identityIssuingCountryUuid || !identityTypes.length"
              label="Document type*"
              name="identity_type"
              class="w-100"
            />
          </div>
          <div class="col-6">
            <MuiInputText
              ref="identityNumberInput"
              v-validate="identityNumberValidation"
              :model-value="inputs.identityNumber"
              :disabled="!inputs.identityType"
              label="ID number*"
              name="identity_number"
              @input="value => inputs.identityNumber = identityTypeValidation.isToUpper ? toUpper(value) : value"
            />
            <label
              v-if="identityTypeValidation.example"
              class="d-block emobg-color-ink-light emobg-font-x-small mt-1"
            >
              Example: {{ identityTypeValidation.example }}
            </label>
          </div>
        </div>

        <div class="mb-4">
          <GoogleLocationSelect
            v-model="inputs.addressStreet"
            v-validate.update:place="{
              isRequired: true,
              isValidAddress: GOOGLE_ADDRESS_VALIDATIONS.hasRequiredComponents,
            }"
            placeholder="Select"
            data-test-id="address-select"
            label="Address*"
            @update:place="updateLocation"
            @created:place="updateLocation"
          />
        </div>

        <div :class="{ 'mb-4': isInputItaly }">
          <MuiInputText
            v-model="inputs.addressStreet2"
            label="Floor, door, apartment..."
            placeholder="Enter address"
            data-test-id="address_2-input"
            name="address_2"
            class="w-100"
          />
        </div>
        <MuiInputText
          v-if="isInputItaly"
          ref="codiceFiscaleInput"
          v-validate="{
            isRequired: true,
            isCorrectLength: (value) => ({
              isValid: value.length === CODICE_FISCALE_MAX_LENGTH,
              message: `Must have ${CODICE_FISCALE_MAX_LENGTH} characters`
            }),
            isCodiceFiscaleValid: (value) => ({
              isValid: !!value.match(ITALY_COMPANY_VALIDATION_PATTERN.userCodiceFiscale),
              message: 'Letters and numbers must be in the right order (example: RSSMRA80A01H501U)',
            }),
            isPattern: PATTERN_INPUT_VALIDATIONS.alphanumeric,
          }"
          :model-value="inputs.extraDocuments.codiceFiscale"
          :caption="`Fiscal code is a unique combination of ${CODICE_FISCALE_MAX_LENGTH} numbers and letters (example: RSSMRA80A01H501U)`"
          label="Fiscal code"
          placeholder="Enter"
          data-test-id="codice_fiscale"
          name="codice_fiscale"
          class="w-100"
          @input="setCodiceFiscale"
        />
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="editSettingsStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveSettings"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
