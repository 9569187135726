var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-badge",
    { attrs: { color: _vm.USER_STATUS_COLORS[_vm.status.name] } },
    [_vm._v(" " + _vm._s(_vm.status.description) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }