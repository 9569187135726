<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'UserCoverContractForm',
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    isCancelRenewal: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      coverFormStatus: state => state.coverForm.STATUS,
    }),
    title() {
      return this.isCancelRenewal
        ? 'Cancel the renewal of annual cover for this user?'
        : 'Contract the annual cover for this user?';
    },
    text() {
      return this.isCancelRenewal
        ? 'This cannot be undone.'
        : 'The cover will start today.';
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'postContractAnnualCover',
      'putCancelAutoRenewalCover',
    ]),
    async userCoverRequest() {
      const request = this.isCancelRenewal
        ? this.putCancelAutoRenewalCover
        : this.postContractAnnualCover;

      await request({ userUuid: this.user.uuid });

      if (!this.coverFormStatus.ERROR) {
        const message = this.isCancelRenewal
          ? 'Renewal of annual cover successfully <span class="emobg-font-weight-semibold">cancelled</span>'
          : 'Annual cover successfully <span class="emobg-font-weight-semibold">contracted</span>';
        this.$notify({
          message,
          textAction: '',
        });

        this.callback();
      }
      this.$emit('closeModal');
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="UserCoverContractForm"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :text="text"
        :title="title"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :color="isCancelRenewal ? COLORS.danger : COLORS.primary"
        :loading="coverFormStatus.LOADING"
        data-test-id="request-button"
        @clickbutton="userCoverRequest"
      >
        {{ isCancelRenewal ? 'Cancel automatic renewal' : 'Contract annual cover' }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
