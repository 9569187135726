<script>
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import { PHONE_VALIDATIONS } from '@/utils';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  PhoneInputComponent,
  StoreNotificationComponent,
} from '@/components';
import UserStatusBadge from '../../../components/UserDetailsHeader/components/UserStatusBadge';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'EditBusinessProfile',
  directives: {
    Validate,
  },
  components: {
    PhoneInputComponent,
    UserStatusBadge,
    CancelButton,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiInputText,
    StoreNotificationComponent,
    ContentCellComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        email: null,
        phone: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      editSettingsStatus: state => get(state, 'editSettings.STATUS'),
    }),
    hasSameValues() {
      return isEqual(this.originalInputs, this.inputs);
    },
  },
  created() {
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.USER_SCOPES = USER_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.inputs.email = get(this, 'user.profiles.business.email');
    this.inputs.phone = get(this, 'user.profiles.business.phoneNumber');
    this.originalInputs = cloneDeep(this.inputs);
    this.resetState({ scopes: [USER_SCOPES.editSettings] });
  },
  methods: {
    companyProfileUrl,
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['putUserBusinessProfile']),
    sentenceCase,
    get,
    async saveSettings() {
      await this.putUserBusinessProfile({
        userUuid: this.user.uuid,
        data: this.inputs,
      });

      if (!this.editSettingsStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Business profile successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    title="Edit business profile"
    :header="{ isClosable: true }"
    class="EditGeneralSettings"
    data-test-id="edit_business_profile-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.editSettings"
        is-editing
        element="business profile"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <ContentCellComponent
          label="Business status"
          class="mb-5"
        >
          <UserStatusBadge :status="user.status.business" />
        </ContentCellComponent>

        <div class="mb-4">
          <MuiInputText
            v-model="inputs.email"
            v-validate="{
              isRequired: true,
              isEmail: true,
            }"
            label="Notification email*"
            class="w-100"
            placeholder="Enter business email"
            data-test-id="email"
            name="email"
          />
        </div>

        <div class="mb-4">
          <PhoneInputComponent
            v-model="inputs.phone"
            v-validate="{
              isRequired: true,
              isValidPhone: PHONE_VALIDATIONS.isValidPhone,
            }"
            label="Notification phone number*"
            class="w-100"
            placeholder="Enter business phone"
            data-test-id="phone"
          />
        </div>

        <ContentCellComponent
          label="Employee user role"
          class="mb-4"
        >
          <p
            v-for="role in get(user, 'profiles.business.frontofficeRoles', [])"
            :key="role"
          >
            {{ sentenceCase(role) }}
          </p>
        </ContentCellComponent>

        <ContentCellComponent label="Company name">
          <a
            v-if="get(user, 'profiles.business.company.uuid')"
            :href="companyProfileUrl(user.profiles.business.company.uuid)"
            target="_blank"
            class="emobg-link-primary emobg-body-2"
          >
            {{ user.profiles.business.company.name }}
          </a>
        </ContentCellComponent>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="editSettingsStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveSettings"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
