<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import size from 'lodash/size';
import intersection from 'lodash/intersection';
import filter from 'lodash/filter';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import { navigationErrorHandler } from '@emobg/web-utils';
import { PageTabs, PageView } from '@/components';
import { USER_TABS } from './const/tabs';
import UserDetailsHeader from './components/UserDetailsHeader/UserDetailsHeader';
import { USER_SCOPES } from '../store/UsersModule';

export default {
  name: 'UserDetailsView',
  components: {
    PageTabs,
    PageView,
    UserDetailsHeader,
  },
  beforeRouteLeave(_to, _from, next) {
    this.setData({ scope: USER_SCOPES.detail, value: null });
    next();
  },
  props: {
    userUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      areTabsReady: false,
      tabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
    }),
  },
  watch: {
    async userUuid() {
      await this.initialLoad();
    },
  },
  async created() {
    await this.initialLoad();
  },
  methods: {
    size,
    ...mapMutations(DOMAINS_MODEL.crm.users, ['setData']),
    ...mapActions(DOMAINS_MODEL.crm.users, ['getUser']),
    updateTabsVisibility() {
      this.areTabsReady = false;
      this.tabs = filter(USER_TABS, item => intersection(this.userPermissions, item.permissions).length);
      this.areTabsReady = true;
    },
    async initialLoad() {
      this.isLoading = true;
      await this.getUser(this.userUuid);

      if (!this.user) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }

      this.updateTabsVisibility();
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div
    :class="[
      'UserDetailsView d-flex flex-column flex-fill',
      {
        'justify-content-center': isLoading,
      }
    ]"
  >
    <ui-loader
      v-if="isLoading"
      label="Loading user..."
    />
    <div v-else>
      <PageView class="emobg-background-color-white">
        <UserDetailsHeader :on-success="initialLoad" />
      </PageView>
      <PageTabs
        v-if="areTabsReady"
        :tabs="tabs"
        has-overview
      />
      <PageView is-tab-content>
        <Transition
          name="page"
          mode="out-in"
        >
          <RouterView />
        </Transition>
      </PageView>
    </div>
  </div>
</template>
