<script>
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import join from 'lodash/join';
import { mapActions, mapState } from 'vuex';
import { PROFILES_TYPES, USER_STATUS } from '@domains/CRM/Users/constants/userStatus';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { crm as crmErrors } from '@domains/CRM/const/errorKeys';
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { Cancel } from '@/constants/modalButtons.const';

export default {
  name: 'UserStatusModal',
  components: {
    GenericModalComponent,
    MuiAlgoliaSelect,
    StoreNotificationComponent,
  },
  props: {
    profile: {
      type: String,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      inputs: {
        statusId: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      statusError: state => state.statusForm.STATUS.ERROR,
    }),
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.isLoading,
          },
          text: 'Save',
          listeners: {
            click: this.userStatusRequest,
          },
        },
      ];
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.crmErrors = crmErrors;

    this.profileCapitalized = capitalize(this.profile);

    const personalStatusNotEditables = [
      USER_STATUS.blockedByScreening,
      USER_STATUS.waitingForAnonymization,
      USER_STATUS.anonymized,
    ];
    const businessStatuNotEditables = [USER_STATUS.anonymized];
    const groupToFilter = this.profile === PROFILES_TYPES.personal ? 'users' : 'employees';
    const namesToFilter = join((this.profile === PROFILES_TYPES.personal
      ? personalStatusNotEditables
      : businessStatuNotEditables
    ), ' AND NOT name:');
    this.statusFilters = `group:${groupToFilter} AND agent_usable:1 AND NOT name:${namesToFilter}`;

    this.inputs.statusId = get(this.user, `status.${this.profile}.id`);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'postPersonalStatus',
      'postBusinessStatus',
    ]),
    async userStatusRequest() {
      this.isLoading = true;
      const request = this.profile === PROFILES_TYPES.personal
        ? this.postPersonalStatus
        : this.postBusinessStatus;

      await request({
        userUuid: this.user.uuid,
        data: this.inputs,
      });

      if (!this.statusError) {
        this.onSuccess();
        this.$emit('closeModal');
        this.$notify({
          message: `${this.profileCapitalized} status successfully <strong class="emobg-font-weight-semibold">edited</strong>`,
          textAction: '',
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :title="`Change ${profile} status`"
    :header="{ isClosable: true }"
    :size="SIZES.small"
    :buttons="modalButtons"
    class="UserStatusModal"
    data-test-id="user_status-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.statusForm"
        :custom-exceptions="[crmErrors.userStatusErrors]"
        :element="`${profile} status`"
        is-editing
      />
    </template>
    <template #body>
      <MuiAlgoliaSelect
        v-model="inputs.statusId"
        :index="ALGOLIA_INDEXES.status"
        :title="status => status.description"
        :label="`${profileCapitalized} status*`"
        :filters="statusFilters"
        name="statusId"
        no-cache
        path-value="id"
        placeholder="Select a status"
        class="w-100"
        data-test-id="status-select"
      />
    </template>
  </GenericModalComponent>
</template>
