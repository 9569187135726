var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EditBadge",
        attrs: {
          header: { isClosable: true },
          title: "Edit badge",
          "data-test-id": "edit_badge-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.newBadge,
              "is-editing": "",
              element: "user badge",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "div",
                { staticClass: "row mb-4" },
                [
                  _c(
                    "ContentCellComponent",
                    { staticClass: "col-6", attrs: { label: "Badge" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.get(
                              _vm.userBadge,
                              "badgeProviderName",
                              _vm.FALLBACK_MESSAGE.dash
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "ContentCellComponent",
                    { staticClass: "col-6", attrs: { label: "Badge" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.get(
                              _vm.userBadge,
                              "badgeNumber",
                              _vm.FALLBACK_MESSAGE.dash
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("MuiSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  options: _vm.map(_vm.BADGE_DELIVERY_STATUS, (status) => ({
                    label: _vm.sentenceCase(status),
                    value: status,
                  })),
                  label: "Status",
                  placeholder: "Select",
                  name: "status",
                  "data-test-id": "status-select",
                },
                model: {
                  value: _vm.inputs.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputs, "status", $$v)
                  },
                  expression: "inputs.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close_modal-button" },
              on: { click: () => _vm.$emit("closeModal") },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: !_vm.isFormValid || _vm.hasSameValues,
                  loading: _vm.newBadgeStatus.LOADING,
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.updateBadge },
              },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }