<script>
import get from 'lodash/get';
import delay from 'lodash/delay';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DELAY } from '@emobg/web-utils';
import {
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'UserImpersonateLogOutModal',
  components: {
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      impersonationHost: state => get(state[USER_SCOPES.impersonation], 'data.host'),
      impersonationFinishStatus: state => state[USER_SCOPES.impersonationFinish].STATUS,
      user: state => state[USER_SCOPES.detail].data,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, ['postUserImpersonateFinish']),
    async impersonateLogOutRequest() {
      await this.postUserImpersonateFinish(this.user.uuid);

      const logoutUrl = `${this.impersonationHost}/impersonate/logout`;
      const logoutTab = window.open(logoutUrl, '_blank');
      delay(() => { logoutTab.close(); }, DELAY.long);
      this.$emit('closeModal');
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="UserImpersonateLogOutModal"
    data-test-id="impersonate_logout-modal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent title="Did you log out from the user’s Webapp after impersonating?" />
    </template>
    <template #footer>
      <ui-button
        :loading="impersonationFinishStatus.LOADING"
        data-test-id="logout-button"
        @clickbutton="impersonateLogOutRequest"
      >
        Yes, I logged out
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
