<script>
import {
  AccountSettings,
  AuthenticationCard,
  DocumentsCard,
  DrivingLicenseCard,
  PersonalSettings,
  UserBadges,
} from './components';

export default {
  name: 'UserAccount',
  components: {
    DocumentsCard,
    AccountSettings,
    PersonalSettings,
    UserBadges,
    DrivingLicenseCard,
    AuthenticationCard,
  },
};
</script>
<template>
  <div
    data-test-id="user_account"
    class="UserAccount"
  >
    <h1 class="flex-grow-1 mb-2">
      User account
    </h1>

    <AccountSettings class="d-block mb-4" />
    <PersonalSettings class="mb-4" />
    <UserBadges class="mb-4" />
    <DrivingLicenseCard class="mb-4" />
    <DocumentsCard class="mb-4" />
    <AuthenticationCard />
  </div>
</template>
