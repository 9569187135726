<script>
import get from 'lodash/get';
import map from 'lodash/map';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import { TableComponent } from '@/components';
import { TARIFFS_SCHEMA } from './subscriptions.const';
import UserTariffForm from './UserTariffForm';
import DeleteUserTariffModal from './DeleteUserTariffModal';

export default {
  name: 'UserTariffs',
  components: {
    DeleteUserTariffModal,
    UserTariffForm,
    TableComponent,
  },
  data() {
    return {
      isFormModalOpen: false,
      isDeleteModalOpen: false,
      editingUserTariff: null,
      tariffToDelete: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userTariffs: state => state.userTariffs.data.data,
      userTariffsStatus: state => state.userTariffs.STATUS,
    }),
    currentTariffs() {
      const currentTariffs = get(this, 'userTariffs.personal.current') || [];
      const futureTariffs = get(this, 'userTariffs.personal.future') || [];

      return [...map(futureTariffs, futureTariff => ({ ...futureTariff, isFuture: true })), ...currentTariffs];
    },
    previousTariffs() {
      return get(this, 'userTariffs.personal.past', null);
    },
    isAddButtonDisabled() {
      return !!get(this, 'userTariffs.personal.future.length', null);
    },
  },
  async created() {
    this.tariffSchema = TARIFFS_SCHEMA;
    this.currentTariffsActions = [
      {
        label: 'Edit user tariff',
        labelClass: 'emobg-font-weight-semibold',
        action: tariff => {
          this.isFormModalOpen = true;
          this.editingUserTariff = tariff;
        },
        hideAction: tariff => !tariff.editable,
      },
      {
        label: 'Delete user tariff',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: tariff => {
          this.isDeleteModalOpen = true;
          this.tariffToDelete = tariff;
        },
        hideAction: tariff => !tariff.removable,
      },
    ];
    await this.getUserTariffs({ userUuid: this.user.uuid });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getUserTariffs',
    ]),
    get,
    async onFormSuccess() {
      await this.getUserTariffs({ userUuid: this.user.uuid });
    },
    closeModal() {
      this.isFormModalOpen = false;
      this.isDeleteModalOpen = false;
      this.editingUserTariff = null;
      this.tariffToDelete = null;
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_tariffs-view"
    class="UserTariffs"
  >
    <h1 class="flex-grow-1 mb-2">
      User tariff
    </h1>
    <ui-card
      class="w-100 mb-4 d-block"
      data-test-id="current_tariffs-card"
    >
      <div class="d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center">
        <h3
          class="emobg-color-ink emobg-font-weight-semibold"
          data-test-id="title"
        >
          Current tariffs
        </h3>

        <ui-button
          :disabled="isAddButtonDisabled"
          @clickbutton="isFormModalOpen = true"
        >
          Add user tariff
        </ui-button>
      </div>

      <div class="position-relative">
        <ui-loader
          v-if="userTariffsStatus.LOADING"
          absolute
          data-test-id="loader"
        />

        <TableComponent
          :schema="tariffSchema()"
          :data="currentTariffs"
          :row-actions="currentTariffsActions"
          empty-label="No tariff"
          class="mt-3"
        />
      </div>
    </ui-card>

    <ui-card
      header="Previous tariffs"
      class="mb-4 d-block"
      data-test-id="previous_tariffs-card"
    >
      <div class="position-relative">
        <ui-loader
          v-if="userTariffsStatus.LOADING"
          absolute
          data-test-id="loader"
        />
        <TableComponent
          :schema="tariffSchema(false)"
          :data="previousTariffs"
          empty-label="No tariff"
          class="mt-1"
        />
      </div>
    </ui-card>

    <UserTariffForm
      v-if="isFormModalOpen"
      :user-tariff="editingUserTariff"
      :on-success="onFormSuccess"
      @closeModal="closeModal"
    />

    <DeleteUserTariffModal
      v-if="isDeleteModalOpen"
      :tariff="tariffToDelete"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
