var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserImpersonateLogOutModal",
        attrs: {
          header: null,
          size: _vm.SIZES.small,
          "data-test-id": "impersonate_logout-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    title:
                      "Did you log out from the user’s Webapp after impersonating?",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "ui-button",
                  {
                    attrs: {
                      loading: _vm.impersonationFinishStatus.LOADING,
                      "data-test-id": "logout-button",
                    },
                    on: { clickbutton: _vm.impersonateLogOutRequest },
                  },
                  [_vm._v(" Yes, I logged out ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }