<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  ContentCellComponent,
  DeleteButton,
  DeleteModalBodyComponent,
  DocumentPreview,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    ContentCellComponent,
    DocumentPreview,
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      deleteDocumentStatus: state => state.deleteDocument.STATUS,
    }),
  },
  methods: {
    get,
    async removeDocument() {
      await this.deleteUserDocument({ userUuid: this.user.uuid, documentUuid: this.document.imageUuid });
      if (!this.deleteDocumentStatus.ERROR) {
        this.$notify({
          message: 'Document successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.callback();
      }
      this.$emit('closeModal');
    },
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'deleteUserDocument',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    class="DeleteDocumentModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        text="It cannot be undone"
        title="Delete this document?"
      />
      <ContentCellComponent
        :label="document.name || ''"
        class="mt-4"
      >
        <DocumentPreview
          v-if="document.imageUrl"
          :image="document.imageUrl"
          is-download-hidden
          is-pointer-disabled
          data-test-id="thumbnail"
        />
      </ContentCellComponent>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deleteDocumentStatus.LOADING"
        data-test-id="delete-button"
        @click="removeDocument"
      />
    </template>
  </GenericModalComponent>
</template>
