var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "UsersView" },
    [
      _c("h1", { staticClass: "mb-3" }, [
        _vm._v(
          " " + _vm._s(_vm.upperFirst(_vm.$t("Common.Business.users"))) + " "
        ),
      ]),
      _c("MuiAlgoliaList", {
        attrs: {
          facets: _vm.userListFacets,
          "table-config": _vm.contentCells,
          "export-columns": _vm.exportColumns,
          filters: _vm.getOperatorFilter({ index: _vm.ALGOLIA_INDEXES.users }),
          index: _vm.ALGOLIA_INDEXES.users,
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }