import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crmRoutes from '../../../router/CRMRouterMap';

export const USER_TABS = [
  {
    label: {
      title: 'Account',
    },
    url: crmRoutes.users.detail.account,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Bookings',
    },
    url: crmRoutes.users.detail.bookings,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Tariffs',
    },
    url: crmRoutes.users.detail.tariffs.index,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Annual protection',
    },
    url: crmRoutes.users.detail.userInsurance,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Invoicing',
    },
    url: crmRoutes.users.detail.invoicing.index,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Promotions',
    },
    url: crmRoutes.users.detail.promotions,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Comments',
    },
    url: crmRoutes.users.detail.comments,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Audits',
    },
    url: crmRoutes.users.detail.audits.index,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Logs',
    },
    url: crmRoutes.users.detail.logs,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Cases',
    },
    url: crmRoutes.users.detail.cases,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Notifications',
    },
    url: crmRoutes.users.detail.notifications,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
];
