var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ResetPassword",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    text: `This will send a password reset link to the account email <span class='emobg-font-weight-semibold'>${_vm.user.info.email}</span>`,
                    title: "Regenerate login?",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      loading: _vm.resetPasswordStatus.LOADING,
                      "data-test-id": "send-button",
                    },
                    on: { clickbutton: _vm.resetPassword },
                  },
                  [_vm._v(" Regenerate login ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }