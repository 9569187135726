var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UserCases", attrs: { "data-test-id": "user_cases-view" } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Cases ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: { clickbutton: _vm.goToCreateCase },
            },
            [_vm._v(" Create case ")]
          ),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        attrs: {
          facets: _vm.caseEventsFacets,
          filters: _vm.caseEventsFilters,
          "export-columns": _vm.contentCells,
          "table-config": _vm.contentCells,
          "query-parameters": {
            attributesToRetrieve: _vm.attributesToRetrieve,
          },
          index: _vm.algoliaIndex,
          "data-test-id": "list",
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }