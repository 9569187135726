var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EditPersonalSettings",
        attrs: {
          title: "Edit personal information",
          header: { isClosable: true },
          "data-test-id": "edit_personal_settings-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.users,
                    scope: _vm.USER_SCOPES.editSettings,
                    action:
                      _vm.IDENTITY_CARD_KEY_ERRORS.invalidCardValue ===
                      _vm.editSettingsErrorKey
                        ? "save personal information because the entered ID number is not correct"
                        : "",
                    "is-editing": "",
                    element: "personal information",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      title: (title) =>
                        `${_vm.get(
                          title.translations,
                          `${_vm.user.info.language.code}`,
                          _vm.sentenceCase(title.internal_name)
                        )} (${_vm.sentenceCase(title.internal_name)})`,
                      index: _vm.titlesIndex,
                      label: "Title*",
                      "path-value": "id",
                      placeholder: "Select",
                      name: "title",
                      "data-test-id": "title-select",
                    },
                    model: {
                      value: _vm.inputs.titleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "titleId", $$v)
                      },
                      expression: "inputs.titleId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      label: "First name*",
                      placeholder: "Enter",
                      "data-test-id": "first_name-input",
                      name: "first_name",
                    },
                    model: {
                      value: _vm.inputs.firstName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "firstName", $$v)
                      },
                      expression: "inputs.firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      label: "Last name*",
                      placeholder: "Enter",
                      "data-test-id": "last_name-input",
                      name: "last_name",
                    },
                    model: {
                      value: _vm.inputs.lastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "lastName", $$v)
                      },
                      expression: "inputs.lastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiDatePicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isValidBirthdate: _vm.isValidBirthdate,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isValidBirthdate,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
                      "skip-time-step": "",
                      "data-test-id": "birthdate-select",
                      label: "Date of birth*",
                      placeholder: "Enter date of birth",
                      name: "birthdate",
                    },
                    model: {
                      value: _vm.inputs.birthdate,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "birthdate", $$v)
                      },
                      expression: "inputs.birthdate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiAlgoliaSelect", {
                    staticClass: "w-100",
                    attrs: {
                      index: _vm.countriesIndex,
                      title: (country) => country.name,
                      label: "ID issuing country",
                      "path-value": "uuid",
                      placeholder: "Select ID issuing country",
                      name: "identityIssuingCountryUuid",
                      "data-test-id": "issuing_country_uuid-select",
                    },
                    on: {
                      selected: (country) => (_vm.countrySelected = country),
                    },
                    model: {
                      value: _vm.inputs.identityIssuingCountryUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "identityIssuingCountryUuid", $$v)
                      },
                      expression: "inputs.identityIssuingCountryUuid",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("MuiSelect", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationsForHospedajesFields,
                          expression: "validationsForHospedajesFields",
                        },
                      ],
                      ref: "identityTypeSelect",
                      staticClass: "w-100",
                      attrs: {
                        options: _vm.map(_vm.identityTypes, (identityType) => ({
                          label: _vm.get(
                            _vm.IDENTITY_TYPE_KEY_MAPPER,
                            identityType.translationKey,
                            _vm.sentenceCase(identityType.translationKey)
                          ),
                          value: identityType.identityType,
                        })),
                        disabled:
                          !_vm.inputs.identityIssuingCountryUuid ||
                          !_vm.identityTypes.length,
                        label: "Document type*",
                        name: "identity_type",
                      },
                      model: {
                        value: _vm.inputs.identityType,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "identityType", $$v)
                        },
                        expression: "inputs.identityType",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.identityNumberValidation,
                          expression: "identityNumberValidation",
                        },
                      ],
                      ref: "identityNumberInput",
                      attrs: {
                        "model-value": _vm.inputs.identityNumber,
                        disabled: !_vm.inputs.identityType,
                        label: "ID number*",
                        name: "identity_number",
                      },
                      on: {
                        input: (value) =>
                          (_vm.inputs.identityNumber = _vm
                            .identityTypeValidation.isToUpper
                            ? _vm.toUpper(value)
                            : value),
                      },
                    }),
                    _vm.identityTypeValidation.example
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-color-ink-light emobg-font-x-small mt-1",
                          },
                          [
                            _vm._v(
                              " Example: " +
                                _vm._s(_vm.identityTypeValidation.example) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("GoogleLocationSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.update:place",
                        value: {
                          isRequired: true,
                          isValidAddress:
                            _vm.GOOGLE_ADDRESS_VALIDATIONS
                              .hasRequiredComponents,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isValidAddress: GOOGLE_ADDRESS_VALIDATIONS.hasRequiredComponents,\n          }",
                        modifiers: { "update:place": true },
                      },
                    ],
                    attrs: {
                      placeholder: "Select",
                      "data-test-id": "address-select",
                      label: "Address*",
                    },
                    on: {
                      "update:place": _vm.updateLocation,
                      "created:place": _vm.updateLocation,
                    },
                    model: {
                      value: _vm.inputs.addressStreet,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "addressStreet", $$v)
                      },
                      expression: "inputs.addressStreet",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { class: { "mb-4": _vm.isInputItaly } },
                [
                  _c("MuiInputText", {
                    staticClass: "w-100",
                    attrs: {
                      label: "Floor, door, apartment...",
                      placeholder: "Enter address",
                      "data-test-id": "address_2-input",
                      name: "address_2",
                    },
                    model: {
                      value: _vm.inputs.addressStreet2,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "addressStreet2", $$v)
                      },
                      expression: "inputs.addressStreet2",
                    },
                  }),
                ],
                1
              ),
              _vm.isInputItaly
                ? _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isCorrectLength: (value) => ({
                            isValid:
                              value.length === _vm.CODICE_FISCALE_MAX_LENGTH,
                            message: `Must have ${_vm.CODICE_FISCALE_MAX_LENGTH} characters`,
                          }),
                          isCodiceFiscaleValid: (value) => ({
                            isValid: !!value.match(
                              _vm.ITALY_COMPANY_VALIDATION_PATTERN
                                .userCodiceFiscale
                            ),
                            message:
                              "Letters and numbers must be in the right order (example: RSSMRA80A01H501U)",
                          }),
                          isPattern: _vm.PATTERN_INPUT_VALIDATIONS.alphanumeric,
                        },
                        expression:
                          "{\n          isRequired: true,\n          isCorrectLength: (value) => ({\n            isValid: value.length === CODICE_FISCALE_MAX_LENGTH,\n            message: `Must have ${CODICE_FISCALE_MAX_LENGTH} characters`\n          }),\n          isCodiceFiscaleValid: (value) => ({\n            isValid: !!value.match(ITALY_COMPANY_VALIDATION_PATTERN.userCodiceFiscale),\n            message: 'Letters and numbers must be in the right order (example: RSSMRA80A01H501U)',\n          }),\n          isPattern: PATTERN_INPUT_VALIDATIONS.alphanumeric,\n        }",
                      },
                    ],
                    ref: "codiceFiscaleInput",
                    staticClass: "w-100",
                    attrs: {
                      "model-value": _vm.inputs.extraDocuments.codiceFiscale,
                      caption: `Fiscal code is a unique combination of ${_vm.CODICE_FISCALE_MAX_LENGTH} numbers and letters (example: RSSMRA80A01H501U)`,
                      label: "Fiscal code",
                      placeholder: "Enter",
                      "data-test-id": "codice_fiscale",
                      name: "codice_fiscale",
                    },
                    on: { input: _vm.setCodiceFiscale },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close_modal-button" },
              on: { click: () => _vm.$emit("closeModal") },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: _vm.hasSameValues || !_vm.isFormValid,
                  loading: _vm.editSettingsStatus.LOADING,
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.saveSettings },
              },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }