<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import {
  COLORS,
  MuiCard,
} from '@emobg/vue-base';
import { DATE_FORMAT, reformatDateTime, sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import {
  ContentCellComponent,
  CopyToClipboard,
  DocumentPreview,
  EditButton,
} from '@/components';
import {
  DrivingLicenseComments,
  DrivingLicenseEdit,
  DrivingLicenseUpload,
} from './components';
import { DL_STATUSES, isUK } from './drivingLicense.const';
import { USER_STATUS } from '../../../../constants/userStatus';

export default {
  name: 'DrivingLicenseCard',
  components: {
    MuiCard,
    ContentCellComponent,
    CopyToClipboard,
    EditButton,
    DocumentPreview,
    DrivingLicenseComments,
    DrivingLicenseUpload,
    DrivingLicenseEdit,
  },
  data() {
    return {
      isModalOpen: false,
      isUploadModalOpen: false,
      isPreviewMode: false,
      startIndex: 0,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      drivingLicense: state => state.drivingLicense.data,
      drivingLicenseComments: state => state.drivingLicenseComments.data,
      drivingLicenseCommentsStatus: state => state.drivingLicenseComments.STATUS,
      drivingLicenseStatus: state => state.drivingLicense.STATUS,
      drivingLicenseValidateStatus: state => state.drivingLicenseValidate.STATUS,
      drivingLicenseInvalidateStatus: state => state.drivingLicenseInvalidate.STATUS,
    }),
    isDrivingLicenseActive() {
      return get(this, 'drivingLicense.active');
    },
    drivingLicenseAction() {
      return this.drivingLicense && !get(this, 'drivingLicense.active') ? 'Replace' : 'Upload';
    },
    hasAlertableStatus() {
      return [DL_STATUSES.waitingForValidation, DL_STATUSES.rejected]
        .includes(get(this, 'drivingLicense.status', DL_STATUSES.rejected));
    },
    userName() {
      return `${get(this, 'user.info.firstName')} ${get(this, 'user.info.lastName')}`;
    },
    alertColor() {
      return get(this, 'drivingLicense.status') === DL_STATUSES.waitingForValidation
        ? COLORS.moderate
        : COLORS.warning;
    },
    alertText() {
      const userStatus = get(this, 'user.status.personal.name') === USER_STATUS.incomplete ? USER_STATUS.incomplete : null;
      const drivingLicenseStatus = get(this, 'drivingLicense.status') === DL_STATUSES.waitingForValidation ? DL_STATUSES.waitingForValidation : null;
      const statusText = drivingLicenseStatus || userStatus;

      const statusNode = `<span class="emobg-font-weight-semibold">${sentenceCase(statusText)}</span>`;
      const message = statusText === USER_STATUS.incomplete
        ? '(Waiting for the user to upload documents)'
        : '(Waiting for an agent to review documents)';

      return `${statusNode} ${message}`;
    },
    disableValidate() {
      return get(this, 'drivingLicense.status') !== DL_STATUSES.waitingForValidation
        || get(this, 'user.status.personal.name') === USER_STATUS.waitingForAnonymization
        || get(this, 'user.status.personal.name') === USER_STATUS.anonymized;
    },
    disableInvalidate() {
      return get(this, 'drivingLicense.status') === DL_STATUSES.rejected
        || !get(this, 'drivingLicense.active')
        || get(this, 'user.status.personal.name') === USER_STATUS.waitingForAnonymization
        || get(this, 'user.status.personal.name') === USER_STATUS.anonymized;
    },
    isEditable() {
      return this.drivingLicense && (this.isDrivingLicenseActive || get(this, 'drivingLicense.status') === DL_STATUSES.waitingForValidation);
    },
  },
  async created() {
    this.DL_STATUSES = DL_STATUSES;
    this.USER_STATUS = USER_STATUS;
    this.USER_SCOPES = USER_SCOPES;
    this.DATE_FORMAT = DATE_FORMAT;
    await this.getDrivingLicense(this.user.uuid);
    const drivingLicenseUuid = get(this, 'drivingLicense.uuid');
    await this.getDrivingLicenseComments({ commentableUuid: drivingLicenseUuid });
  },
  methods: {
    get,
    reformatDateTime,
    isUK,
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getUser',
      'getDrivingLicense',
      'getDrivingLicenseComments',
      'getUserDocuments',
      'postValidateDrivingLicense',
      'postInvalidateDrivingLicense',
    ]),
    closeModal() {
      this.isModalOpen = false;
      this.isUploadModalOpen = false;
    },
    /**
     * Opens the driving license edit modal in preview mode or edit mode
     * @param startIndex is the index of the image to start the gallery.
     * @param isPreviewMode sets the preview mode for the modal
     */
    openEditModal(startIndex = 0, isPreviewMode = false) {
      this.isPreviewMode = isPreviewMode;
      this.startIndex = startIndex;
      this.isModalOpen = true;
    },
    async onDrivingLicenseValidation(scope = USER_SCOPES.drivingLicenseValidate) {
      const actionText = scope === USER_SCOPES.drivingLicenseValidate ? 'validated' : 'invalidated';
      const request = scope === USER_SCOPES.drivingLicenseValidate ? this.postValidateDrivingLicense : this.postInvalidateDrivingLicense;
      const status = scope === USER_SCOPES.drivingLicenseValidate ? this.drivingLicenseValidateStatus : this.drivingLicenseInvalidateStatus;

      await request({
        userUuid: this.user.uuid,
        drivingLicenseUuid: this.drivingLicense.uuid,
      });

      if (!status.ERROR) {
        this.$notify({
          message: `Driving license successfully <span class="emobg-font-weight-semibold">${actionText}</span>`,
          textAction: '',
        });

        await this.onFormSuccess();
      }
    },
    async onFormSuccess(isUpload = false) {
      const userUuid = get(this, 'user.uuid');
      const requestsAtSameTime = [
        this.getUser(userUuid),
        this.getDrivingLicense(userUuid),
      ];

      if (isUpload) {
        requestsAtSameTime.push(this.getUserDocuments(userUuid));
      }

      await Promise.all(requestsAtSameTime);
      await this.getDrivingLicenseComments({ commentableUuid: get(this, 'drivingLicense.uuid') });
    },
    getFormattedDate(attribute) {
      const date = get(this.drivingLicense, attribute);
      return date ? reformatDateTime(date, DATE_FORMAT.dob) : this.FALLBACK_MESSAGE.dash;
    },
  },
};
</script>
<template>
  <MuiCard
    title="Driving license"
    class="DrivingLicense w-100 position-relative"
    data-test-id="driving_license-card"
  >
    <ui-loader
      v-if="drivingLicenseStatus.LOADING"
      absolute
      data-test-id="loader"
    />
    <div
      v-if="get(user, 'status.personal.name') === USER_STATUS.anonymized"
      class="emobg-color-ink-light mb-3"
    >
      User data anonymised
    </div>
    <template v-else>
      <ui-alert
        v-if="hasAlertableStatus"
        :color="alertColor"
        :icon="ICONS.alertFull"
        class="pb-3 d-flex w-100"
        data-test-id="warning_message-alert"
      >
        <p v-html="alertText" />
      </ui-alert>

      <DrivingLicenseComments
        :driving-license-comments="drivingLicenseComments || []"
        :is-loading="drivingLicenseCommentsStatus.LOADING"
      />

      <div class="d-flex justify-content-between align-items-top">
        <div class="w-100 row">
          <div class="col-md-6 col-lg-3">
            <ContentCellComponent
              label="Title, Full Name"
              class="mb-3"
            >
              <span v-if="get(user, 'user.info.title.name')">
                {{ sentenceCase(user.info.title.name) }},
              </span>
              {{ userName }}
            </ContentCellComponent>

            <ContentCellComponent
              :value="getFormattedDate('birthdate')"
              label="Date of birth"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(drivingLicense, 'issuingCountryName', FALLBACK_MESSAGE.dash)"
              label="Country of issue"
              class="mb-3"
            />
          </div>
          <div class="col-md-6 col-lg-3">
            <ContentCellComponent
              :value="getFormattedDate('physicalIssueDate')"
              label="Date of issue"
              class="mb-3"
            />

            <ContentCellComponent
              :value="getFormattedDate('expiration')"
              label="Expiry date"
              class="mb-3"
            />

            <ContentCellComponent
              label="License number"
              class="mb-3"
            >
              <CopyToClipboard
                v-if="get(drivingLicense, 'licenseNumber')"
                data-test-id="copy_license-button"
              >
                {{ get(drivingLicense, 'licenseNumber') }}
              </CopyToClipboard>
              <span v-else>
                {{ FALLBACK_MESSAGE.dash }}
              </span>
            </ContentCellComponent>
          </div>
          <div class="col-md-6 col-lg-3">
            <ContentCellComponent
              :value="getFormattedDate('firstIssueDate')"
              label="First issuing date of category"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(drivingLicense, 'drivingCategories', FALLBACK_MESSAGE.dash)"
              label="Driving categories"
              class="mb-3"
            />

            <ContentCellComponent
              v-if="isUK(get(drivingLicense, 'issuingCountryName', ''))"
              label="DVLA check code"
              class="mb-3"
            >
              <CopyToClipboard
                v-if="get(drivingLicense, 'dlvaCode')"
                data-test-id="copy_dlva-button"
              >
                {{ get(drivingLicense, 'dlvaCode') }}
              </CopyToClipboard>
              <span v-else>
                {{ FALLBACK_MESSAGE.dash }}
              </span>
            </ContentCellComponent>
          </div>
          <div
            v-if="drivingLicense"
            class="row col-12"
          >
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Driving license (Front)"
                class="mb-3"
              >
                <DocumentPreview
                  v-if="drivingLicense.frontDocumentImg"
                  :image="drivingLicense.frontDocumentImg"
                  @click="openEditModal(0, true)"
                />
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Driving license (Back)"
                class="mb-3"
              >
                <DocumentPreview
                  v-if="drivingLicense.backDocumentImg"
                  :image="drivingLicense.backDocumentImg"
                  @click="openEditModal(1, true)"
                />
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Selfie"
                class="mb-3"
              >
                <DocumentPreview
                  v-if="drivingLicense.selfieImg"
                  :image="drivingLicense.selfieImg"
                  @click="openEditModal(2, true)"
                />
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton
          :disabled="!isEditable"
          class="ml-2"
          @click="openEditModal"
        />
      </div>
      <div
        v-if="!drivingLicense || !isDrivingLicenseActive"
        class="col-12 d-flex justify-content-center p-3 emobg-background-color-ground-lighter emobg-border-color-ground emobg-border-1 emobg-border-radius-small my-3"
      >
        <ui-button
          :face="FACES.outline"
          @clickbutton="isUploadModalOpen = true"
        >
          {{ drivingLicenseAction }} driving license
        </ui-button>
      </div>
    </template>

    <div
      v-if="get(user, 'status.personal.name') !== USER_STATUS.blockedByScreening"
      class="d-flex flex-column justify-content-end pt-3 emobg-border-color-ground-light emobg-border-top-1"
    >
      <ContentCellComponent
        label="Validation actions"
        :class="['text-right', { 'emobg-color-ink-lighter': disableInvalidate && disableValidate }]"
      >
        <ui-button
          v-if="get(drivingLicense, 'status') !== DL_STATUSES.validated"
          :color="COLORS.success"
          :disabled="disableValidate"
          :loading="drivingLicenseValidateStatus.LOADING"
          class="mr-1"
          @clickbutton="onDrivingLicenseValidation(USER_SCOPES.drivingLicenseValidate)"
        >
          Validate
        </ui-button>
        <ui-button
          :color="COLORS.danger"
          :disabled="disableInvalidate"
          :loading="drivingLicenseInvalidateStatus.LOADING"
          @clickbutton="onDrivingLicenseValidation(USER_SCOPES.drivingLicenseInvalidate)"
        >
          Invalidate
        </ui-button>
      </ContentCellComponent>
      <div
        v-if="isDrivingLicenseActive === false"
        class="d-flex justify-content-end mt-2 emobg-color-danger emobg-font-weight-semibold"
      >
        DL was invalidated
      </div>
    </div>

    <DrivingLicenseUpload
      v-if="isUploadModalOpen"
      :callback="() => onFormSuccess(true)"
      @closeModal="closeModal"
    />

    <DrivingLicenseEdit
      v-if="isModalOpen"
      :is-preview="isPreviewMode"
      :backdrop-dismiss="isPreviewMode"
      :start-index="startIndex"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </MuiCard>
</template>
