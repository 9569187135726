<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'UserImpersonationRequest',
  components: {
    CancelButton,
    GenericModalComponent,
    StoreNotificationComponent,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      reasonUuid: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state[USER_SCOPES.detail].data,
      reasons: state => state[USER_SCOPES.impersonationReasons].data,
      impersonationUrl: state => get(state[USER_SCOPES.impersonation], 'data.url'),
      impersonationStatus: state => state[USER_SCOPES.impersonation].STATUS,
      areReasonsLoading: state => state[USER_SCOPES.impersonationReasons].STATUS.LOADING,
    }),
  },
  async created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    await this.getImpersonateReasons();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, ['getImpersonateReasons', 'postUserImpersonateReason']),
    async impersonate() {
      await this.postUserImpersonateReason({
        userUuid: this.user.uuid,
        data: {
          reasonUuid: this.reasonUuid,
        },
      });

      if (!this.impersonationStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        window.open(this.impersonationUrl, '_blank').focus();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :size="SIZES.small"
    title="Impersonation request"
    class="UserImpersonationRequest"
    data-test-id="impersonation_request-modal"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.impersonation"
        action="impersonate"
      />
    </template>
    <template #body>
      <ui-loader
        v-if="areReasonsLoading"
        data-test-id="loader"
      />
      <template v-else>
        <h3 class="emobg-font-weight-normal mb-3">
          Reason for impersonation
        </h3>
        <ui-radio
          v-for="(reason, index) in reasons"
          :key="reason.uuid"
          :name="`reason_${reason.uuid}`"
          :value="reasonUuid"
          :option="reason.uuid"
          :caption="reason.name"
          :class="{ 'mb-2': index < reasons.length - 1 }"
          :data-test-id="`reason_${index}-option`"
          class="d-block"
          @changevalue="({ detail }) => reasonUuid = detail"
        />
      </template>
    </template>
    <template #footer>
      <CancelButton @click="() => $emit('closeModal')" />
      <ui-button
        :disabled="!reasonUuid"
        :loading="impersonationStatus.LOADING"
        class="ml-2"
        data-test-id="impersonate-button"
        @clickbutton="impersonate"
      >
        Impersonate
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
