var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UserCover", attrs: { "data-test-id": "user_cover-view" } },
    [
      _c("h1", { staticClass: "flex-grow-1 mb-2" }, [_vm._v(" Cover ")]),
      _c(
        "ui-card",
        {
          staticClass: "w-100 mb-4 position-relative",
          attrs: { "data-test-id": "cover-card" },
        },
        [
          _vm.insuranceStatus.LOADING
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center",
            },
            [
              _c(
                "h3",
                {
                  staticClass: "emobg-color-ink emobg-font-weight-semibold",
                  attrs: { "data-test-id": "title" },
                },
                [_vm._v(" Annual cover ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: { disabled: _vm.hasAnnualCover },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isModalOpen = true
                    },
                  },
                },
                [_vm._v(" Contract annual cover ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row d-flex mt-3" },
            [
              _c("ContentCellComponent", {
                staticClass: "col-md-6 col-lg-3",
                attrs: {
                  value: _vm.hasAnnualCover
                    ? _vm.get(_vm.insurance, "createdAt")
                    : _vm.FALLBACK_MESSAGE.dash,
                  label: "Start date",
                },
              }),
              _c(
                "div",
                { staticClass: "col-md-6 col-lg-3" },
                [
                  _c("ContentCellComponent", {
                    staticClass: "pb-1",
                    attrs: {
                      value: _vm.hasAnnualCover
                        ? _vm.get(_vm.insurance, "expiresAt")
                        : _vm.FALLBACK_MESSAGE.dash,
                      label: _vm.isInsuranceAutomaticRenewal
                        ? "Renewal date"
                        : "End date",
                    },
                  }),
                  _vm.hasAnnualCover &&
                  _vm.insurance &&
                  !_vm.isInsuranceAutomaticRenewal
                    ? _c("span", { staticClass: "emobg-color-ink-light" }, [
                        _vm._v(" Renewal cancelled "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.isInsuranceAutomaticRenewal
                ? _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3 align-self-center" },
                    [
                      _c(
                        "ui-button",
                        {
                          attrs: { color: _vm.COLORS.danger },
                          on: {
                            clickbutton: function ($event) {
                              _vm.isModalOpen = true
                            },
                          },
                        },
                        [_vm._v(" Cancel automatic renewal ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalOpen
        ? _c("UserCoverContractForm", {
            attrs: {
              "is-cancel-renewal": _vm.isInsuranceAutomaticRenewal,
              callback: _vm.onCoverContract,
            },
            on: {
              closeModal: function ($event) {
                _vm.isModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }