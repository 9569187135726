var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CreateBadge",
        attrs: {
          title: `${_vm.actionText} badge`,
          header: { isClosable: true },
          "data-test-id": "create_badge-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.newBadge,
              action: `${_vm.actionText} user badge`,
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [_vm._v(" Type ")]
                  ),
                  _c("ui-radio", {
                    attrs: {
                      value: _vm.isCustomBadge,
                      option: false,
                      caption: "CS Operator badge",
                      name: "is_custom_badge",
                      "data-test-id": "operator_badge-radio",
                    },
                    on: {
                      changevalue: () => {
                        _vm.isCustomBadge = false
                        _vm.onBadgeTypeChange()
                      },
                    },
                  }),
                  _c("br"),
                  _c("ui-radio", {
                    attrs: {
                      value: _vm.isCustomBadge,
                      option: true,
                      caption: "Custom badge",
                      name: "is_custom_badge",
                      "data-test-id": "custom_badge-radio",
                    },
                    on: {
                      changevalue: () => {
                        _vm.isCustomBadge = true
                        _vm.onBadgeTypeChange()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.isCustomBadge
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("MuiAlgoliaSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n              isRequired: true,\n            }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            filters: `technology:generic AND api:true AND (${_vm.getOperatorFilter()} OR cs_operator_fk:null)`,
                            index: _vm.ALGOLIA_INDEXES.badgeProviders,
                            title: (result) => result.name,
                            "path-value": "uuid",
                            label: "Badge*",
                            placeholder: "Select",
                            name: "badge_provider_uuid",
                            "data-test-id": "badge_provider-select",
                          },
                          model: {
                            value: _vm.inputs.badgeProviderUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "badgeProviderUuid", $$v)
                            },
                            expression: "inputs.badgeProviderUuid",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isPattern:
                                  _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                                isRequired: true,
                              },
                              expression:
                                "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n              isRequired: true,\n            }",
                            },
                          ],
                          attrs: {
                            label: "Badge number*",
                            name: "badge_number",
                            placeholder: "Enter number",
                            "data-test-id": "number-input",
                          },
                          model: {
                            value: _vm.inputs.number,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "number", $$v)
                            },
                            expression: "inputs.number",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                        },
                        expression: "{\n          isRequired: true,\n        }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.badges,
                      title: (result) =>
                        `${result.number} ${_vm.get(
                          result,
                          "provider.name",
                          ""
                        )}`,
                      filters: "serial_number > 0 AND status:active",
                      "path-value": "uuid",
                      label: "Badge*",
                      placeholder: "Select",
                      name: "badge_uuid",
                      "data-test-id": "badge-select",
                    },
                    model: {
                      value: _vm.inputs.badgeUuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "badgeUuid", $$v)
                      },
                      expression: "inputs.badgeUuid",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close_modal-button" },
              on: { click: () => _vm.$emit("closeModal") },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: !_vm.isFormValid,
                  loading: _vm.newBadgeStatus.LOADING,
                  "data-test-id": "create-button",
                },
                on: { clickbutton: _vm.createBadge },
              },
              [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }