<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      deleteUserTariffStatus: state => state.deleteUserTariff.STATUS,
    }),
    isMigratedTariff() {
      return get(this, 'tariff.isMigrated', false);
    },
  },
  methods: {
    get,
    async removeUserTariff() {
      await this.deleteUserTariff({
        userTariffUuid: get(this, 'tariff.userTariffUuid'),
        version: this.isMigratedTariff ? 'v3' : 'v2',
      });
      if (!this.deleteUserTariffStatus.ERROR) {
        this.$notify({
          message: 'User tariff successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.callback();
      }
      this.$emit('closeModal');
    },
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'deleteUserTariff',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteUserTariffModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        title="Delete this tariff?"
        :text="`<p class='pb-2'>${get(tariff, 'tariffName', '')}.</p>This cannot be undone`"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deleteUserTariffStatus.LOADING"
        data-test-id="delete-button"
        @click="removeUserTariff"
      />
    </template>
  </GenericModalComponent>
</template>
