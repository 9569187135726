<script>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'EditGeneralSettings',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiAlgoliaSelect,
    StoreNotificationComponent,
    ContentCellComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        localeCode: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      editSettingsStatus: state => state.editSettings.STATUS,
    }),
    hasSameValues() {
      return this.inputs.localeCode === this.user.info.language.code;
    },
  },
  created() {
    this.localesIndex = ALGOLIA_INDEXES.locales;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.inputs.localeCode = this.user.info.language.code;
    this.resetState({ scopes: [USER_SCOPES.editSettings] });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['patchUserInformation']),
    sentenceCase,
    async saveSettings() {
      await this.patchUserInformation({
        userUuid: this.user.uuid,
        data: this.inputs,
      });

      if (!this.editSettingsStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'General settings successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    title="Edit general settings"
    :header="{ isClosable: true }"
    class="EditGeneralSettings"
    data-test-id="edit_general_settings-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.editSettings"
        is-editing
        element="general settings"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <ContentCellComponent
          label="Account email"
          class="mb-5"
        >
          {{ user.info.email }}
        </ContentCellComponent>

        <ContentCellComponent
          label="Operator"
          class="mb-5"
        >
          {{ user.info.operatorName }}
        </ContentCellComponent>

        <MuiAlgoliaSelect
          v-model="inputs.localeCode"
          v-validate="{
            isRequired: true,
          }"
          :title="locale => `${locale.code} ${locale.name}`"
          :index="localesIndex"
          filters="active: true"
          label="Language"
          path-value="code"
          class="w-100"
          placeholder="Select"
          name="locale"
          data-test-id="locale-select"
        />
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="editSettingsStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveSettings"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
