<script>
import { mapState } from 'vuex';
import { DELAY, TIME_ZONE } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, facets } from './config';

export default {
  name: 'UsersLogs',
  components: {
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      impersonationStatus: state => state.impersonationFinish.STATUS,
    }),
    algoliaConfig() {
      return contentCells({ operatorTimezone: this.operatorTimezone });
    },
    algoliaFacets() {
      return facets({ operatorTimezone: this.operatorTimezone });
    },
  },
  watch: {
    impersonationStatus: {
      handler() {
        if (this.impersonationStatus.LOADED) {
          this.refreshList();
        }
      },
      deep: true,
    },
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.impersonateLogs;
    this.algoliaFilters = `user.uuid:${this.user.uuid}`;
  },
  methods: {
    refreshList() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.impersonationLogs, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>
<template>
  <div
    data-test-id="users_logs"
    class="UsersLogs"
  >
    <h1 class="mb-2">
      Impersonation requests
    </h1>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="impersonationLogs"
        data-test-id="list"
        :index="algoliaIndex"
        :table-config="algoliaConfig"
        :export-columns="algoliaConfig"
        :filters="algoliaFilters"
        :facets="algoliaFacets"
        is-export-enabled
      />
    </div>
  </div>
</template>
