<script>
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'AddUserBadge',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
    MuiAlgoliaSelect,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isCustomBadge: false,
      inputs: {
        badgeUuid: null,
        badgeProviderUuid: null,
        number: null,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userBadge: state => state.userBadge.data,
      newBadgeStatus: state => state.newBadge.STATUS,
    }),
    actionText() {
      return this.userBadge ? 'Replace' : 'Add';
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.resetState({ scopes: [USER_SCOPES.newBadge] });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['postUserBadge']),
    get,
    onBadgeTypeChange() {
      if (this.isCustomBadge) {
        this.inputs.badgeUuid = null;
      } else {
        this.inputs.badgeProviderUuid = null;
        this.inputs.number = null;
      }
    },
    async createBadge() {
      await this.postUserBadge({
        userUuid: this.user.uuid,
        data: this.inputs,
      });

      if (!this.newBadgeStatus.ERROR) {
        const action = this.userBadge ? 'replaced' : 'added';
        this.$emit('closeModal');
        this.$notify({
          message: `Badge successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :title="`${actionText} badge`"
    :header="{ isClosable: true }"
    class="CreateBadge"
    data-test-id="create_badge-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.newBadge"
        :action="`${actionText} user badge`"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="mb-4">
          <label class="d-block emobg-font-weight-semibold mb-1">
            Type
          </label>
          <ui-radio
            :value="isCustomBadge"
            :option="false"
            caption="CS Operator badge"
            name="is_custom_badge"
            data-test-id="operator_badge-radio"
            @changevalue="() => {
              isCustomBadge = false;
              onBadgeTypeChange()
            }"
          />
          <br>
          <ui-radio
            :value="isCustomBadge"
            :option="true"
            caption="Custom badge"
            name="is_custom_badge"
            data-test-id="custom_badge-radio"
            @changevalue="() => {
              isCustomBadge = true;
              onBadgeTypeChange()
            }"
          />
        </div>

        <div
          v-if="isCustomBadge"
          class="row"
        >
          <div class="col-6">
            <MuiAlgoliaSelect
              v-model="inputs.badgeProviderUuid"
              v-validate="{
                isRequired: true,
              }"
              :filters="`technology:generic AND api:true AND (${getOperatorFilter()} OR cs_operator_fk:null)`"
              :index="ALGOLIA_INDEXES.badgeProviders"
              :title="result => result.name"
              path-value="uuid"
              label="Badge*"
              placeholder="Select"
              name="badge_provider_uuid"
              data-test-id="badge_provider-select"
              class="w-100"
            />
          </div>

          <div class="col-6">
            <MuiInputText
              v-model="inputs.number"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                isRequired: true,
              }"
              label="Badge number*"
              name="badge_number"
              placeholder="Enter number"
              data-test-id="number-input"
            />
          </div>
        </div>
        <MuiAlgoliaSelect
          v-else
          v-model="inputs.badgeUuid"
          v-validate="{
            isRequired: true,
          }"
          :index="ALGOLIA_INDEXES.badges"
          :title="result => `${result.number} ${get(result, 'provider.name', '')}`"
          filters="serial_number > 0 AND status:active"
          path-value="uuid"
          label="Badge*"
          placeholder="Select"
          name="badge_uuid"
          data-test-id="badge-select"
          class="w-100"
        />
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="!isFormValid"
          :loading="newBadgeStatus.LOADING"
          class="wmin-initial"
          data-test-id="create-button"
          @clickbutton="createBadge"
        >
          {{ actionText }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
