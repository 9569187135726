var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserImpersonationRequest",
        attrs: {
          size: _vm.SIZES.small,
          title: "Impersonation request",
          "data-test-id": "impersonation_request-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.users,
                    scope: _vm.USER_SCOPES.impersonation,
                    action: "impersonate",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.areReasonsLoading
                  ? _c("ui-loader", { attrs: { "data-test-id": "loader" } })
                  : [
                      _c(
                        "h3",
                        { staticClass: "emobg-font-weight-normal mb-3" },
                        [_vm._v(" Reason for impersonation ")]
                      ),
                      _vm._l(_vm.reasons, function (reason, index) {
                        return _c("ui-radio", {
                          key: reason.uuid,
                          staticClass: "d-block",
                          class: { "mb-2": index < _vm.reasons.length - 1 },
                          attrs: {
                            name: `reason_${reason.uuid}`,
                            value: _vm.reasonUuid,
                            option: reason.uuid,
                            caption: reason.name,
                            "data-test-id": `reason_${index}-option`,
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.reasonUuid = detail),
                          },
                        })
                      }),
                    ],
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  on: { click: () => _vm.$emit("closeModal") },
                }),
                _c(
                  "ui-button",
                  {
                    staticClass: "ml-2",
                    attrs: {
                      disabled: !_vm.reasonUuid,
                      loading: _vm.impersonationStatus.LOADING,
                      "data-test-id": "impersonate-button",
                    },
                    on: { clickbutton: _vm.impersonate },
                  },
                  [_vm._v(" Impersonate ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }