var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "p-3 emobg-border-color-ground-light emobg-border-1 emobg-border-radius-small mb-3",
    },
    [
      _c("p", { staticClass: "emobg-font-weight-semibold mb-2" }, [
        _vm._v(" System feedback "),
      ]),
      _vm.isLoading
        ? _c("ui-loader")
        : _c(
            "div",
            [
              _vm.size(_vm.drivingLicenseComments)
                ? _vm._l(_vm.drivingLicenseComments, function (comment) {
                    return _c(
                      "p",
                      {
                        key: comment.uuid,
                        staticClass: "d-flex align-items-center",
                      },
                      [
                        _c("ui-icon", {
                          staticClass: "mr-1 pt-1",
                          attrs: {
                            icon: _vm.ICONS.infoFull,
                            size: _vm.ICONS_SIZES.small,
                            color: _vm.COLORS.primary,
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(comment.body))]),
                      ],
                      1
                    )
                  })
                : _c("p", { staticClass: "emobg-color-ink-light" }, [
                    _vm._v(" No system feedback provided "),
                  ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }