<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteButton,
    GenericModalComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      checkCancelAccountStatus: state => state.cancelableAccount.STATUS,
      cancelAccountStatus: state => state.cancelAccount.STATUS,
    }),
  },
  async created() {
    await this.getCheckCancelAccount({ userUuid: this.user.uuid });
  },
  methods: {
    get,
    async cancelAccount() {
      await this.putCancelAccount({ userUuid: this.user.uuid });
      if (!this.cancelAccountStatus.ERROR) {
        this.$notify({
          message: 'Account successfully <span class="emobg-font-weight-semibold">cancelled</span>',
          textAction: '',
        });

        this.callback();
      }
      this.$emit('closeModal');
    },
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getCheckCancelAccount',
      'putCancelAccount',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: 'Cancel this account?', isClosable: true }"
    :size="SIZES.small"
    class="CancelAccount"
    v-on="$listeners"
  >
    <template #body>
      <ui-loader
        v-if="checkCancelAccountStatus.LOADING"
        absolute
        data-test-id="loader"
      />
      <div v-else-if="checkCancelAccountStatus.LOADED">
        <p class="mb-4">
          By doing so, the data anonymization process will be triggered which means that:
        </p>
        <ul class="pl-4">
          <li>The user won't be able to log in anymore</li>
          <li>The user will receive an email confirmation</li>
          <li>The user data will be deleted on the corresponding anonymization date</li>
        </ul>
      </div>
      <div v-else-if="checkCancelAccountStatus.ERROR">
        <p class="mb-4">
          This account cannot be cancelled for one of the following reasons:
        </p>
        <ul class="pl-4 mb-4">
          <li>The user has current or pending bookings</li>
          <li>The user has a pending debt</li>
        </ul>
        <p>
          The user will receive email notification.
        </p>
      </div>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :disabled="!checkCancelAccountStatus.LOADED"
        :loading="cancelAccountStatus.LOADING"
        data-test-id="delete-button"
        @click="cancelAccount"
      >
        Cancel account
      </DeleteButton>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
  .CancelAccount {
    ul {
      list-style: initial !important;
    }
  }
</style>
