<script>
import isNumber from 'lodash/isNumber';
import reduce from 'lodash/reduce';

import { PLACEMENTS } from '@emobg/vue-base';
import { MuiPopper } from '@emobg/motion-ui/v1';

export default {
  name: 'SiftScoreCell',
  components: {
    MuiPopper,
  },
  props: {
    score: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    scoreColor() {
      const { score } = this;
      const range = [{ range: 0, color: 'emobg-color-success' }, { range: 61, color: 'emobg-color-moderate' }, { range: 80, color: 'emobg-color-danger' }];
      return reduce(range, (result, item) => (isNumber(score) && score >= item.range && item.color) || result, 'emobg-color-ink-light');
    },
  },
  created() {
    this.PLACEMENTS = PLACEMENTS;
  },
  methods: {
    isNumber,
  },
};
</script>
<template>
  <div class="SiftScoreCell">
    <div class="d-flex align-items-center">
      <h3 class="mr-1">
        Sift score
      </h3>
      <div @mouseleave="isVisible = false">
        <MuiPopper
          :show="isVisible"
          :arrow-class="`emobg-background-color-secondary`"
          :options="{
            placement: PLACEMENTS.top,
            modifiers: [{
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            }]}"
          arrow
          popper-class="fade"
        >
          <div @mouseenter="isVisible = true">
            <ui-icon
              :icon="ICONS.infoFull"
              :size="SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
              data-test-id="icon"
            />
          </div>
          <template #popper>
            <div class="v1-MuiTooltip__tooltip px-2 py-3 emobg-font-weight-light emobg-border-radius-medium emobg-background-color-secondary emobg-color-white">
              <p>Sift Score is a number between 0 and 100</p>
              <ul class="ml-4">
                <li>0 indicates low risk</li>
                <li>100 indicates high risk</li>
              </ul>
            </div>
          </template>
        </MuiPopper>
      </div>
    </div>
    <div
      :class="scoreColor"
      class="emobg-body-1 emobg-font-weight-semibold mt-1"
    >
      {{ isNumber(score) ? score : FALLBACK_MESSAGE.noData }}
    </div>
  </div>
</template>
<style lang="scss">
  .SiftScoreCell {
    ul {
      list-style: disc !important;
    }
  }
</style>
