var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserStatusModal",
        attrs: {
          title: `Change ${_vm.profile} status`,
          header: { isClosable: true },
          size: _vm.SIZES.small,
          buttons: _vm.modalButtons,
          "data-test-id": "user_status-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.users,
                    scope: _vm.USER_SCOPES.statusForm,
                    "custom-exceptions": [_vm.crmErrors.userStatusErrors],
                    element: `${_vm.profile} status`,
                    "is-editing": "",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("MuiAlgoliaSelect", {
                  staticClass: "w-100",
                  attrs: {
                    index: _vm.ALGOLIA_INDEXES.status,
                    title: (status) => status.description,
                    label: `${_vm.profileCapitalized} status*`,
                    filters: _vm.statusFilters,
                    name: "statusId",
                    "no-cache": "",
                    "path-value": "id",
                    placeholder: "Select a status",
                    "data-test-id": "status-select",
                  },
                  model: {
                    value: _vm.inputs.statusId,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "statusId", $$v)
                    },
                    expression: "inputs.statusId",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }