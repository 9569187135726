var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CancelAccount",
        attrs: {
          header: { title: "Cancel this account?", isClosable: true },
          size: _vm.SIZES.small,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.checkCancelAccountStatus.LOADING
                  ? _c("ui-loader", {
                      attrs: { absolute: "", "data-test-id": "loader" },
                    })
                  : _vm.checkCancelAccountStatus.LOADED
                  ? _c("div", [
                      _c("p", { staticClass: "mb-4" }, [
                        _vm._v(
                          " By doing so, the data anonymization process will be triggered which means that: "
                        ),
                      ]),
                      _c("ul", { staticClass: "pl-4" }, [
                        _c("li", [
                          _vm._v("The user won't be able to log in anymore"),
                        ]),
                        _c("li", [
                          _vm._v("The user will receive an email confirmation"),
                        ]),
                        _c("li", [
                          _vm._v(
                            "The user data will be deleted on the corresponding anonymization date"
                          ),
                        ]),
                      ]),
                    ])
                  : _vm.checkCancelAccountStatus.ERROR
                  ? _c("div", [
                      _c("p", { staticClass: "mb-4" }, [
                        _vm._v(
                          " This account cannot be cancelled for one of the following reasons: "
                        ),
                      ]),
                      _c("ul", { staticClass: "pl-4 mb-4" }, [
                        _c("li", [
                          _vm._v("The user has current or pending bookings"),
                        ]),
                        _c("li", [_vm._v("The user has a pending debt")]),
                      ]),
                      _c("p", [
                        _vm._v(" The user will receive email notification. "),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "DeleteButton",
                  {
                    attrs: {
                      disabled: !_vm.checkCancelAccountStatus.LOADED,
                      loading: _vm.cancelAccountStatus.LOADING,
                      "data-test-id": "delete-button",
                    },
                    on: { click: _vm.cancelAccount },
                  },
                  [_vm._v(" Cancel account ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }