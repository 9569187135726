import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crmRoutes from '@domains/CRM/router/CRMRouterMap';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';

export const USER_SUBSCRIPTION_TABS = [
  {
    label: {
      title: 'User tariff',
    },
    url: crmRoutes.users.detail.tariffs.userTariff,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
  {
    label: {
      title: 'Company tariff',
    },
    url: crmRoutes.users.detail.tariffs.companyTariff,
    permissions: [CRM_PERMISSIONS.viewCrmUsers],
  },
];

const getStatusBadgeProps = tariff => ({
  text: tariff.active && !tariff.isFuture ? 'Active tariff' : 'Next tariff',
  color: tariff.active && !tariff.isFuture ? COLORS.success : GRAYSCALE.ground,
});

export const TARIFFS_SCHEMA = (hasStatus = true) => {
  const schema = [
    {
      header: 'Name',
      minWidth: 250,
      template: tariff => tariff.tariffName || FALLBACK_MESSAGE.dash,
    },
    {
      header: 'Start date',
      minWidth: 150,
      template: tariff => tariff ? reformatDateTime(tariff.start, DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash,
    },
    {
      header: 'End date',
      minWidth: 150,
      template: tariff => tariff ? reformatDateTime(tariff.end, DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash,
    },
    {
      header: 'Profile',
      minWidth: 100,
      template: tariff => sentenceCase(tariff.profile) || FALLBACK_MESSAGE.dash,
    },
    {
      header: 'Fleet',
      minWidth: 150,
      template: tariff => `${sentenceCase(tariff.fleet)} fleet`,
    },
    {
      header: 'Periodicity',
      minWidth: 150,
      template: tariff => sentenceCase(tariff.period) || FALLBACK_MESSAGE.dash,
    },
    {
      header: 'Next payment on',
      minWidth: 150,
      template: tariff => tariff.nextPayday || FALLBACK_MESSAGE.dash,
    },
    {
      header: 'Fee',
      minWidth: 150,
      template: tariff => sentenceCase(tariff.fee) || FALLBACK_MESSAGE.dash,
    },
  ];

  if (hasStatus) {
    schema.push({
      header: 'Tariff status',
      minWidth: 130,
      component: BadgeComponent,
      props: tariff => getStatusBadgeProps(tariff),
    });
  }

  return schema;
};
