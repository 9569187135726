<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      resetPasswordStatus: state => state.resetPassword.STATUS,
    }),
  },
  methods: {
    get,
    async resetPassword() {
      await this.putResetPassword({ userUuid: this.user.uuid });
      if (!this.resetPasswordStatus.ERROR) {
        this.$notify({
          message: 'Password reset email successfully <span class="emobg-font-weight-semibold">sent</span>',
          textAction: '',
        });
      }
      this.$emit('closeModal');
    },
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'putResetPassword',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="ResetPassword"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        :text="`This will send a password reset link to the account email <span class='emobg-font-weight-semibold'>${user.info.email}</span>`"
        title="Regenerate login?"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :loading="resetPasswordStatus.LOADING"
        data-test-id="send-button"
        @clickbutton="resetPassword"
      >
        Regenerate login
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
