import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  TIME_ZONE,
  reformatDateTime,
} from '@emobg/web-utils';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';

export function contentCells({ operatorTimezone }) {
  return [
    {
      attributeName: 'start',
      title: 'Start date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'end',
      title: 'End date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'agent',
      title: 'User',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      transformResult: result => `${result.agent.first_name} ${result.agent.last_name}`,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: CRM_ROUTES.users.detail.index,
          params: { userUuid: result.agent.user_uuid },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `${result.agent.first_name} ${result.agent.last_name}`,
      }),
      minWidth: 100,
    },
    {
      title: 'Reason',
      attributeName: 'reason.name',
      displayPriority: 1,
      minWidth: 250,
    },
  ];
}

export const facets = ({ operatorTimezone }) => [
  {
    type: 'rangeDateTimePicker',
    props: {
      title: 'Start date',
      attributeName: 'start_ts',
      timezone: operatorTimezone || TIME_ZONE.default,
      from: null,
      labels: {
        removeButton: 'Clear dates',
      },
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Reason',
      attributeName: 'reason.name',
    },
  },
];
