export const PREMIUM_USER_OPTIONS = {
  yes: true,
  no: false,
  automatic: null,
};

export const PAYMENT_MODES = {
  payPerUse: 'pay_per_use',
  payMonthly: 'pay_monthly',
};
