var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Documents" },
    [
      _c(
        "ui-card",
        { staticClass: "w-100", attrs: { "data-test-id": "documents-card" } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center",
            },
            [
              _c(
                "h2",
                {
                  staticClass: "emobg-color-ink",
                  attrs: { "data-test-id": "title" },
                },
                [_vm._v(" Documents ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: { face: _vm.FACES.outline },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isAddDocumentModalOpen = true
                    },
                  },
                },
                [_vm._v(" Upload document ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm.documentsStatus.LOADING
                ? _c("ui-loader", {
                    attrs: { absolute: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c("TableComponent", {
                staticClass: "mt-3",
                attrs: {
                  schema: _vm.documentsSchema,
                  data: _vm.documents,
                  "row-actions": _vm.documentRowActions,
                  "empty-label": "No documents yet",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isPreviewModalOpen
        ? _c("DocumentPreviewModal", {
            attrs: { "start-index": _vm.startIndex },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isAddDocumentModalOpen
        ? _c("DocumentUploadForm", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalOpen
        ? _c("DeleteDocumentModal", {
            attrs: {
              document: _vm.documentToDelete,
              callback: _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }