<script>
import { sentenceCase } from '@emobg/web-utils';
import { USER_STATUS_COLORS } from '../../../../constants/userStatus';

export default {
  name: 'UserStatusBadge',
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.USER_STATUS_COLORS = USER_STATUS_COLORS;
  },
  methods: {
    sentenceCase,
  },
};
</script>
<template>
  <ui-badge :color="USER_STATUS_COLORS[status.name]">
    {{ status.description }}
  </ui-badge>
</template>
