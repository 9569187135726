var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PersonalSettings" },
    [
      _c(
        "ui-card",
        {
          staticClass: "position-relative",
          attrs: {
            header: "Personal information",
            "data-test-id": "personal_settings-card",
          },
        },
        [
          _c("ui-loader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            attrs: { absolute: "", "data-test-id": "loader" },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-content-between align-items-top" },
            [
              _c("div", { staticClass: "w-100 row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-3" },
                  [
                    _c(
                      "ContentCellComponent",
                      {
                        staticClass: "mb-3",
                        attrs: { label: "Title, Full Name" },
                      },
                      [
                        _vm.get(_vm.user, "info.title.name")
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.sentenceCase(_vm.user.info.title.name)
                                  ) +
                                  ", "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.userName) + " "),
                      ]
                    ),
                    _c("ContentCellComponent", {
                      staticClass: "mb-3",
                      attrs: {
                        value: _vm.get(_vm.user, "info.birthdate")
                          ? _vm.reformatDateTime(
                              _vm.get(_vm.user, "info.birthdate"),
                              _vm.DATE_FORMAT.dob
                            )
                          : _vm.FALLBACK_MESSAGE.dash,
                        label: "Date of birth",
                      },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-3",
                      attrs: {
                        value: _vm.get(
                          _vm.user,
                          "info.identityIssuingCountryName",
                          _vm.FALLBACK_MESSAGE.dash
                        ),
                        label: "ID issuing country",
                      },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-3",
                      attrs: {
                        value: _vm.get(
                          _vm.user,
                          "info.identityType",
                          _vm.FALLBACK_MESSAGE.dash
                        ),
                        label: "Document type",
                      },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-3",
                      attrs: {
                        value: _vm.get(
                          _vm.user,
                          "info.identityNumber",
                          _vm.FALLBACK_MESSAGE.dash
                        ),
                        label: "ID number",
                      },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-3",
                      attrs: {
                        value: _vm.get(
                          _vm.user,
                          "info.addressStreet1",
                          _vm.FALLBACK_MESSAGE.dash
                        ),
                        label: "Address",
                      },
                    }),
                    _c("ContentCellComponent", {
                      staticClass: "mb-3",
                      attrs: {
                        value: _vm.get(
                          _vm.user,
                          "info.addressStreet2",
                          _vm.FALLBACK_MESSAGE.dash
                        ),
                        label: "Floor, door, apartment...",
                      },
                    }),
                    _vm.get(_vm.user, "info.extraDocuments.codiceFiscale")
                      ? _c("ContentCellComponent", {
                          staticClass: "mb-3",
                          attrs: {
                            value: _vm.get(
                              _vm.user,
                              "info.extraDocuments.codiceFiscale"
                            ),
                            label: "Fiscal code",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-9" },
                  [
                    !_vm.isLoading
                      ? _c("GoogleMapComponent", {
                          attrs: { markers: _vm.mapMarkers },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("EditButton", {
                staticClass: "ml-2",
                on: {
                  click: function ($event) {
                    _vm.isModalOpen = true
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalOpen
        ? _c("EditPersonalSettings", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }