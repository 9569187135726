var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserTariffForm",
        attrs: {
          title: `${_vm.userTariff ? "Edit current" : "Add"} user tariff`,
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          "data-test-id": "user_tariff_form-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _vm.userTariff
                      ? _c(
                          "div",
                          [
                            _c("ContentCellComponent", {
                              staticClass: "mb-4",
                              attrs: {
                                value: _vm.userTariff.tariffName,
                                label: "Tariff name",
                              },
                            }),
                            _c("ContentCellComponent", {
                              staticClass: "mb-4",
                              attrs: {
                                value: _vm.reformatDateTime(
                                  _vm.userTariff.start,
                                  _vm.DATE_FORMAT.dob
                                ),
                                label: "Start date",
                              },
                            }),
                          ],
                          1
                        )
                      : [
                          _c(
                            "div",
                            { staticClass: "d-inline-flex flex-column mb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-weight-semibold mb-1",
                                },
                                [_vm._v(" Filter ")]
                              ),
                              _c("ui-radio", {
                                staticClass: "d-inline-block mb-2",
                                attrs: {
                                  value: _vm.isRecommendedTariffFilter,
                                  option: true,
                                  caption: "Recommended tariffs",
                                  name: "tariffs_filter",
                                  "data-test-id": "recommended_tariffs-radio",
                                },
                                on: {
                                  changevalue: () =>
                                    (_vm.isRecommendedTariffFilter = true),
                                },
                              }),
                              _c("ui-radio", {
                                attrs: {
                                  value: _vm.isRecommendedTariffFilter,
                                  option: false,
                                  caption: "All tariffs",
                                  name: "filter_tariff",
                                  "data-test-id": "all_tariffs-radio",
                                },
                                on: {
                                  changevalue: () =>
                                    (_vm.isRecommendedTariffFilter = false),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _vm.availableUserTariffsStatus.LOADING
                                ? _c("ui-skeleton", {
                                    attrs: {
                                      "data-test-id": "select-skeleton",
                                    },
                                  })
                                : _c("MuiSelect", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      options: _vm.getSelectTariffOptions(
                                        _vm.availableUserTariffs
                                      ),
                                      "path-value": "uuid",
                                      label: "Tariff name*",
                                      placeholder: "Select a tariff",
                                      name: "tariffUuid",
                                    },
                                    on: {
                                      change: (tariffUuid) =>
                                        (_vm.tariffSelected = _vm.find(
                                          _vm.availableUserTariffs,
                                          { uuid: tariffUuid }
                                        )),
                                    },
                                    model: {
                                      value: _vm.inputs.tariffUuid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "tariffUuid", $$v)
                                      },
                                      expression: "inputs.tariffUuid",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                    _vm.hasTariffQuotas
                      ? _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiSelect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n            isRequired: true,\n          }",
                                },
                              ],
                              staticClass: "w-100 text-capitalize",
                              attrs: {
                                options: _vm.getSelectPeriodOptions(
                                  _vm.availableTariffPeriods
                                ),
                                label: "Periodicity*",
                                name: "periodicity",
                                placeholder: "Select",
                                "data-test-id": "periodicity-select",
                              },
                              model: {
                                value: _vm.inputs.period,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "period", $$v)
                                },
                                expression: "inputs.period",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        !_vm.userTariff
                          ? _c("MuiDatePicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                    isValidDate:
                                      _vm.DATE_INPUT_VALIDATIONS.validDate,
                                    isBeforeCurrentTariff:
                                      _vm.isBeforeCurrentTariff,
                                  },
                                  expression:
                                    "{\n            isRequired: true,\n            isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            isBeforeCurrentTariff,\n          }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                date: _vm.startInput,
                                "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
                                "skip-time-step": true,
                                label: "Start date*",
                                placeholder: "Start date",
                                "data-test-id": "start-input",
                              },
                              on: { "update:date": _vm.setStart },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("MuiDatePicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                                isValidDate:
                                  _vm.DATE_INPUT_VALIDATIONS.validDate,
                              },
                              expression:
                                "{\n            isRequired: true,\n            isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n          }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            date: _vm.endInput,
                            "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
                            "skip-time-step": true,
                            label: "End date*",
                            placeholder: "End date",
                            "data-test-id": "end-input",
                          },
                          on: { "update:date": _vm.setEnd },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.userTariffForm,
              action: `${_vm.userTariff ? "Edit" : "Add"} user tariff`,
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }