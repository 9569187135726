<script>
import moment from 'moment-timezone';
import get from 'lodash/get';
import size from 'lodash/size';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { GRAYSCALE } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  isNilString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import {
  ContentCellComponent,
  CopyToClipboard,
  OverviewCollapse,
} from '@/components';
import { PROFILES_TYPES, USER_STATUS } from '../../../constants/userStatus';
import {
  SiftScoreCell,
  UserBookingLink,
  UserImpersonateLogOutModal,
  UserImpersonationRequest,
  UserStatusBadge,
  UserStatusModal,
} from './components';

export default {
  components: {
    ContentCellComponent,
    CopyToClipboard,
    OverviewCollapse,
    SiftScoreCell,
    UserBookingLink,
    UserImpersonateLogOutModal,
    UserImpersonationRequest,
    UserStatusBadge,
    UserStatusModal,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shownAlerts: 1,
      isImpersonateModalVisible: false,
      isImpersonateLogOutModalVisible: false,
      isStatusModalVisible: false,
      statusModalProfile: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      impersonationStatus: state => state[USER_SCOPES.impersonation].STATUS,
    }),
    userName() {
      return `${get(this, 'user.info.firstName')} ${get(this, 'user.info.lastName')}`;
    },
    userAge() {
      const birthdate = get(this, 'user.info.birthdate');
      return birthdate
        ? moment().diff(moment(birthdate), 'years')
        : null;
    },
    premiumUserText() {
      const status = {
        true: 'Yes',
        false: 'No',
        null: 'Automatic',
      };

      return status[get(this, 'user.info.isPremium')];
    },
    isEditableStatus() {
      return [
        USER_STATUS.activated,
        USER_STATUS.incomplete,
        USER_STATUS.waitingForValidation,
        USER_STATUS.preRegistered,
        USER_STATUS.blocked,
        USER_STATUS.watchlisted,
        USER_STATUS.unpaid,
        USER_STATUS.deactivated,
      ].includes(get(this, 'user.status.personal.name'));
    },
    fleetSegmentText() {
      const preposition = get(this, 'user.notes.belongsToCirclesWithFleetSegments') ? 'with' : 'without';
      const text = `Belongs to circle(s) ${preposition} fleet segment`;
      const hasFleetSegment = get(this, 'user.notes.belongsToCirclesWithFleetSegments')
      || get(this, 'user.notes.belongsToCirclesWithoutFleetSegments');

      return hasFleetSegment ? text : null;
    },
    hasNotes() {
      return get(this, 'user.notes.hasAnnualCover')
        || get(this, 'user.notes.belongsToCirclesWithFleetSegments')
        || get(this, 'user.notes.belongsToCirclesWithoutFleetSegments');
    },
  },
  created() {
    this.PROFILES_TYPES = PROFILES_TYPES;
    this.GRAYSCALE = GRAYSCALE;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getUserImpersonate',
    ]),
    sentenceCase,
    isNilString,
    reformatDateTime,
    get,
    size,
    getUserPhone(type) {
      return get(this.user.profiles, `${type}.phoneNumber`) || null;
    },
    openStatusModal(profile) {
      this.statusModalProfile = profile;
      this.isStatusModalVisible = true;
    },
    closeStatusModal() {
      this.isStatusModalVisible = false;
      this.statusModalProfile = null;
    },
  },
};
</script>
<template>
  <div
    class="UserDetailsHeader"
    data-test-id="user_details_header"
  >
    <div class="row">
      <div class="col-12">
        <OverviewCollapse
          v-if="size(user)"
          id="userOverview"
        >
          <template #header>
            <div
              class="d-flex flex-wrap align-items-center"
              data-test-id="name"
            >
              <h2 class="emobg-font-weight-semibold mr-3">
                <span
                  v-if="get(user, 'info.title.name')"
                  class="emobg-color-ink-light"
                >
                  {{ sentenceCase(user.info.title.name) }},
                </span>
                {{ userName }}
              </h2>
            </div>
          </template>
          <template
            v-if="user.idpUuid"
            #actions
          >
            <ui-button
              :face="FACES.outline"
              class="ml-3"
              @clickbutton="isImpersonateModalVisible = true"
            >
              Impersonate
            </ui-button>
          </template>
          <div class="py-4">
            <ui-alert
              v-if="get(user, 'invoicing.pendingPayment')"
              :color="COLORS.warning"
              :icon="ICONS.alertFull"
              class="pb-4 d-block w-100"
              data-test-id="warning_message-alert"
            >
              User with unpaid invoice and/or open proforma invoice.
              <span class="emobg-color-danger">Pending payment (-{{ user.invoicing.pendingPayment }})</span>
            </ui-alert>
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="Date of birth"
                  is-on-header
                  class="mb-4"
                >
                  <template v-if="userAge">
                    <p class="mb-1">
                      {{ reformatDateTime(user.info.birthdate, DATE_FORMAT.dob) }}
                    </p>
                    <span
                      class="emobg-color-ink-light"
                      data-test-id="age"
                    >
                      Age: {{ userAge }}
                    </span>
                  </template>
                  <template v-else>
                    {{ FALLBACK_MESSAGE.dash }}
                  </template>
                </ContentCellComponent>
                <ContentCellComponent
                  label="Residence"
                  is-on-header
                  class="mb-4"
                >
                  <p
                    class="mb-1"
                    data-test-id="city"
                  >
                    {{ get(user, 'info.city', FALLBACK_MESSAGE.dash) }}, {{ get(user, 'info.country', FALLBACK_MESSAGE.dash) }}
                  </p>
                  <p
                    class="emobg-color-ink-light mb-1"
                    data-test-id="language"
                  >
                    Language: {{ get(user, 'info.language.name', FALLBACK_MESSAGE.dash) }}
                  </p>
                  <span
                    class="emobg-color-ink-light"
                    data-test-id="operator"
                  >
                    Operator: {{ get(user, 'info.operatorName', FALLBACK_MESSAGE.dash) }}
                  </span>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="get(user, 'info.email')"
                  label="Account email"
                  is-on-header
                  class="mb-4"
                >
                  <CopyToClipboard data-test-id="copy_email-button">
                    {{ user.info.email }}
                  </CopyToClipboard>
                </ContentCellComponent>
                <ContentCellComponent
                  label="Phone number"
                  is-on-header
                  class="mb-4"
                >
                  <div
                    v-if="getUserPhone(PROFILES_TYPES.personal)"
                    class="d-flex"
                    data-test-id="personal_phone"
                  >
                    Personal:
                    <CopyToClipboard
                      data-test-id="copy_personal_phone-action"
                      class="ml-1"
                    >
                      {{ getUserPhone(PROFILES_TYPES.personal) }}
                    </CopyToClipboard>
                  </div>
                  <div
                    v-if="getUserPhone(PROFILES_TYPES.business)"
                    class="d-flex"
                    data-test-id="business_phone"
                  >
                    Business:
                    <CopyToClipboard
                      data-test-id="copy_business_phone-action"
                      class="ml-1"
                    >
                      {{ getUserPhone(PROFILES_TYPES.business) }}
                    </CopyToClipboard>
                  </div>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="hasNotes"
                  label="Notes"
                  is-on-header
                >
                  <div
                    v-if="get(user, 'notes.hasAnnualCover')"
                    class="d-flex align-items-center"
                    data-test-id="annual_cover-text"
                  >
                    <ui-icon
                      :icon="ICONS.insuranceAnnual"
                      :size="ICONS_SIZES.small"
                    />
                    <p class="ml-1">
                      Has annual insurance
                    </p>
                  </div>
                  <div
                    v-if="fleetSegmentText"
                    class="d-flex align-items-center"
                    data-test-id="fleet_segment-text"
                  >
                    <ui-icon
                      :icon="ICONS.userCircle"
                      :size="ICONS_SIZES.small"
                    />
                    <p class="ml-1">
                      {{ fleetSegmentText }}
                    </p>
                  </div>
                </ContentCellComponent>
              </div>
              <div class="col-md-6 col-lg-3">
                <SiftScoreCell
                  :score="user.info.profilingScore"
                  class="mb-4"
                  data-test-id="sift_score"
                />
                <ContentCellComponent
                  v-if="user.status.personal"
                  label="Personal status"
                  is-on-header
                  class="mb-4"
                >
                  <div class="d-flex align-items-center">
                    <UserStatusBadge
                      :status="user.status.personal"
                      data-test-id="personal_status-badge"
                    />
                    <ui-icon
                      v-if="isEditableStatus"
                      :icon="ICONS.edit"
                      :color="GRAYSCALE.inkLight"
                      :size="ICONS_SIZES.small"
                      class="ml-1 cursor-pointer"
                      data-test-id="personal_status-action"
                      @click="openStatusModal(PROFILES_TYPES.personal)"
                    />
                  </div>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="user.status.business"
                  label="Business status"
                  is-on-header
                  class="mb-4"
                >
                  <div class="d-flex align-items-center">
                    <UserStatusBadge
                      :status="user.status.business"
                      data-test-id="business_status-badge"
                    />
                    <ui-icon
                      :icon="ICONS.edit"
                      :color="GRAYSCALE.inkLight"
                      :size="ICONS_SIZES.small"
                      class="ml-1 cursor-pointer"
                      data-test-id="business_status-action"
                      @click="openStatusModal(PROFILES_TYPES.business)"
                    />
                  </div>
                </ContentCellComponent>

                <ContentCellComponent
                  label="Alerts"
                  class="mb-4"
                  is-on-header
                >
                  <template v-if="user.alerts.length">
                    <div
                      v-for="(alert, index) in user.alerts.slice(0, shownAlerts)"
                      :key="index"
                      :data-test-id="`alert_${index}`"
                      class="d-flex align-items-center py-1"
                    >
                      <span class="emobg-border-radius-large p-2 emobg-background-color-warning d-inline-block mr-1" /> {{ alert }}
                    </div>
                    <span
                      v-if="user.alerts.length > shownAlerts"
                      class="cursor-pointer emobg-font-weight-semibold emobg-color-primary"
                      data-test-id="show_alerts-action"
                      @click="shownAlerts = user.alerts.length"
                    >
                      Show all ({{ user.alerts.length }})
                    </span>
                  </template>
                  <div
                    v-else
                    class="d-flex align-items-center"
                    data-test-id="no_alerts"
                  >
                    <span class="emobg-border-radius-large p-2 emobg-background-color-ground-light d-inline-block mr-1" />
                    <span class="emobg-color-ink-light">
                      No alerts
                    </span>
                  </div>
                </ContentCellComponent>
              </div>
              <div class="col-md-6 col-lg-3">
                <UserBookingLink
                  :booking="user.bookings.upcoming"
                  label="Upcoming booking"
                />
                <UserBookingLink
                  :booking="user.bookings.inProgress"
                  label="Booking in progress"
                />
                <UserBookingLink
                  :booking="user.bookings.lastFinished"
                  label="Last finished booking"
                />
                <ContentCellComponent
                  label="Total finished bookings"
                  is-on-header
                  class="mb-4"
                  data-test-id="total_finished_bookings-text"
                >
                  {{ user.bookings.totalFinished }}
                </ContentCellComponent>
                <ContentCellComponent
                  label="Premium user"
                  is-on-header
                  class="mb-4"
                >
                  <ui-badge
                    :color="GRAYSCALE.ground"
                    data-test-id="is_premium-badge"
                  >
                    {{ premiumUserText }}
                  </ui-badge>
                </ContentCellComponent>
              </div>
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="Created"
                  is-on-header
                  class="mb-4"
                  data-test-id="created_at-text"
                >
                  {{ user.info.createdAt ? reformatDateTime(user.info.createdAt, DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash }}
                </ContentCellComponent>

                <ContentCellComponent
                  label="Activated"
                  is-on-header
                  class="mb-4"
                  data-test-id="activated_at-text"
                >
                  {{ user.info.activationDate ? reformatDateTime(user.info.activationDate, DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash }}
                </ContentCellComponent>

                <ContentCellComponent
                  label="Remaining promo credit"
                  is-on-header
                  class="mb-4"
                  data-test-id="promo_credit-text"
                >
                  {{ user.invoicing.promoCredit || FALLBACK_MESSAGE.dash }}
                </ContentCellComponent>

                <ContentCellComponent
                  label="Total revenue"
                  is-on-header
                  class="mb-4"
                  data-test-id="total_revenue-text"
                >
                  {{ user.invoicing.totalRevenue || FALLBACK_MESSAGE.dash }}
                </ContentCellComponent>

                <ContentCellComponent
                  label="Tariff"
                  is-on-header
                  class="mb-4"
                  data-test-id="tariff-text"
                >
                  {{ user.invoicing.tariff ? sentenceCase(user.invoicing.tariff) : FALLBACK_MESSAGE.dash }}
                </ContentCellComponent>
              </div>
            </div>
          </div>
        </OverviewCollapse>
      </div>
    </div>

    <UserStatusModal
      v-if="isStatusModalVisible"
      :profile="statusModalProfile"
      :on-success="onSuccess"
      @closeModal="closeStatusModal"
    />

    <UserImpersonationRequest
      v-if="isImpersonateModalVisible"
      :on-success="() => isImpersonateLogOutModalVisible = true"
      @closeModal="isImpersonateModalVisible = false"
    />

    <UserImpersonateLogOutModal
      v-if="isImpersonateLogOutModalVisible"
      @closeModal="isImpersonateLogOutModalVisible = false"
    />
  </div>
</template>
