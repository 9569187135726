var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EditGeneralSettings",
        attrs: {
          title: "Edit general settings",
          header: { isClosable: true },
          "data-test-id": "edit_general_settings-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.editSettings,
              "is-editing": "",
              element: "general settings",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "ContentCellComponent",
                { staticClass: "mb-5", attrs: { label: "Account email" } },
                [_vm._v(" " + _vm._s(_vm.user.info.email) + " ")]
              ),
              _c(
                "ContentCellComponent",
                { staticClass: "mb-5", attrs: { label: "Operator" } },
                [_vm._v(" " + _vm._s(_vm.user.info.operatorName) + " ")]
              ),
              _c("MuiAlgoliaSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  title: (locale) => `${locale.code} ${locale.name}`,
                  index: _vm.localesIndex,
                  filters: "active: true",
                  label: "Language",
                  "path-value": "code",
                  placeholder: "Select",
                  name: "locale",
                  "data-test-id": "locale-select",
                },
                model: {
                  value: _vm.inputs.localeCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputs, "localeCode", $$v)
                  },
                  expression: "inputs.localeCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close_modal-button" },
              on: { click: () => _vm.$emit("closeModal") },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: _vm.hasSameValues || !_vm.isFormValid,
                  loading: _vm.editSettingsStatus.LOADING,
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.saveSettings },
              },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }