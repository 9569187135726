var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserTariffs",
      attrs: { "data-test-id": "user_tariffs-view" },
    },
    [
      _c("h1", { staticClass: "flex-grow-1 mb-2" }, [_vm._v(" User tariff ")]),
      _c(
        "ui-card",
        {
          staticClass: "w-100 mb-4 d-block",
          attrs: { "data-test-id": "current_tariffs-card" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center",
            },
            [
              _c(
                "h3",
                {
                  staticClass: "emobg-color-ink emobg-font-weight-semibold",
                  attrs: { "data-test-id": "title" },
                },
                [_vm._v(" Current tariffs ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: { disabled: _vm.isAddButtonDisabled },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isFormModalOpen = true
                    },
                  },
                },
                [_vm._v(" Add user tariff ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm.userTariffsStatus.LOADING
                ? _c("ui-loader", {
                    attrs: { absolute: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c("TableComponent", {
                staticClass: "mt-3",
                attrs: {
                  schema: _vm.tariffSchema(),
                  data: _vm.currentTariffs,
                  "row-actions": _vm.currentTariffsActions,
                  "empty-label": "No tariff",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "ui-card",
        {
          staticClass: "mb-4 d-block",
          attrs: {
            header: "Previous tariffs",
            "data-test-id": "previous_tariffs-card",
          },
        },
        [
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _vm.userTariffsStatus.LOADING
                ? _c("ui-loader", {
                    attrs: { absolute: "", "data-test-id": "loader" },
                  })
                : _vm._e(),
              _c("TableComponent", {
                staticClass: "mt-1",
                attrs: {
                  schema: _vm.tariffSchema(false),
                  data: _vm.previousTariffs,
                  "empty-label": "No tariff",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isFormModalOpen
        ? _c("UserTariffForm", {
            attrs: {
              "user-tariff": _vm.editingUserTariff,
              "on-success": _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalOpen
        ? _c("DeleteUserTariffModal", {
            attrs: { tariff: _vm.tariffToDelete, callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }