var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "EditGeneralSettings",
        attrs: {
          title: "Edit business profile",
          header: { isClosable: true },
          "data-test-id": "edit_business_profile-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.editSettings,
              "is-editing": "",
              element: "business profile",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "ContentCellComponent",
                { staticClass: "mb-5", attrs: { label: "Business status" } },
                [
                  _c("UserStatusBadge", {
                    attrs: { status: _vm.user.status.business },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiInputText", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isEmail: true,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isEmail: true,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      label: "Notification email*",
                      placeholder: "Enter business email",
                      "data-test-id": "email",
                      name: "email",
                    },
                    model: {
                      value: _vm.inputs.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "email", $$v)
                      },
                      expression: "inputs.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("PhoneInputComponent", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: true,
                          isValidPhone: _vm.PHONE_VALIDATIONS.isValidPhone,
                        },
                        expression:
                          "{\n            isRequired: true,\n            isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      label: "Notification phone number*",
                      placeholder: "Enter business phone",
                      "data-test-id": "phone",
                    },
                    model: {
                      value: _vm.inputs.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "phone", $$v)
                      },
                      expression: "inputs.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "ContentCellComponent",
                { staticClass: "mb-4", attrs: { label: "Employee user role" } },
                _vm._l(
                  _vm.get(_vm.user, "profiles.business.frontofficeRoles", []),
                  function (role) {
                    return _c("p", { key: role }, [
                      _vm._v(" " + _vm._s(_vm.sentenceCase(role)) + " "),
                    ])
                  }
                ),
                0
              ),
              _c("ContentCellComponent", { attrs: { label: "Company name" } }, [
                _vm.get(_vm.user, "profiles.business.company.uuid")
                  ? _c(
                      "a",
                      {
                        staticClass: "emobg-link-primary emobg-body-2",
                        attrs: {
                          href: _vm.companyProfileUrl(
                            _vm.user.profiles.business.company.uuid
                          ),
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.profiles.business.company.name) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close_modal-button" },
              on: { click: () => _vm.$emit("closeModal") },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: _vm.hasSameValues || !_vm.isFormValid,
                  loading: _vm.editSettingsStatus.LOADING,
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.saveSettings },
              },
              [_vm._v(" Save ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }