var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AccountSettings" },
    [
      _c(
        "ui-card",
        {
          staticClass: "w-100 position-relative",
          attrs: {
            header: "Account settings",
            "data-test-id": "account_settings",
          },
        },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("h3", { staticClass: "mb-3" }, [
                  _vm._v(" General settings "),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-3",
                          attrs: { label: "Account email" },
                        },
                        [
                          _c("p", { staticClass: "text-break" }, [
                            _vm._v(" " + _vm._s(_vm.user.info.email) + " "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-3", attrs: { label: "Operator" } },
                        [_vm._v(" " + _vm._s(_vm.user.info.operatorName) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        { staticClass: "mb-3", attrs: { label: "Language" } },
                        [
                          _vm.get(_vm.user, "info.language.code")
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.info.language.code) +
                                    " " +
                                    _vm._s(_vm.user.info.language.name) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("EditButton", {
                on: {
                  click: function ($event) {
                    _vm.isGeneralSettingsOpen = true
                  },
                },
              }),
            ],
            1
          ),
          _vm.get(_vm.user, "profiles.personal")
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center emobg-border-top-1 emobg-border-color-ground-light pt-3",
                },
                [
                  _c("div", { staticClass: "w-100" }, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v(" Personal profile "),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Notification channels" },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.profiles.personal.email) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.user.profiles.personal.phoneNumber
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Personal status" },
                            },
                            [
                              _c("UserStatusBadge", {
                                attrs: {
                                  status: _vm.user.status.personal,
                                  "data-test-id": "personal_status-badge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Premium user" },
                            },
                            [
                              _c(
                                "ui-badge",
                                {
                                  attrs: {
                                    color: _vm.GRAYSCALE.ground,
                                    "data-test-id": "is_premium-badge",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.premiumUserText) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Payment mode" },
                            },
                            [
                              _vm.get(_vm.user, "profiles.personal.paymentMode")
                                ? _c(
                                    "ui-badge",
                                    {
                                      attrs: {
                                        color: _vm.GRAYSCALE.ground,
                                        "data-test-id": "payment_mode-badge",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.sentenceCase(
                                              _vm.user.profiles.personal
                                                .paymentMode
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("EditButton", {
                    on: {
                      click: function ($event) {
                        _vm.isEditPersonalProfileOpen = true
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.get(_vm.user, "profiles.business")
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center emobg-border-top-1 emobg-border-color-ground-light pt-3",
                },
                [
                  _c("div", { staticClass: "w-100" }, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v(" Business profile "),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Notification channels" },
                            },
                            [
                              _c("p", { staticClass: "text-break" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.profiles.business.email) +
                                    " "
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.user.profiles.business.phoneNumber
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Business status" },
                            },
                            [
                              _c("UserStatusBadge", {
                                attrs: {
                                  status: _vm.user.status.business,
                                  "data-test-id": "business_status-badge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "Employee user role" },
                            },
                            _vm._l(
                              _vm.get(
                                _vm.user,
                                "profiles.business.frontofficeRoles",
                                []
                              ),
                              function (role) {
                                return _c("p", { key: role }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.sentenceCase(role)) + " "
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                      _vm.get(_vm.user, "profiles.business.company.id")
                        ? _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-3",
                                  attrs: { label: "Company name" },
                                },
                                [
                                  _vm.get(
                                    _vm.user,
                                    "profiles.business.company.uuid"
                                  )
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "emobg-link-primary emobg-body-2",
                                          attrs: {
                                            href: _vm.companyProfileUrl(
                                              _vm.user.profiles.business.company
                                                .uuid
                                            ),
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.user.profiles.business
                                                  .company.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("EditButton", {
                    on: {
                      click: function ($event) {
                        _vm.isEditBusinessProfileOpen = true
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isGeneralSettingsOpen
        ? _c("EditGeneralSettings", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isEditPersonalProfileOpen
        ? _c("EditPersonalProfile", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isEditBusinessProfileOpen
        ? _c("EditBusinessProfile", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }