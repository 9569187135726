var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DrivingLicenseUpload v1-MuiModal--adaptative",
        attrs: {
          title: `${_vm.actionText} driving license`,
          header: { isClosable: true },
          "data-test-id": "driving_license_upload-form",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.drivingLicenseUpload,
              action: `${_vm.actionText} driving license`,
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "ui-alert",
            {
              staticClass: "pb-3 d-flex w-100",
              attrs: {
                color: _vm.COLORS.warning,
                icon: _vm.ICONS.alertFull,
                "data-test-id": "warning_message-alert",
              },
            },
            [
              _vm._v(
                " Ensure to upload the correct images. Once uploaded, these images can only be changed by invalidating the driving license. "
              ),
            ]
          ),
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm._l(_vm.licenseDocuments, function (licenseDocument) {
                    return [
                      _c(
                        "div",
                        {
                          key: licenseDocument.key,
                          staticClass: "col-md-6 col-lg-4",
                        },
                        [
                          _c("label", {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                            domProps: {
                              textContent: _vm._s(licenseDocument.label),
                            },
                          }),
                          _c(
                            "DragFileComponent",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.files",
                                  value: {
                                    isMaxSize: _vm.isMaxSize,
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n                isMaxSize,\n                isRequired: true,\n              }",
                                  modifiers: { files: true },
                                },
                              ],
                              attrs: {
                                "draggable-height": 230,
                                "show-preview": "",
                                "data-test-id": "front_image-input",
                              },
                              model: {
                                value: _vm.inputs[licenseDocument.key],
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, licenseDocument.key, $$v)
                                },
                                expression: "inputs[licenseDocument.key]",
                              },
                            },
                            [
                              _c("template", { slot: "extraInformation" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-block emobg-font-x-small mt-2",
                                  },
                                  [
                                    _vm._v(
                                      " Maximum size " +
                                        _vm._s(_vm.maxSizeInMB) +
                                        " MB (JPG, PNG) "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close_modal-button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("closeModal")
                },
              },
            }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: !_vm.isFormValid,
                  loading: _vm.drivingLicenseUploadStatus.LOADING,
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.uploadDrivingLicense },
              },
              [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }