var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DocumentsPreview v1-MuiModal--adaptative",
        attrs: {
          title: "Document preview",
          header: { isClosable: true },
          "data-test-id": "documents-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("GalleryComponent", {
                      staticClass: "h-100",
                      attrs: {
                        images: _vm.mappedDocuments,
                        "start-index": _vm.startIndex,
                        "data-test-id": "gallery-component",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }