export const CODICE_FISCALE_MAX_LENGTH = 16;

export const IDENTITY_TYPE_VALIDATIONS_BY_COUNTRY = {
  ES: {
    identity_card: {
      format: /^[0-9]{8}[A-Z]$/,
      example: '12345678A',
      message: 'Only letters and numbers allowed Must have 8 numbers and finish with a letter',
      isToUpper: true,
    },
    residence: {
      format: /^[XYZ][0-9]{7}[A-Z]$/,
      example: 'A1234567B',
      message: 'Only letters and numbers allowed<br/>Must have 7 numbers and start and finish with a letter',
      isToUpper: true,
    },
  },
};

export const IDENTITY_TYPE_KEY_MAPPER = {
  nif: 'NIF',
  nie: 'NIE',
};

export const IDENTITY_CARD_KEY_ERRORS = {
  invalidCardValue: 'invalid_identity_card_value',
};
