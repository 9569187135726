<script>
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import { TableComponent } from '@/components';
import { TARIFFS_SCHEMA } from './subscriptions.const';

export default {
  name: 'UserCompanyTariffs',
  components: {
    TableComponent,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userTariffs: state => state.userTariffs.data.data,
      userTariffsStatus: state => state.userTariffs.STATUS,
    }),
    currentTariffs() {
      return get(this, 'userTariffs.business.current') || [];
    },
  },
  async created() {
    this.tariffSchema = TARIFFS_SCHEMA;
    await this.getUserTariffs({ userUuid: this.user.uuid });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getUserTariffs',
    ]),
  },
};
</script>
<template>
  <div
    data-test-id="user_company_tariffs-view"
    class="UserCompanyTariffs"
  >
    <h3 class="flex-grow-1 mb-2">
      Company tariff
    </h3>
    <ui-card
      title="Current tariffs"
      class="w-100 mb-4"
      data-test-id="current_tariffs-card"
    >
      <div class="position-relative">
        <ui-loader
          v-if="userTariffsStatus.LOADING"
          absolute
          data-test-id="loader"
        />
        <TableComponent
          :schema="tariffSchema()"
          :data="currentTariffs"
          empty-label="No tariff"
          class="mt-1"
        />
      </div>
    </ui-card>
  </div>
</template>
