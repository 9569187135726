var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UserCoverContractForm",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: { text: _vm.text, title: _vm.title },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      color: _vm.isCancelRenewal
                        ? _vm.COLORS.danger
                        : _vm.COLORS.primary,
                      loading: _vm.coverFormStatus.LOADING,
                      "data-test-id": "request-button",
                    },
                    on: { clickbutton: _vm.userCoverRequest },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isCancelRenewal
                            ? "Cancel automatic renewal"
                            : "Contract annual cover"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }