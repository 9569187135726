<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TableComponent } from '@/components';
import { BADGE_SCHEMA } from './const/badges.const';
import AddUserBadge from './AddUserBadge';
import EditUserBadge from './EditUserBadge';

export default {
  name: 'UserBadges',
  components: {
    AddUserBadge,
    EditUserBadge,
    TableComponent,
  },
  data() {
    return {
      isAddModalOpen: false,
      isEditModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userBadge: state => get(state, 'userBadge.data'),
      userBadgeStatus: state => get(state, 'userBadge.STATUS'),
    }),
    buttonTitle() {
      return this.userBadge ? 'Replace badge' : 'Add badge';
    },
  },
  async created() {
    this.badgeSchema = BADGE_SCHEMA;
    this.badgeRowActions = [
      {
        label: 'Edit badge',
        action: () => { this.isEditModalOpen = true; },
      },
    ];
    await this.getUserBadge(get(this, 'user.uuid'));
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, ['getUser', 'getUserBadge']),
    closeModal() {
      this.isAddModalOpen = false;
      this.isEditModalOpen = false;
    },
    async onFormSuccess() {
      const userUuid = get(this, 'user.uuid');
      await this.getUser(userUuid);
      await this.getUserBadge(userUuid);
    },
  },
};
</script>
<template>
  <div class="UserBadges">
    <ui-card data-test-id="badges-card">
      <div class="d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center">
        <h2
          class="emobg-color-ink"
          data-test-id="title"
        >
          Badge
        </h2>

        <ui-button
          :face="FACES.outline"
          @clickbutton="isAddModalOpen = true"
        >
          {{ buttonTitle }}
        </ui-button>
      </div>
      <div class="position-relative">
        <ui-loader
          v-if="userBadgeStatus.LOADING"
          absolute
          data-test-id="loader"
        />
        <TableComponent
          :schema="badgeSchema"
          :data="userBadge ? [userBadge] : null"
          :row-actions="badgeRowActions"
          empty-label="No badge yet"
          class="mt-3"
        />
      </div>
    </ui-card>

    <AddUserBadge
      v-if="isAddModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
    <EditUserBadge
      v-if="isEditModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
