var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UserBadges" },
    [
      _c("ui-card", { attrs: { "data-test-id": "badges-card" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center",
          },
          [
            _c(
              "h2",
              {
                staticClass: "emobg-color-ink",
                attrs: { "data-test-id": "title" },
              },
              [_vm._v(" Badge ")]
            ),
            _c(
              "ui-button",
              {
                attrs: { face: _vm.FACES.outline },
                on: {
                  clickbutton: function ($event) {
                    _vm.isAddModalOpen = true
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "position-relative" },
          [
            _vm.userBadgeStatus.LOADING
              ? _c("ui-loader", {
                  attrs: { absolute: "", "data-test-id": "loader" },
                })
              : _vm._e(),
            _c("TableComponent", {
              staticClass: "mt-3",
              attrs: {
                schema: _vm.badgeSchema,
                data: _vm.userBadge ? [_vm.userBadge] : null,
                "row-actions": _vm.badgeRowActions,
                "empty-label": "No badge yet",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.isAddModalOpen
        ? _c("AddUserBadge", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isEditModalOpen
        ? _c("EditUserBadge", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }