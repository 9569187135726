<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import map from 'lodash/map';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { base64WithoutName } from '@emobg/web-utils';
import {
  CancelButton,
  ContentCellComponent,
  DragFileComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { USER_SCOPES } from '@/domains/CRM/Users/store/UsersModule';
import { DL_STATUSES } from '../../DrivingLicense/drivingLicense.const';
import { DOCUMENT_TYPES } from '../const/documents.const';

export default {
  name: 'DocumentUploadForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    DragFileComponent,
    MuiInputText,
    MuiTextarea,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
    ContentCellComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      documentType: null,
      inputs: {
        name: null,
        description: null,
        document: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      drivingLicense: state => state.drivingLicense.data,
      addDocumentStatus: state => state.addDocument.STATUS,
    }),
    dataForRequest() {
      const data = cloneDeep(this.inputs);
      const base64 = first(this.inputs.document);
      data.document = base64 ? base64WithoutName(base64) : null;
      data.drivingLicenseUuid = this.documentType === DOCUMENT_TYPES.drivingLicenses
        ? get(this, 'drivingLicense.uuid')
        : null;

      return data;
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.DL_STATUSES = DL_STATUSES;
    this.DOCUMENT_TYPES = DOCUMENT_TYPES;
    this.maxSizeInMB = 10;
    this.resetState({ scopes: [USER_SCOPES.addDocument] });
  },
  methods: {
    get,
    map,
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['postUserDocument']),
    isMaxSize(files) {
      const file = get(files, '[0]');
      const maxSizeInBytes = this.maxSizeInMB * (10 ** 6);
      return {
        isValid: file && file.size < maxSizeInBytes,
        message: `File size should be maximum ${this.maxSizeInMB} MB`,
      };
    },
    async uploadDocument() {
      await this.postUserDocument({
        userUuid: this.user.uuid,
        data: this.dataForRequest,
      });

      if (!this.addDocumentStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Document successfully <span class="emobg-font-weight-semibold">uploaded</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Upload document"
    class="DocumentUpload"
    data-test-id="document_upload-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.addDocument"
        action="upload document"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="mb-4">
          <MuiInputText
            v-model="inputs.name"
            label="Document name"
            placeholder="Enter name (e.g. Passport)"
            class="w-100"
            name="name"
            data-test-id="name-input"
          />
        </div>
        <div class="mb-4">
          <label class="d-block emobg-font-weight-semibold mb-1"> Description </label>
          <MuiTextarea
            v-model="inputs.description"
            placeholder="Enter description (e.g. OK)"
            data-test-id="description-input"
            name="description"
            rows="1"
          />
        </div>
        <div class="mb-4">
          <ContentCellComponent
            v-if="get(drivingLicense, 'status', DL_STATUSES.rejected) === DL_STATUSES.rejected"
            :value="DOCUMENT_TYPES.users"
            label="Document type"
            data-test-id="document_type-text"
          />
          <MuiSelect
            v-else
            v-model="documentType"
            v-validate="{
              isRequired: true,
            }"
            :options="map(DOCUMENT_TYPES, (type) => ({ label: type, value: type }))"
            label="Document type*"
            placeholder="Select"
            class="w-100"
            name="documentType"
            data-test-id="document_type-select"
          />
        </div>
        <div>
          <label class="d-block emobg-font-weight-semibold mb-1"> Attachment* </label>
          <DragFileComponent
            v-model="inputs.document"
            v-validate.files="{
              isMaxSize,
              isRequired: true,
            }"
            :draggable-height="230"
            accepted-formats="image/*,application/pdf"
            show-preview
            data-test-id="file-input"
          >
            <template slot="extraInformation">
              <span class="d-block emobg-font-x-small mt-2">
                Maximum size {{ maxSizeInMB }} MB (JPG, PNG, PDF)
              </span>
            </template>
          </DragFileComponent>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />
        <ui-button
          :disabled="!isFormValid"
          :loading="addDocumentStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="uploadDocument"
        >
          Upload
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
