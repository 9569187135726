var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      _vm._b(
        {
          staticClass: "DrivingLicenseEdit v1-MuiModal--adaptative",
          attrs: {
            title: _vm.isPreview
              ? "Driving license preview"
              : "Edit driving license",
            header: { isClosable: true },
            buttons: _vm.modalButtons,
            "data-test-id": "driving_license_edit-form",
          },
          scopedSlots: _vm._u([
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "MuiValidationWrapper",
                    {
                      staticClass: "row",
                      on: {
                        areAllValid: (isValid) => (_vm.isFormValid = isValid),
                      },
                    },
                    [
                      !_vm.isPreview
                        ? _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Title, Full Name",
                                    "data-test-id": "user-cell",
                                  },
                                },
                                [
                                  _vm.get(_vm.user, "user.info.title.name")
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sentenceCase(
                                                _vm.user.info.title.name
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(_vm.userName) + " "),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiDatePicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                          isValidDate:
                                            _vm.DATE_INPUT_VALIDATIONS
                                              .validDate,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      "date-format-key":
                                        _vm.DATE_FORMAT_KEYS.dob,
                                      "skip-time-step": "",
                                      label: "Date of birth*",
                                      "data-test-id": "birthdate-select",
                                    },
                                    model: {
                                      value: _vm.inputs.birthdate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "birthdate", $$v)
                                      },
                                      expression: "inputs.birthdate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiAlgoliaSelect", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      index: _vm.ALGOLIA_INDEXES.countries,
                                      title: (country) => country.name,
                                      "path-value": "uuid",
                                      label: "Country of issue*",
                                      "data-test-id": "country-select",
                                      name: "issuingCountryUuid",
                                    },
                                    model: {
                                      value: _vm.inputs.issuingCountryUuid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs,
                                          "issuingCountryUuid",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.issuingCountryUuid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiDatePicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                          isValidDate:
                                            _vm.DATE_INPUT_VALIDATIONS
                                              .validDate,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      "date-format-key":
                                        _vm.DATE_FORMAT_KEYS.dob,
                                      "skip-time-step": "",
                                      label: "Date of issue*",
                                      "data-test-id":
                                        "physsical_issue_date-select",
                                    },
                                    model: {
                                      value: _vm.inputs.physicalIssueDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs,
                                          "physicalIssueDate",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.physicalIssueDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiDatePicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                          isValidDate:
                                            _vm.DATE_INPUT_VALIDATIONS
                                              .validDate,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      "date-format-key":
                                        _vm.DATE_FORMAT_KEYS.dob,
                                      "skip-time-step": "",
                                      label: "Expiry date*",
                                      "data-test-id": "expiration-select",
                                    },
                                    model: {
                                      value: _vm.inputs.expiration,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "expiration", $$v)
                                      },
                                      expression: "inputs.expiration",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiInputText", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      label: "License number*",
                                      "data-test-id": "license_number-input",
                                      name: "licenseNumber",
                                    },
                                    model: {
                                      value: _vm.inputs.licenseNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs,
                                          "licenseNumber",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.licenseNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiDatePicker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                          isValidDate:
                                            _vm.DATE_INPUT_VALIDATIONS
                                              .validDate,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      "date-format-key":
                                        _vm.DATE_FORMAT_KEYS.dob,
                                      "skip-time-step": "",
                                      label: "First issuing date of category*",
                                      "data-test-id": "first_issue_date-select",
                                    },
                                    model: {
                                      value: _vm.inputs.firstIssueDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs,
                                          "firstIssueDate",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.firstIssueDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c("MuiInputText", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n              isRequired: true,\n            }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      label: "Driving categories*",
                                      "data-test-id": "categories-input",
                                      name: "drivingCategories",
                                    },
                                    model: {
                                      value: _vm.inputs.drivingCategories,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs,
                                          "drivingCategories",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.drivingCategories",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isUK(
                                _vm.get(
                                  _vm.drivingLicense,
                                  "issuingCountryName",
                                  ""
                                )
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c("MuiInputText", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n              isRequired: true,\n            }",
                                          },
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          label: "DVLA check code*",
                                          "data-test-id": "dlva-input",
                                          name: "dlvaCode",
                                        },
                                        model: {
                                          value: _vm.inputs.dlvaCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "dlvaCode",
                                              $$v
                                            )
                                          },
                                          expression: "inputs.dlvaCode",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("GalleryComponent", {
                            staticClass: "h-100",
                            attrs: {
                              images: _vm.drivingLicenseImages,
                              "start-index": _vm.startIndex,
                              "data-test-id": "gallery-component",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "GenericModalComponent",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.crm.users,
              scope: _vm.USER_SCOPES.drivingLicenseEdit,
              action: "Edit driving license",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }