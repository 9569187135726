import toLower from 'lodash/toLower';

export const DL_STATUSES = {
  waitingForValidation: 'waiting_for_validation',
  validated: 'validated',
  rejected: 'rejected',
  expired: 'expired',
  incomplete: 'incomplete',
};

export const isUK = countryName => toLower(countryName) === 'united kingdom';
