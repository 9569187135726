import camelCase from 'lodash/camelCase';
import { DATE_FORMAT, FALLBACK_MESSAGE, reformatDateTime } from '@emobg/web-utils';
import { DocumentThumbnail } from '@/components';

export const DOCUMENT_TYPES = {
  users: 'User document',
  drivingLicenses: 'DL document',
};

export const DOCUMENTS_SCHEMA = openPreviewModal => [
  {
    header: 'Upload date',
    template: uploadedDocument => uploadedDocument.uploadDate
      ? reformatDateTime(uploadedDocument.uploadDate, DATE_FORMAT.dob)
      : FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Attachment',
    component: DocumentThumbnail,
    props: uploadedDocument => ({
      image: uploadedDocument.imageUrl,
      onClickImage: () => {
        openPreviewModal(uploadedDocument);
      },
    }),
  },
  {
    header: 'Document name',
    template: uploadedDocument => uploadedDocument.name || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Description',
    template: uploadedDocument => uploadedDocument.description || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Document type',
    template: uploadedDocument => uploadedDocument.type ? DOCUMENT_TYPES[camelCase(uploadedDocument.type)] : DOCUMENT_TYPES.drivingLicenses,
  },
];
