import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';

export const BADGE_DELIVERY_STATUS = {
  pending: 'pending',
  delivered: 'delivered',
  unassigned: 'unassigned',
  inactive: 'inactive',
};

export const BADGE_STATUS = {
  broken: 'broken',
  lost: 'lost',
  inactive: 'inactive',
  active: 'active',
};

export const BADGE_SCHEMA = [
  {
    header: 'Status',
    component: BadgeComponent,
    props: userBadge => ({
      text: sentenceCase(userBadge.badgeStatus),
      color: BADGE_STATUS.active === userBadge.badgeStatus ? COLORS.success : GRAYSCALE.ground,
    }),
  },
  {
    header: 'Delivery status',
    component: BadgeComponent,
    props: userBadge => ({
      text: sentenceCase(userBadge.badgeDeliveryStatus),
      color: BADGE_DELIVERY_STATUS.delivered === userBadge.badgeDeliveryStatus ? COLORS.success : GRAYSCALE.ground,
    }),
  },
  {
    header: 'Delivery method',
    template: badge => sentenceCase(badge.badgeDeliveryMethod) || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Badge',
    template: badge => sentenceCase(badge.badgeProviderName) || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Number',
    template: badge => sentenceCase(badge.badgeNumber) || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Pin',
    template: badge => sentenceCase(badge.badgeDeliveryPin) || FALLBACK_MESSAGE.dash,
  },
];
