<script>
import map from 'lodash/map';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  GalleryComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'DocumentPreviewModal',
  components: {
    GalleryComponent,
    GenericModalComponent,
  },
  props: {
    startIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      documents: state => state.documents.data,
    }),
    mappedDocuments() {
      return map(this.documents, ({ name, imageUrl }) => ({ label: name, src: imageUrl }));
    },
  },
};
</script>

<template>
  <GenericModalComponent
    title="Document preview"
    :header="{ isClosable: true }"
    class="DocumentsPreview v1-MuiModal--adaptative"
    data-test-id="documents-modal"
    v-on="$listeners"
  >
    <template #body>
      <div class="col">
        <GalleryComponent
          :images="mappedDocuments"
          :start-index="startIndex"
          data-test-id="gallery-component"
          class="h-100"
        />
      </div>
    </template>
  </GenericModalComponent>
</template>
