<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageTabs, PageView } from '@/components';
import { USER_SUBSCRIPTION_TABS } from './subscriptions.const';

export default {
  name: 'UserSubscriptions',
  components: {
    PageTabs,
    PageView,
  },
  data() {
    return {
      areTabsReady: false,
      tabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
  },
  mounted() {
    this.tabs = USER_SUBSCRIPTION_TABS;
    this.updateTabsVisibility();
  },
  methods: {
    updateTabsVisibility() {
      this.areTabsReady = false;
      this.tabs = filter(this.tabs, item => intersection(this.userPermissions, item.permissions).length);
      this.areTabsReady = true;
    },
  },
};
</script>

<template>
  <div
    class="UserSubscriptionsView"
    data-test-id="user_invoicing-view"
  >
    <PageTabs
      v-if="areTabsReady"
      :tabs="tabs"
      data-test-id="tabs"
      are-inner-tabs
    />
    <PageView is-inner-tab-content>
      <RouterView />
    </PageView>
  </div>
</template>
