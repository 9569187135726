<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import { sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { ContentCellComponent, EditButton } from '@/components';

import { UserStatusBadge } from '../../../components/UserDetailsHeader/components';
import { PROFILES_TYPES } from '../../../../constants/userStatus';
import EditGeneralSettings from './EditGeneralSettings';
import EditPersonalProfile from './EditPersonalProfile';
import EditBusinessProfile from './EditBusinessProfile';

export default {
  name: 'AccountSettings',
  components: {
    UserStatusBadge,
    EditButton,
    ContentCellComponent,
    EditGeneralSettings,
    EditPersonalProfile,
    EditBusinessProfile,
  },
  data() {
    return {
      isLoading: false,
      isGeneralSettingsOpen: false,
      isEditPersonalProfileOpen: false,
      isEditBusinessProfileOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => get(state, 'detail.data'),
    }),
    premiumUserText() {
      const status = {
        true: 'Yes',
        false: 'No',
        null: 'Automatic',
      };

      return status[get(this, 'user.info.isPremium')];
    },
  },
  created() {
    this.PROFILES_TYPES = PROFILES_TYPES;
  },
  methods: {
    sentenceCase,
    companyProfileUrl,
    ...mapActions(DOMAINS_MODEL.crm.users, ['getUser']),
    get,
    closeModal() {
      this.isGeneralSettingsOpen = false;
      this.isEditPersonalProfileOpen = false;
      this.isEditBusinessProfileOpen = false;
    },
    async onFormSuccess() {
      this.isLoading = true;
      await this.getUser(this.user.uuid);
      this.isLoading = false;
    },
  },
};
</script>
<template>
  <div class="AccountSettings">
    <ui-card
      header="Account settings"
      class="w-100 position-relative"
      data-test-id="account_settings"
    >
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-center">
        <div class="w-100">
          <h3 class="mb-3">
            General settings
          </h3>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Account email"
                class="mb-3"
              >
                <p class="text-break">
                  {{ user.info.email }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Operator"
                class="mb-3"
              >
                {{ user.info.operatorName }}
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Language"
                class="mb-3"
              >
                <span v-if="get(user, 'info.language.code')">
                  {{ user.info.language.code }} {{ user.info.language.name }}
                </span>
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isGeneralSettingsOpen = true" />
      </div>
      <div
        v-if="get(user, 'profiles.personal')"
        class="d-flex justify-content-between align-items-center emobg-border-top-1 emobg-border-color-ground-light pt-3"
      >
        <div class="w-100">
          <h3 class="mb-3">
            Personal profile
          </h3>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Notification channels"
                class="mb-3"
              >
                <p>
                  {{ user.profiles.personal.email }}
                </p>
                <p>
                  {{ user.profiles.personal.phoneNumber }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Personal status"
                class="mb-3"
              >
                <UserStatusBadge
                  :status="user.status.personal"
                  data-test-id="personal_status-badge"
                />
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Premium user"
                class="mb-3"
              >
                <ui-badge
                  :color="GRAYSCALE.ground"
                  data-test-id="is_premium-badge"
                >
                  {{ premiumUserText }}
                </ui-badge>
              </ContentCellComponent>
            </div>

            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Payment mode"
                class="mb-3"
              >
                <ui-badge
                  v-if="get(user, 'profiles.personal.paymentMode')"
                  :color="GRAYSCALE.ground"
                  data-test-id="payment_mode-badge"
                >
                  {{ sentenceCase(user.profiles.personal.paymentMode) }}
                </ui-badge>
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isEditPersonalProfileOpen = true" />
      </div>
      <div
        v-if="get(user, 'profiles.business')"
        class="d-flex justify-content-between align-items-center emobg-border-top-1 emobg-border-color-ground-light pt-3"
      >
        <div class="w-100">
          <h3 class="mb-3">
            Business profile
          </h3>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Notification channels"
                class="mb-3"
              >
                <p class="text-break">
                  {{ user.profiles.business.email }}
                </p>
                <p>
                  {{ user.profiles.business.phoneNumber }}
                </p>
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Business status"
                class="mb-3"
              >
                <UserStatusBadge
                  :status="user.status.business"
                  data-test-id="business_status-badge"
                />
              </ContentCellComponent>
            </div>
            <div class="col-md-6 col-lg-3">
              <ContentCellComponent
                label="Employee user role"
                class="mb-3"
              >
                <p
                  v-for="role in get(user, 'profiles.business.frontofficeRoles', [])"
                  :key="role"
                >
                  {{ sentenceCase(role) }}
                </p>
              </ContentCellComponent>
            </div>

            <div
              v-if="get(user, 'profiles.business.company.id')"
              class="col-md-6 col-lg-3"
            >
              <ContentCellComponent
                label="Company name"
                class="mb-3"
              >
                <a
                  v-if="get(user, 'profiles.business.company.uuid')"
                  :href="companyProfileUrl(user.profiles.business.company.uuid)"
                  target="_blank"
                  class="emobg-link-primary emobg-body-2"
                >
                  {{ user.profiles.business.company.name }}
                </a>
              </ContentCellComponent>
            </div>
          </div>
        </div>

        <EditButton @click="isEditBusinessProfileOpen = true" />
      </div>
    </ui-card>
    <EditGeneralSettings
      v-if="isGeneralSettingsOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
    <EditPersonalProfile
      v-if="isEditPersonalProfileOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
    <EditBusinessProfile
      v-if="isEditBusinessProfileOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
