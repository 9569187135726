<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import moment from 'moment';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { USE_CASE } from '@domains/Pricing/Tariffs/tariff.const';
import {
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { Cancel } from '@/constants/modalButtons.const';
import { DATE_INPUT_VALIDATIONS } from '@/utils';
import { USER_SCOPES } from '../../store/UsersModule';
import { PROFILES_TYPES } from '../../constants/userStatus';

export default {
  name: 'UserTariffForm',
  directives: {
    Validate,
  },
  components: {
    ContentCellComponent,
    GenericModalComponent,
    MuiDatePicker,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    userTariff: {
      type: Object,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isRecommendedTariffFilter: false,
      tariffSelected: null,
      inputs: {
        tariffUuid: null,
        period: null,
      },
      startInput: null,
      endInput: null,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userTariffs: state => state.userTariffs.data.data,
      userTariffFormStatus: state => state.userTariffForm.STATUS,
      availableUserTariffs: state => get(state[USER_SCOPES.availableUserTariffs].data, 'data') || [],
      availableUserTariffsStatus: state => state[USER_SCOPES.availableUserTariffs].STATUS,
      availableTariffPeriods: state => get(state[USER_SCOPES.availableUserTariffPeriods].data, 'data') || [],
      availableTariffPeriodsStatus: state => state[USER_SCOPES.availableUserTariffPeriods].STATUS,
    }),
    modalButtons() {
      const isActionButtonDisabled = (!this.isFormValid || this.availableTariffPeriodsStatus.LOADING)
        || (this.userTariff && this.areValuesSameAsOriginal);
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          text: this.userTariff ? 'Save' : 'Add',
          attributes: {
            disabled: isActionButtonDisabled,
            loading: this.userTariffFormStatus.LOADING,
          },
          listeners: {
            click: this.request,
          },
        },
      ];
    },
    hasTariffQuotas() {
      return !!this.availableTariffPeriods.length;
    },
    currentTariff() {
      return get(this, 'userTariffs.personal.current[0]');
    },
    minStartDate() {
      let minDate = moment();

      if (this.currentTariff) {
        const currentEnd = get(this.currentTariff, 'end');

        if (currentEnd) {
          minDate = moment(currentEnd).add(1, 'day');
        } else {
          const currentStart = moment(this.currentTariff.start).add(1, 'day');
          minDate = currentStart.isSameOrAfter(minDate) ? currentStart : minDate;
        }
      }

      return minDate;
    },
    areValuesSameAsOriginal() {
      return this.inputs.endDate === get(this.userTariff, 'end')
        && this.inputs.period === get(this.userTariff, 'period');
    },
    dataForRequest() {
      return this.userTariff
        ? {
          userTariffUuid: this.userTariff.userTariffUuid,
          data: {
            period: this.inputs.period,
            endDate: this.endInput && this.endInput.isValid() ? this.endInput.format(DATE_FORMAT.date) : null,
          },
        }
        : {
          userUuid: this.user.uuid,
          data: {
            ...this.inputs,
            startDate: this.startInput && this.startInput.isValid() ? this.startInput.format(DATE_FORMAT.date) : null,
            endDate: this.endInput && this.endInput.isValid() ? this.endInput.format(DATE_FORMAT.date) : null,
          },
        };
    },
  },
  watch: {
    async isRecommendedTariffFilter() {
      this.inputs.tariffUuid = null;
      this.tariffSelected = null;

      this.setData({
        scope: USER_SCOPES.availableUserTariffPeriods,
        value: [],
      });

      await this.getAvailableTariffsForUser();
    },
    async tariffSelected(value) {
      this.inputs.period = null;

      if (value) {
        await this.getTariffPeriods(value);
      }
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;

    if (this.userTariff) {
      this.startInput = moment(this.userTariff.start);
      this.endInput = moment(this.userTariff.end);
      await this.getTariffPeriods({
        uuid: this.userTariff.tariffUuid,
        isMigrated: this.userTariff.isMigrated,
      });
      this.inputs.period = this.userTariff.period;
    } else {
      this.endInput = moment(this.minStartDate).year(2050).endOf('year');
      this.setStart(moment(this.minStartDate));
      await this.getAvailableTariffsForUser();
    }
  },
  beforeDestroy() {
    this.resetState({
      scopes: [
        USER_SCOPES.availableUserTariffs,
        USER_SCOPES.availableUserTariffPeriods,
      ],
    });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getAvailableUserTariffs',
      'getAvailableTariffPeriods',
      'postUserTariff',
      'postMigratedUserTariff',
      'putUserTariff',
    ]),
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
      'setData',
    ]),
    map,
    find,
    reformatDateTime,
    sentenceCase,
    setStart(date) {
      this.startInput = date;

      if (this.startInput && this.startInput.isAfter(this.endInput)) {
        this.endInput = moment(this.startInput);
      }
    },
    setEnd(date) {
      const minEnd = this.startInput && this.startInput.isBefore(moment()) ? moment() : this.startInput;
      if (date && date.isBefore(minEnd)) {
        this.endInput = moment(minEnd);
      } else {
        this.endInput = date;
      }
    },
    isBeforeCurrentTariff(date) {
      const message = this.currentTariff
        ? `New tariff must start after current one: ${this.currentTariff.end || this.minStartDate.format(DATE_FORMAT.dob)}`
        : 'New tariff must start today minimum';
      return {
        isValid: date.isSameOrAfter(this.minStartDate),
        message,
      };
    },
    async request() {
      const postRequest = get(this.tariffSelected, 'isMigrated') ? this.postMigratedUserTariff : this.postUserTariff;
      const targetAction = this.userTariff ? this.putUserTariff : postRequest;
      await targetAction(this.dataForRequest);

      if (!this.userTariffFormStatus.ERROR) {
        const action = this.userTariff ? 'edited' : 'added';
        this.$emit('closeModal');
        this.$notify({
          message: `User tariff successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });
        this.onSuccess();
      }
    },
    async getAvailableTariffsForUser() {
      await this.getAvailableUserTariffs({
        userUuid: this.user.uuid,
        params: {
          fleetType: USE_CASE.openPersonal,
          recommended: this.isRecommendedTariffFilter,
        },
      });
    },
    async getTariffPeriods(tariff) {
      await this.getAvailableTariffPeriods({
        tariffUuid: tariff.uuid,
        params: {
          isMigrated: tariff.isMigrated,
          profile: PROFILES_TYPES.personal,
        },
      });
    },
    getSelectTariffOptions(tariffs) {
      return map(
        tariffs,
        ({
          uuid, id, name, internalName,
        }) => {
          const textId = id ? `${id} ` : '';
          const textName = name || internalName;
          return {
            value: uuid,
            label: `${textId}${textName}`,
          };
        },
      );
    },
    getSelectPeriodOptions(periods) {
      return map(
        periods,
        ({ fee, period }) => ({
          value: period,
          label: `${sentenceCase(period)} - ${fee}`,
        }),
      );
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :title="`${userTariff ? 'Edit current' : 'Add'} user tariff`"
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    class="UserTariffForm"
    data-test-id="user_tariff_form-modal"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.userTariffForm"
        :action="`${userTariff ? 'Edit' : 'Add'} user tariff`"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div v-if="userTariff">
          <ContentCellComponent
            :value="userTariff.tariffName"
            label="Tariff name"
            class="mb-4"
          />
          <ContentCellComponent
            :value="reformatDateTime(userTariff.start, DATE_FORMAT.dob)"
            label="Start date"
            class="mb-4"
          />
        </div>

        <template v-else>
          <div class="d-inline-flex flex-column mb-4">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Filter
            </label>
            <ui-radio
              :value="isRecommendedTariffFilter"
              :option="true"
              caption="Recommended tariffs"
              name="tariffs_filter"
              class="d-inline-block mb-2"
              data-test-id="recommended_tariffs-radio"
              @changevalue="() => isRecommendedTariffFilter = true"
            />
            <ui-radio
              :value="isRecommendedTariffFilter"
              :option="false"
              caption="All tariffs"
              name="filter_tariff"
              data-test-id="all_tariffs-radio"
              @changevalue="() => isRecommendedTariffFilter = false"
            />
          </div>
          <div class="mb-4">
            <ui-skeleton
              v-if="availableUserTariffsStatus.LOADING"
              data-test-id="select-skeleton"
            />
            <MuiSelect
              v-else
              v-model="inputs.tariffUuid"
              v-validate="{
                isRequired: true,
              }"
              :options="getSelectTariffOptions(availableUserTariffs)"
              path-value="uuid"
              label="Tariff name*"
              placeholder="Select a tariff"
              name="tariffUuid"
              class="w-100"
              @change="tariffUuid => tariffSelected = find(availableUserTariffs, { uuid: tariffUuid })"
            />
          </div>
        </template>
        <div
          v-if="hasTariffQuotas"
          class="mb-4"
        >
          <MuiSelect
            v-model="inputs.period"
            v-validate="{
              isRequired: true,
            }"
            :options="getSelectPeriodOptions(availableTariffPeriods)"
            label="Periodicity*"
            name="periodicity"
            class="w-100 text-capitalize"
            placeholder="Select"
            data-test-id="periodicity-select"
          />
        </div>
        <div class="mb-4">
          <MuiDatePicker
            v-if="!userTariff"
            v-validate="{
              isRequired: true,
              isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              isBeforeCurrentTariff,
            }"
            :date="startInput"
            :date-format-key="DATE_FORMAT_KEYS.dob"
            :skip-time-step="true"
            label="Start date*"
            class="w-100"
            placeholder="Start date"
            data-test-id="start-input"
            @update:date="setStart"
          />
        </div>
        <div class="mb-4">
          <MuiDatePicker
            v-validate="{
              isRequired: true,
              isValidDate: DATE_INPUT_VALIDATIONS.validDate,
            }"
            :date="endInput"
            :date-format-key="DATE_FORMAT_KEYS.dob"
            :skip-time-step="true"
            label="End date*"
            class="w-100"
            placeholder="End date"
            data-test-id="end-input"
            @update:date="setEnd"
          />
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
