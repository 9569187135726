<script>
import get from 'lodash/get';
import map from 'lodash/map';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiSelect, MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { USER_SCOPES } from '../../../../store/UsersModule';
import { BADGE_DELIVERY_STATUS } from './const/badges.const';

export default {
  name: 'EditUserBadge',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    ContentCellComponent,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        status: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      userBadge: state => state.userBadge.data,
      newBadgeStatus: state => state.newBadge.STATUS,
    }),
    hasSameValues() {
      return get(this, 'userBadge.badgeDeliveryStatus') === this.inputs.status;
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.BADGE_DELIVERY_STATUS = BADGE_DELIVERY_STATUS;
    this.inputs.status = get(this, 'userBadge.badgeDeliveryStatus');
    this.resetState({ scopes: [USER_SCOPES.newBadge] });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['putUserBadge']),
    get,
    map,
    sentenceCase,
    async updateBadge() {
      await this.putUserBadge({
        userUuid: this.user.uuid,
        badgeUuid: get(this, 'userBadge.badgeDeliveryUuid'),
        data: this.inputs,
      });

      if (!this.newBadgeStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Badge successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    title="Edit badge"
    class="EditBadge"
    data-test-id="edit_badge-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.newBadge"
        is-editing
        element="user badge"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row mb-4">
          <ContentCellComponent
            label="Badge"
            class="col-6"
          >
            {{ get(userBadge, 'badgeProviderName', FALLBACK_MESSAGE.dash) }}
          </ContentCellComponent>

          <ContentCellComponent
            label="Badge"
            class="col-6"
          >
            {{ get(userBadge, 'badgeNumber', FALLBACK_MESSAGE.dash) }}
          </ContentCellComponent>
        </div>

        <MuiSelect
          v-model="inputs.status"
          v-validate="{
            isRequired: true,
          }"
          :options="map(BADGE_DELIVERY_STATUS, status => ({ label: sentenceCase(status), value: status }))"
          class="w-100"
          label="Status"
          placeholder="Select"
          name="status"
          data-test-id="status-select"
        />
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="!isFormValid || hasSameValues"
          :loading="newBadgeStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="updateBadge"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
