<script>
import size from 'lodash/size';

export default {
  name: 'DrivingLicenseComments',
  props: {
    drivingLicenseComments: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    size,
  },
};
</script>
<template>
  <div class="p-3 emobg-border-color-ground-light emobg-border-1 emobg-border-radius-small mb-3">
    <p class="emobg-font-weight-semibold mb-2">
      System feedback
    </p>
    <ui-loader v-if="isLoading" />
    <div v-else>
      <template v-if="size(drivingLicenseComments)">
        <p
          v-for="comment in drivingLicenseComments"
          :key="comment.uuid"
          class="d-flex align-items-center"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            :size="ICONS_SIZES.small"
            :color="COLORS.primary"
            class="mr-1 pt-1"
          />
          <span>{{ comment.body }}</span>
        </p>
      </template>
      <p
        v-else
        class="emobg-color-ink-light"
      >
        No system feedback provided
      </p>
    </div>
  </div>
</template>
