var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiCard",
    {
      staticClass: "DrivingLicense w-100 position-relative",
      attrs: {
        title: "Driving license",
        "data-test-id": "driving_license-card",
      },
    },
    [
      _vm.drivingLicenseStatus.LOADING
        ? _c("ui-loader", { attrs: { absolute: "", "data-test-id": "loader" } })
        : _vm._e(),
      _vm.get(_vm.user, "status.personal.name") === _vm.USER_STATUS.anonymized
        ? _c("div", { staticClass: "emobg-color-ink-light mb-3" }, [
            _vm._v(" User data anonymised "),
          ])
        : [
            _vm.hasAlertableStatus
              ? _c(
                  "ui-alert",
                  {
                    staticClass: "pb-3 d-flex w-100",
                    attrs: {
                      color: _vm.alertColor,
                      icon: _vm.ICONS.alertFull,
                      "data-test-id": "warning_message-alert",
                    },
                  },
                  [_c("p", { domProps: { innerHTML: _vm._s(_vm.alertText) } })]
                )
              : _vm._e(),
            _c("DrivingLicenseComments", {
              attrs: {
                "driving-license-comments": _vm.drivingLicenseComments || [],
                "is-loading": _vm.drivingLicenseCommentsStatus.LOADING,
              },
            }),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between align-items-top" },
              [
                _c("div", { staticClass: "w-100 row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-3",
                          attrs: { label: "Title, Full Name" },
                        },
                        [
                          _vm.get(_vm.user, "user.info.title.name")
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.sentenceCase(_vm.user.info.title.name)
                                    ) +
                                    ", "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.userName) + " "),
                        ]
                      ),
                      _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.getFormattedDate("birthdate"),
                          label: "Date of birth",
                        },
                      }),
                      _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.get(
                            _vm.drivingLicense,
                            "issuingCountryName",
                            _vm.FALLBACK_MESSAGE.dash
                          ),
                          label: "Country of issue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.getFormattedDate("physicalIssueDate"),
                          label: "Date of issue",
                        },
                      }),
                      _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.getFormattedDate("expiration"),
                          label: "Expiry date",
                        },
                      }),
                      _c(
                        "ContentCellComponent",
                        {
                          staticClass: "mb-3",
                          attrs: { label: "License number" },
                        },
                        [
                          _vm.get(_vm.drivingLicense, "licenseNumber")
                            ? _c(
                                "CopyToClipboard",
                                {
                                  attrs: {
                                    "data-test-id": "copy_license-button",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.drivingLicense,
                                          "licenseNumber"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "
                                ),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-3" },
                    [
                      _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.getFormattedDate("firstIssueDate"),
                          label: "First issuing date of category",
                        },
                      }),
                      _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: {
                          value: _vm.get(
                            _vm.drivingLicense,
                            "drivingCategories",
                            _vm.FALLBACK_MESSAGE.dash
                          ),
                          label: "Driving categories",
                        },
                      }),
                      _vm.isUK(
                        _vm.get(_vm.drivingLicense, "issuingCountryName", "")
                      )
                        ? _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-3",
                              attrs: { label: "DVLA check code" },
                            },
                            [
                              _vm.get(_vm.drivingLicense, "dlvaCode")
                                ? _c(
                                    "CopyToClipboard",
                                    {
                                      attrs: {
                                        "data-test-id": "copy_dlva-button",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.drivingLicense,
                                              "dlvaCode"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                        " "
                                    ),
                                  ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.drivingLicense
                    ? _c("div", { staticClass: "row col-12" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-3" },
                          [
                            _c(
                              "ContentCellComponent",
                              {
                                staticClass: "mb-3",
                                attrs: { label: "Driving license (Front)" },
                              },
                              [
                                _vm.drivingLicense.frontDocumentImg
                                  ? _c("DocumentPreview", {
                                      attrs: {
                                        image:
                                          _vm.drivingLicense.frontDocumentImg,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditModal(0, true)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-3" },
                          [
                            _c(
                              "ContentCellComponent",
                              {
                                staticClass: "mb-3",
                                attrs: { label: "Driving license (Back)" },
                              },
                              [
                                _vm.drivingLicense.backDocumentImg
                                  ? _c("DocumentPreview", {
                                      attrs: {
                                        image:
                                          _vm.drivingLicense.backDocumentImg,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditModal(1, true)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-3" },
                          [
                            _c(
                              "ContentCellComponent",
                              {
                                staticClass: "mb-3",
                                attrs: { label: "Selfie" },
                              },
                              [
                                _vm.drivingLicense.selfieImg
                                  ? _c("DocumentPreview", {
                                      attrs: {
                                        image: _vm.drivingLicense.selfieImg,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openEditModal(2, true)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("EditButton", {
                  staticClass: "ml-2",
                  attrs: { disabled: !_vm.isEditable },
                  on: { click: _vm.openEditModal },
                }),
              ],
              1
            ),
            !_vm.drivingLicense || !_vm.isDrivingLicenseActive
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-12 d-flex justify-content-center p-3 emobg-background-color-ground-lighter emobg-border-color-ground emobg-border-1 emobg-border-radius-small my-3",
                  },
                  [
                    _c(
                      "ui-button",
                      {
                        attrs: { face: _vm.FACES.outline },
                        on: {
                          clickbutton: function ($event) {
                            _vm.isUploadModalOpen = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.drivingLicenseAction) +
                            " driving license "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _vm.get(_vm.user, "status.personal.name") !==
      _vm.USER_STATUS.blockedByScreening
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-content-end pt-3 emobg-border-color-ground-light emobg-border-top-1",
            },
            [
              _c(
                "ContentCellComponent",
                {
                  class: [
                    "text-right",
                    {
                      "emobg-color-ink-lighter":
                        _vm.disableInvalidate && _vm.disableValidate,
                    },
                  ],
                  attrs: { label: "Validation actions" },
                },
                [
                  _vm.get(_vm.drivingLicense, "status") !==
                  _vm.DL_STATUSES.validated
                    ? _c(
                        "ui-button",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            color: _vm.COLORS.success,
                            disabled: _vm.disableValidate,
                            loading: _vm.drivingLicenseValidateStatus.LOADING,
                          },
                          on: {
                            clickbutton: function ($event) {
                              return _vm.onDrivingLicenseValidation(
                                _vm.USER_SCOPES.drivingLicenseValidate
                              )
                            },
                          },
                        },
                        [_vm._v(" Validate ")]
                      )
                    : _vm._e(),
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        color: _vm.COLORS.danger,
                        disabled: _vm.disableInvalidate,
                        loading: _vm.drivingLicenseInvalidateStatus.LOADING,
                      },
                      on: {
                        clickbutton: function ($event) {
                          return _vm.onDrivingLicenseValidation(
                            _vm.USER_SCOPES.drivingLicenseInvalidate
                          )
                        },
                      },
                    },
                    [_vm._v(" Invalidate ")]
                  ),
                ],
                1
              ),
              _vm.isDrivingLicenseActive === false
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end mt-2 emobg-color-danger emobg-font-weight-semibold",
                    },
                    [_vm._v(" DL was invalidated ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isUploadModalOpen
        ? _c("DrivingLicenseUpload", {
            attrs: { callback: () => _vm.onFormSuccess(true) },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isModalOpen
        ? _c("DrivingLicenseEdit", {
            attrs: {
              "is-preview": _vm.isPreviewMode,
              "backdrop-dismiss": _vm.isPreviewMode,
              "start-index": _vm.startIndex,
              callback: _vm.onFormSuccess,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }