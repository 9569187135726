<script>
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import { getImageName } from '@emobg/web-utils';
import { TableComponent } from '@/components';
import { DOCUMENTS_SCHEMA } from './const/documents.const';
import { DeleteDocumentModal, DocumentPreviewModal, DocumentUploadForm } from './components';
import { DL_STATUSES } from '../DrivingLicense/drivingLicense.const';

export default {
  name: 'DocumentsCard',
  components: {
    DocumentUploadForm,
    DocumentPreviewModal,
    DeleteDocumentModal,
    TableComponent,
  },
  data() {
    return {
      isAddDocumentModalOpen: false,
      isDeleteModalOpen: false,
      isPreviewModalOpen: false,
      documentToDelete: null,
      startIndex: 0,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      documents: state => state.documents.data || [],
      drivingLicense: state => state.drivingLicense.data,
      documentsStatus: state => state.documents.STATUS,
    }),
    documentsSchema() {
      return DOCUMENTS_SCHEMA(this.openPreviewModal);
    },
  },
  async created() {
    this.USER_SCOPES = USER_SCOPES;
    this.DL_STATUSES = DL_STATUSES;
    this.documentRowActions = [
      {
        label: 'Preview document',
        labelClass: 'emobg-font-weight-semibold',
        action: this.openPreviewModal,
      },
      {
        label: 'Download document',
        labelClass: 'emobg-font-weight-semibold',
        action: this.downloadOrOpenDocument,
      },
      {
        label: 'Delete document',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: document => {
          this.documentToDelete = document;
          this.isDeleteModalOpen = true;
        },
      },
    ];
    await this.getUserDocuments(this.user.uuid);
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getUserDocuments',
    ]),
    openPreviewModal(document) {
      this.startIndex = findIndex(this.documents, ({ imageUuid }) => imageUuid === document.imageUuid);
      this.isPreviewModalOpen = true;
    },
    async downloadOrOpenDocument(userDocument) {
      const link = document.createElement('a');
      link.href = userDocument.imageUrl;
      link.target = '_blank';
      link.download = getImageName(userDocument.imageUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal() {
      this.isAddDocumentModalOpen = false;
      this.isPreviewModalOpen = false;
      this.isDeleteModalOpen = false;
      this.documentToDelete = null;
      this.startIndex = 0;
    },
    async onFormSuccess() {
      await this.getUserDocuments(this.user.uuid);
    },
  },
};
</script>
<template>
  <div class="Documents">
    <ui-card
      class="w-100"
      data-test-id="documents-card"
    >
      <div class="d-flex flex-fill justify-content-between pb-3 emobg-border-bottom-2 emobg-border-color-ground-light align-items-center">
        <h2
          class="emobg-color-ink"
          data-test-id="title"
        >
          Documents
        </h2>

        <ui-button
          :face="FACES.outline"
          @clickbutton="isAddDocumentModalOpen = true"
        >
          Upload document
        </ui-button>
      </div>
      <div class="position-relative">
        <ui-loader
          v-if="documentsStatus.LOADING"
          absolute
          data-test-id="loader"
        />
        <TableComponent
          :schema="documentsSchema"
          :data="documents"
          :row-actions="documentRowActions"
          empty-label="No documents yet"
          class="mt-3"
        />
      </div>
    </ui-card>

    <DocumentPreviewModal
      v-if="isPreviewModalOpen"
      :start-index="startIndex"
      @closeModal="closeModal"
    />
    <DocumentUploadForm
      v-if="isAddDocumentModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
    <DeleteDocumentModal
      v-if="isDeleteModalOpen"
      :document="documentToDelete"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
