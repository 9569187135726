<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TIME_ZONE } from '@emobg/web-utils';
import { mapState } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  attributesToRetrieve,
  caseEventsFacets,
  contentCells,
} from '../../../../CasesServices/const/eventsListContentCells';
import CasesRoutes from '../../../../CasesServices/router/CasesServicesRouterMap';

export default {
  name: 'UserCases',
  components: {
    MuiAlgoliaList,
  },
  props: {
    userUuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    contentCells() {
      return contentCells(this.operatorTimezone, false);
    },
    caseEventsFacets() {
      return caseEventsFacets(this.operatorTimezone);
    },
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.caseEvents;
    this.attributesToRetrieve = attributesToRetrieve;
    this.caseEventsFilters = `user_uuid:${this.userUuid}`;
  },
  methods: {
    goToCreateCase() {
      this.$router.push({
        name: CasesRoutes.caseEvents.create,
        query: {
          entity: 'user',
          entityUUID: this.userUuid,
        },
      });
    },
  },
};
</script>
<template>
  <div
    data-test-id="user_cases-view"
    class="UserCases"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Cases
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="goToCreateCase"
      >
        Create case
      </ui-button>
    </div>
    <MuiAlgoliaList
      :facets="caseEventsFacets"
      :filters="caseEventsFilters"
      :export-columns="contentCells"
      :table-config="contentCells"
      :query-parameters="{ attributesToRetrieve }"
      :index="algoliaIndex"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
