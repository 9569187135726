<script>
import { DATE_FORMAT, reformatDateTime } from '@emobg/web-utils';
import kebabCase from 'lodash/kebabCase';

import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import carsharing from '@domains/Carsharing/router/CarsharingRouterMap';
import { ContentCellComponent, PermissionLink } from '@/components';

export default {
  name: 'UserBookingLink',
  components: {
    ContentCellComponent,
    PermissionLink,
  },
  props: {
    booking: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
  },
  created() {
    this.carsharing = carsharing;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    kebabCase,
    reformatDateTime,
  },
};
</script>
<template>
  <ContentCellComponent
    :label="label"
    :data-test-id="kebabCase(label)"
    is-on-header
    class="mb-4"
  >
    <p v-if="!booking">
      {{ FALLBACK_MESSAGE.dash }}
    </p>
    <PermissionLink
      v-else
      :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingBookings]"
      :to="{
        name: carsharing.bookings.detail.index,
        params: {
          bookingUuid: booking.uuid,
        },
      }"
      class="emobg-font-weight-semibold"
      data-test-id="booking-link"
    >
      {{ reformatDateTime(booking.date, DATE_FORMAT.dob) }}
    </PermissionLink>
  </ContentCellComponent>
</template>
