<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { USER_STATUS } from '@domains/CRM/Users/constants/userStatus';
import { CRM_FLAGS } from '@domains/CRM/const/features';
import { ContentCellComponent } from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import ResetPasswordModal from './components/ResetPasswordModal';
import CancelAccountModal from './components/CancelAccountModal';
import { AUTHENTICATION_ERRORS } from './const/authentication.const';

export default {
  name: 'AuthenticationCard',
  components: {
    ContentCellComponent,
    ResetPasswordModal,
    CancelAccountModal,
  },
  data() {
    return {
      isResetPasswordModalOpen: false,
      isCancelAccountModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      drivingLicense: state => state.drivingLicense.data,
      documents: state => state.documents.data || [],
      resendVerificationMailStatus: state => state.resendVerificationMail.STATUS,
      resendVerificationMailErrorKey: state => get(state, 'resendVerificationMail.error.key'),
      user: state => state.detail.data,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      features: state => get(state, 'features.data'),
    }),
    isResendButtonHidden() {
      return this.features[CRM_FLAGS.hideResendVerificationMailButton] || false;
    },
    isCancelAccountDisabled() {
      const userStatus = get(this, 'user.status.personal.name');
      return [USER_STATUS.anonymized, USER_STATUS.waitingForAnonymization].includes(userStatus);
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.users, [
      'getDrivingLicense',
      'getUser',
      'getUserDocuments',
      'getResendVerificationEmail',
    ]),
    get,
    async resendVerificationMail() {
      await this.getResendVerificationEmail({ userUuid: this.user.uuid });
      let notification = {
        message: 'Something went wrong',
        type: NOTIFICATION_TYPES.error,
        textAction: 'Dismiss',
      };

      if (!this.resendVerificationMailStatus.ERROR) {
        notification = {
          message: 'Verification email successfully <span class="emobg-font-weight-semibold">resent</span>',
          textAction: '',
        };
      } else if (this.resendVerificationMailErrorKey === AUTHENTICATION_ERRORS.verificationMailAlreadyVerified) {
        notification = {
          message: 'User email is already verified',
          type: NOTIFICATION_TYPES.alert,
          textAction: 'Dismiss',
        };
      }

      this.$notify(notification);
    },
    closeModal() {
      this.isResetPasswordModalOpen = false;
      this.isCancelAccountModalOpen = false;
    },
    async onFormSuccess() {
      const requestAtSameTime = [
        this.getUser(this.user.uuid),
      ];

      if (!isEmpty(this.drivingLicense)) {
        requestAtSameTime.push(this.getDrivingLicense(this.user.uuid));
      }

      if (!isEmpty(this.documents)) {
        requestAtSameTime.push(this.getUserDocuments(this.user.uuid));
      }

      await Promise.all(requestAtSameTime);
    },
  },
};
</script>
<template>
  <div class="Authentication">
    <ui-card
      header="Authentication"
      class="w-100"
      data-test-id="authentication-card"
    >
      <div class="row d-flex align-items-center">
        <div class="col-md-6 col-lg-3">
          <ContentCellComponent
            :value="user && user.idpUuid"
            label="Auth0 ID"
            class="mb-3"
          />
        </div>
        <div class="col-md-6 col-lg-3">
          <ui-button
            :face="FACES.outline"
            @clickbutton="isResetPasswordModalOpen = true"
          >
            Regenerate login
          </ui-button>
        </div>
        <div
          v-if="!isResendButtonHidden"
          class="col-md-6 col-lg-3"
        >
          <ui-button
            :face="FACES.outline"
            :loading="resendVerificationMailStatus.LOADING"
            @clickbutton="resendVerificationMail"
          >
            Resend verification email
          </ui-button>
        </div>
        <div class="col-md-6 col-lg-3">
          <ui-button
            :color="COLORS.danger"
            :disabled="isCancelAccountDisabled"
            @clickbutton="isCancelAccountModalOpen = true"
          >
            Cancel account
          </ui-button>
        </div>
      </div>
    </ui-card>

    <ResetPasswordModal
      v-if="isResetPasswordModalOpen"
      @closeModal="closeModal"
    />
    <CancelAccountModal
      v-if="isCancelAccountModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
