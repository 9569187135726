<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DATE_FORMAT, reformatDateTime, sentenceCase } from '@emobg/web-utils';

import { ContentCellComponent, EditButton, GoogleMapComponent } from '@/components';
import EditPersonalSettings from './EditPersonalSettings';

export default {
  name: 'PersonalSettings',
  components: {
    EditButton,
    ContentCellComponent,
    GoogleMapComponent,
    EditPersonalSettings,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      mapMarkers: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => get(state, 'detail.data'),
    }),
    userName() {
      return `${get(this, 'user.info.firstName')} ${get(this, 'user.info.lastName')}`;
    },
  },
  created() {
    this.setCoordinates();
    this.DATE_FORMAT = DATE_FORMAT;
  },
  methods: {
    sentenceCase,
    ...mapActions(DOMAINS_MODEL.crm.users, ['getUser']),
    get,
    reformatDateTime,
    closeModal() {
      this.isModalOpen = false;
    },
    async onFormSuccess() {
      this.isLoading = true;
      await this.getUser(this.user.uuid);
      this.setCoordinates();
      this.isLoading = false;
    },
    setCoordinates() {
      this.mapMarkers = [
        {
          position: {
            lat: parseFloat(get(this, 'user.info.gpsLat')),
            lng: parseFloat(get(this, 'user.info.gpsLng')),
          },
          id: 'user',
        },
      ];
    },
  },
};
</script>
<template>
  <div class="PersonalSettings">
    <ui-card
      header="Personal information"
      class="position-relative"
      data-test-id="personal_settings-card"
    >
      <ui-loader
        v-show="isLoading"
        absolute
        data-test-id="loader"
      />
      <div class="d-flex justify-content-between align-items-top">
        <div class="w-100 row">
          <div class="col-md-6 col-lg-3">
            <ContentCellComponent
              label="Title, Full Name"
              class="mb-3"
            >
              <span v-if="get(user, 'info.title.name')">
                {{ sentenceCase(user.info.title.name) }},
              </span>
              {{ userName }}
            </ContentCellComponent>

            <ContentCellComponent
              :value="get(user, 'info.birthdate') ? reformatDateTime(get(user, 'info.birthdate'), DATE_FORMAT.dob) : FALLBACK_MESSAGE.dash"
              label="Date of birth"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(user, 'info.identityIssuingCountryName', FALLBACK_MESSAGE.dash)"
              label="ID issuing country"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(user, 'info.identityType', FALLBACK_MESSAGE.dash)"
              label="Document type"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(user, 'info.identityNumber', FALLBACK_MESSAGE.dash)"
              label="ID number"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(user, 'info.addressStreet1', FALLBACK_MESSAGE.dash)"
              label="Address"
              class="mb-3"
            />

            <ContentCellComponent
              :value="get(user, 'info.addressStreet2', FALLBACK_MESSAGE.dash)"
              label="Floor, door, apartment..."
              class="mb-3"
            />
            <ContentCellComponent
              v-if="get(user, 'info.extraDocuments.codiceFiscale')"
              :value="get(user, 'info.extraDocuments.codiceFiscale')"
              label="Fiscal code"
              class="mb-3"
            />
          </div>

          <div class="col-md-6 col-lg-9">
            <GoogleMapComponent
              v-if="!isLoading"
              :markers="mapMarkers"
            />
          </div>
        </div>

        <EditButton
          class="ml-2"
          @click="isModalOpen = true"
        />
      </div>
    </ui-card>

    <EditPersonalSettings
      v-if="isModalOpen"
      :callback="onFormSuccess"
      @closeModal="closeModal"
    />
  </div>
</template>
