var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Authentication" },
    [
      _c(
        "ui-card",
        {
          staticClass: "w-100",
          attrs: {
            header: "Authentication",
            "data-test-id": "authentication-card",
          },
        },
        [
          _c("div", { staticClass: "row d-flex align-items-center" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-3" },
              [
                _c("ContentCellComponent", {
                  staticClass: "mb-3",
                  attrs: {
                    value: _vm.user && _vm.user.idpUuid,
                    label: "Auth0 ID",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-3" },
              [
                _c(
                  "ui-button",
                  {
                    attrs: { face: _vm.FACES.outline },
                    on: {
                      clickbutton: function ($event) {
                        _vm.isResetPasswordModalOpen = true
                      },
                    },
                  },
                  [_vm._v(" Regenerate login ")]
                ),
              ],
              1
            ),
            !_vm.isResendButtonHidden
              ? _c(
                  "div",
                  { staticClass: "col-md-6 col-lg-3" },
                  [
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          face: _vm.FACES.outline,
                          loading: _vm.resendVerificationMailStatus.LOADING,
                        },
                        on: { clickbutton: _vm.resendVerificationMail },
                      },
                      [_vm._v(" Resend verification email ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-3" },
              [
                _c(
                  "ui-button",
                  {
                    attrs: {
                      color: _vm.COLORS.danger,
                      disabled: _vm.isCancelAccountDisabled,
                    },
                    on: {
                      clickbutton: function ($event) {
                        _vm.isCancelAccountModalOpen = true
                      },
                    },
                  },
                  [_vm._v(" Cancel account ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.isResetPasswordModalOpen
        ? _c("ResetPasswordModal", { on: { closeModal: _vm.closeModal } })
        : _vm._e(),
      _vm.isCancelAccountModalOpen
        ? _c("CancelAccountModal", {
            attrs: { callback: _vm.onFormSuccess },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }