var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DocumentUpload",
        attrs: {
          header: { isClosable: true },
          title: "Upload document",
          "data-test-id": "document_upload-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.users,
                    scope: _vm.USER_SCOPES.addDocument,
                    action: "upload document",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: !_vm.isFormValid,
                          loading: _vm.addDocumentStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.uploadDocument },
                      },
                      [_vm._v(" Upload ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("MuiInputText", {
                    staticClass: "w-100",
                    attrs: {
                      label: "Document name",
                      placeholder: "Enter name (e.g. Passport)",
                      name: "name",
                      "data-test-id": "name-input",
                    },
                    model: {
                      value: _vm.inputs.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "name", $$v)
                      },
                      expression: "inputs.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [_vm._v(" Description ")]
                  ),
                  _c("MuiTextarea", {
                    attrs: {
                      placeholder: "Enter description (e.g. OK)",
                      "data-test-id": "description-input",
                      name: "description",
                      rows: "1",
                    },
                    model: {
                      value: _vm.inputs.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "description", $$v)
                      },
                      expression: "inputs.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _vm.get(
                    _vm.drivingLicense,
                    "status",
                    _vm.DL_STATUSES.rejected
                  ) === _vm.DL_STATUSES.rejected
                    ? _c("ContentCellComponent", {
                        attrs: {
                          value: _vm.DOCUMENT_TYPES.users,
                          label: "Document type",
                          "data-test-id": "document_type-text",
                        },
                      })
                    : _c("MuiSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n            isRequired: true,\n          }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          options: _vm.map(_vm.DOCUMENT_TYPES, (type) => ({
                            label: type,
                            value: type,
                          })),
                          label: "Document type*",
                          placeholder: "Select",
                          name: "documentType",
                          "data-test-id": "document_type-select",
                        },
                        model: {
                          value: _vm.documentType,
                          callback: function ($$v) {
                            _vm.documentType = $$v
                          },
                          expression: "documentType",
                        },
                      }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [_vm._v(" Attachment* ")]
                  ),
                  _c(
                    "DragFileComponent",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.files",
                          value: {
                            isMaxSize: _vm.isMaxSize,
                            isRequired: true,
                          },
                          expression:
                            "{\n            isMaxSize,\n            isRequired: true,\n          }",
                          modifiers: { files: true },
                        },
                      ],
                      attrs: {
                        "draggable-height": 230,
                        "accepted-formats": "image/*,application/pdf",
                        "show-preview": "",
                        "data-test-id": "file-input",
                      },
                      model: {
                        value: _vm.inputs.document,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "document", $$v)
                        },
                        expression: "inputs.document",
                      },
                    },
                    [
                      _c("template", { slot: "extraInformation" }, [
                        _c(
                          "span",
                          { staticClass: "d-block emobg-font-x-small mt-2" },
                          [
                            _vm._v(
                              " Maximum size " +
                                _vm._s(_vm.maxSizeInMB) +
                                " MB (JPG, PNG, PDF) "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }