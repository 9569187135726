<script>
import get from 'lodash/get';
import each from 'lodash/each';
import first from 'lodash/first';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { base64WithoutName } from '@emobg/web-utils';
import {
  CancelButton,
  DragFileComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { USER_SCOPES } from '@/domains/CRM/Users/store/UsersModule';

export default {
  name: 'DrivingLicenseUpload',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    DragFileComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      maxSizeInMB: 10,
      isFormValid: false,
      inputs: {
        frontDocumentImg: null,
        backDocumentImg: null,
        selfieImg: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      drivingLicense: state => state.drivingLicense.data,
      drivingLicenseUploadStatus: state => state.drivingLicenseUpload.STATUS,
    }),
    actionText() {
      return this.drivingLicense ? 'Replace' : 'Upload';
    },
    dataForRequest() {
      const data = {};
      each(this.inputs, (item, key) => {
        const base64 = first(item);
        data[key] = base64 ? base64WithoutName(base64) : null;
      });

      return data;
    },
  },
  created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.licenseDocuments = [
      {
        label: 'Driving license (Front)*',
        key: 'frontDocumentImg',
      },
      {
        label: 'Driving license (Back)*',
        key: 'backDocumentImg',
      },
      {
        label: 'Selfie*',
        key: 'selfieImg',
      },
    ];
    this.resetState({ scopes: [USER_SCOPES.drivingLicenseUpload] });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['postUserDrivingLicense']),
    isMaxSize(files) {
      const file = get(files, '[0]');
      const maxSizeInBytes = this.maxSizeInMB * (10 ** 6);
      return {
        isValid: file && file.size < maxSizeInBytes,
        message: `File size should be maximum ${this.maxSizeInMB} MB`,
      };
    },
    async uploadDrivingLicense() {
      const action = this.drivingLicense ? 'replaced' : 'uploaded';
      await this.postUserDrivingLicense({
        userUuid: this.user.uuid,
        data: this.dataForRequest,
      });

      if (!this.drivingLicenseUploadStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: `Driving license successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :title="`${actionText} driving license`"
    :header="{ isClosable: true }"
    class="DrivingLicenseUpload v1-MuiModal--adaptative"
    data-test-id="driving_license_upload-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.drivingLicenseUpload"
        :action="`${actionText} driving license`"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <ui-alert
        :color="COLORS.warning"
        :icon="ICONS.alertFull"
        class="pb-3 d-flex w-100"
        data-test-id="warning_message-alert"
      >
        Ensure to upload the correct images. Once uploaded, these images can only be changed by invalidating the driving license.
      </ui-alert>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <template v-for="licenseDocument in licenseDocuments">
            <div
              :key="licenseDocument.key"
              class="col-md-6 col-lg-4"
            >
              <label
                class="d-block emobg-font-weight-semibold mb-1"
                v-text="licenseDocument.label"
              />
              <DragFileComponent
                v-model="inputs[licenseDocument.key]"
                v-validate.files="{
                  isMaxSize,
                  isRequired: true,
                }"
                :draggable-height="230"
                show-preview
                data-test-id="front_image-input"
              >
                <template slot="extraInformation">
                  <span class="d-block emobg-font-x-small mt-2">
                    Maximum size {{ maxSizeInMB }} MB (JPG, PNG)
                  </span>
                </template>
              </DragFileComponent>
            </div>
          </template>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />
        <ui-button
          :disabled="!isFormValid"
          :loading="drivingLicenseUploadStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="uploadDrivingLicense"
        >
          {{ actionText }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
