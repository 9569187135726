<script>
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import { PHONE_VALIDATIONS } from '@/utils';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  PhoneInputComponent,
  StoreNotificationComponent,
} from '@/components';
import UserStatusBadge from '../../../components/UserDetailsHeader/components/UserStatusBadge';
import { PAYMENT_MODES, PREMIUM_USER_OPTIONS } from './const/index.const';
import { USER_SCOPES } from '../../../../store/UsersModule';

export default {
  name: 'EditPersonalProfile',
  directives: {
    Validate,
  },
  components: {
    PhoneInputComponent,
    UserStatusBadge,
    CancelButton,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiSelect,
    MuiInputText,
    StoreNotificationComponent,
    ContentCellComponent,
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        email: null,
        phone: null,
        paymentMode: null,
        premiumUser: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
      editSettingsStatus: state => state.editSettings.STATUS,
    }),
    hasSameValues() {
      return isEqual(this.originalInputs, this.inputs);
    },
  },
  created() {
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.PREMIUM_USER_OPTIONS = PREMIUM_USER_OPTIONS;
    this.PAYMENT_MODES = PAYMENT_MODES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.USER_SCOPES = USER_SCOPES;
    this.inputs.email = get(this, 'user.profiles.personal.email');
    this.inputs.phone = get(this, 'user.profiles.personal.phoneNumber');
    this.inputs.premiumUser = get(this, 'user.info.isPremium');
    this.inputs.paymentMode = get(this, 'user.profiles.personal.paymentMode');
    this.originalInputs = cloneDeep(this.inputs);
    this.resetState({ scopes: [USER_SCOPES.editSettings] });
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['putUserPersonalProfile']),
    sentenceCase,
    map,
    async saveSettings() {
      await this.putUserPersonalProfile({
        userUuid: this.user.uuid,
        data: this.inputs,
      });

      if (!this.editSettingsStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Personal profile successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    title="Edit personal profile"
    :header="{ isClosable: true }"
    class="EditGeneralSettings"
    data-test-id="edit_personal_profile-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.editSettings"
        is-editing
        element="personal profile"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <ContentCellComponent
          label="Personal status"
          class="mb-5"
        >
          <UserStatusBadge :status="user.status.personal" />
        </ContentCellComponent>

        <div class="mb-4">
          <MuiInputText
            v-model="inputs.email"
            v-validate="{
              isRequired: true,
              isEmail: true,
            }"
            label="Notification email*"
            class="w-100"
            placeholder="Enter personal email"
            data-test-id="email"
            name="email"
          />
        </div>

        <div class="mb-4">
          <PhoneInputComponent
            v-model="inputs.phone"
            v-validate="{
              isRequired: true,
              isValidPhone: PHONE_VALIDATIONS.isValidPhone,
            }"
            label="Notification phone number*"
            class="w-100"
            placeholder="Enter personal phone"
            data-test-id="phone"
          />
        </div>

        <div class="mb-4">
          <MuiSelect
            v-model="inputs.premiumUser"
            :options="map(PREMIUM_USER_OPTIONS, (option, key) => ({ label: sentenceCase(key), value: option }))"
            label="Premium user*"
            placeholder="Select"
            data-test-id="premium-select"
            name="premium"
            class="w-100"
          />
        </div>

        <MuiSelect
          v-model="inputs.paymentMode"
          :options="map(PAYMENT_MODES, (option, key) => ({ label: sentenceCase(key), value: option }))"
          label="Payment mode*"
          placeholder="Select"
          data-test-id="payment_mode-select"
          name="payment-mode"
          class="w-100"
        />
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="editSettingsStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="saveSettings"
        >
          Save
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
