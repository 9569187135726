var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "UserDetailsHeader",
      attrs: { "data-test-id": "user_details_header" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.size(_vm.user)
              ? _c(
                  "OverviewCollapse",
                  {
                    attrs: { id: "userOverview" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-wrap align-items-center",
                                  attrs: { "data-test-id": "name" },
                                },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "emobg-font-weight-semibold mr-3",
                                    },
                                    [
                                      _vm.get(_vm.user, "info.title.name")
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "emobg-color-ink-light",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.sentenceCase(
                                                      _vm.user.info.title.name
                                                    )
                                                  ) +
                                                  ", "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(_vm.userName) + " "),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        _vm.user.idpUuid
                          ? {
                              key: "actions",
                              fn: function () {
                                return [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { face: _vm.FACES.outline },
                                      on: {
                                        clickbutton: function ($event) {
                                          _vm.isImpersonateModalVisible = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Impersonate ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "py-4" },
                      [
                        _vm.get(_vm.user, "invoicing.pendingPayment")
                          ? _c(
                              "ui-alert",
                              {
                                staticClass: "pb-4 d-block w-100",
                                attrs: {
                                  color: _vm.COLORS.warning,
                                  icon: _vm.ICONS.alertFull,
                                  "data-test-id": "warning_message-alert",
                                },
                              },
                              [
                                _vm._v(
                                  " User with unpaid invoice and/or open proforma invoice. "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "emobg-color-danger" },
                                  [
                                    _vm._v(
                                      "Pending payment (-" +
                                        _vm._s(
                                          _vm.user.invoicing.pendingPayment
                                        ) +
                                        ")"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Date of birth",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _vm.userAge
                                    ? [
                                        _c("p", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reformatDateTime(
                                                  _vm.user.info.birthdate,
                                                  _vm.DATE_FORMAT.dob
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "emobg-color-ink-light",
                                            attrs: { "data-test-id": "age" },
                                          },
                                          [
                                            _vm._v(
                                              " Age: " +
                                                _vm._s(_vm.userAge) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Residence",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { "data-test-id": "city" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.user,
                                              "info.city",
                                              _vm.FALLBACK_MESSAGE.dash
                                            )
                                          ) +
                                          ", " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.user,
                                              "info.country",
                                              _vm.FALLBACK_MESSAGE.dash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "emobg-color-ink-light mb-1",
                                      attrs: { "data-test-id": "language" },
                                    },
                                    [
                                      _vm._v(
                                        " Language: " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.user,
                                              "info.language.name",
                                              _vm.FALLBACK_MESSAGE.dash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "emobg-color-ink-light",
                                      attrs: { "data-test-id": "operator" },
                                    },
                                    [
                                      _vm._v(
                                        " Operator: " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.user,
                                              "info.operatorName",
                                              _vm.FALLBACK_MESSAGE.dash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.get(_vm.user, "info.email")
                                ? _c(
                                    "ContentCellComponent",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        label: "Account email",
                                        "is-on-header": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "CopyToClipboard",
                                        {
                                          attrs: {
                                            "data-test-id": "copy_email-button",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.user.info.email) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Phone number",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _vm.getUserPhone(_vm.PROFILES_TYPES.personal)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "d-flex",
                                          attrs: {
                                            "data-test-id": "personal_phone",
                                          },
                                        },
                                        [
                                          _vm._v(" Personal: "),
                                          _c(
                                            "CopyToClipboard",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "data-test-id":
                                                  "copy_personal_phone-action",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getUserPhone(
                                                      _vm.PROFILES_TYPES
                                                        .personal
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.getUserPhone(_vm.PROFILES_TYPES.business)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "d-flex",
                                          attrs: {
                                            "data-test-id": "business_phone",
                                          },
                                        },
                                        [
                                          _vm._v(" Business: "),
                                          _c(
                                            "CopyToClipboard",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                "data-test-id":
                                                  "copy_business_phone-action",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getUserPhone(
                                                      _vm.PROFILES_TYPES
                                                        .business
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm.hasNotes
                                ? _c(
                                    "ContentCellComponent",
                                    {
                                      attrs: {
                                        label: "Notes",
                                        "is-on-header": "",
                                      },
                                    },
                                    [
                                      _vm.get(_vm.user, "notes.hasAnnualCover")
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                              attrs: {
                                                "data-test-id":
                                                  "annual_cover-text",
                                              },
                                            },
                                            [
                                              _c("ui-icon", {
                                                attrs: {
                                                  icon: _vm.ICONS
                                                    .insuranceAnnual,
                                                  size: _vm.ICONS_SIZES.small,
                                                },
                                              }),
                                              _c("p", { staticClass: "ml-1" }, [
                                                _vm._v(
                                                  " Has annual insurance "
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.fleetSegmentText
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                              attrs: {
                                                "data-test-id":
                                                  "fleet_segment-text",
                                              },
                                            },
                                            [
                                              _c("ui-icon", {
                                                attrs: {
                                                  icon: _vm.ICONS.userCircle,
                                                  size: _vm.ICONS_SIZES.small,
                                                },
                                              }),
                                              _c("p", { staticClass: "ml-1" }, [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.fleetSegmentText
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c("SiftScoreCell", {
                                staticClass: "mb-4",
                                attrs: {
                                  score: _vm.user.info.profilingScore,
                                  "data-test-id": "sift_score",
                                },
                              }),
                              _vm.user.status.personal
                                ? _c(
                                    "ContentCellComponent",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        label: "Personal status",
                                        "is-on-header": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("UserStatusBadge", {
                                            attrs: {
                                              status: _vm.user.status.personal,
                                              "data-test-id":
                                                "personal_status-badge",
                                            },
                                          }),
                                          _vm.isEditableStatus
                                            ? _c("ui-icon", {
                                                staticClass:
                                                  "ml-1 cursor-pointer",
                                                attrs: {
                                                  icon: _vm.ICONS.edit,
                                                  color: _vm.GRAYSCALE.inkLight,
                                                  size: _vm.ICONS_SIZES.small,
                                                  "data-test-id":
                                                    "personal_status-action",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openStatusModal(
                                                      _vm.PROFILES_TYPES
                                                        .personal
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.user.status.business
                                ? _c(
                                    "ContentCellComponent",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        label: "Business status",
                                        "is-on-header": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                        },
                                        [
                                          _c("UserStatusBadge", {
                                            attrs: {
                                              status: _vm.user.status.business,
                                              "data-test-id":
                                                "business_status-badge",
                                            },
                                          }),
                                          _c("ui-icon", {
                                            staticClass: "ml-1 cursor-pointer",
                                            attrs: {
                                              icon: _vm.ICONS.edit,
                                              color: _vm.GRAYSCALE.inkLight,
                                              size: _vm.ICONS_SIZES.small,
                                              "data-test-id":
                                                "business_status-action",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openStatusModal(
                                                  _vm.PROFILES_TYPES.business
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Alerts",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _vm.user.alerts.length
                                    ? [
                                        _vm._l(
                                          _vm.user.alerts.slice(
                                            0,
                                            _vm.shownAlerts
                                          ),
                                          function (alert, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "d-flex align-items-center py-1",
                                                attrs: {
                                                  "data-test-id": `alert_${index}`,
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "emobg-border-radius-large p-2 emobg-background-color-warning d-inline-block mr-1",
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(alert) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm.user.alerts.length > _vm.shownAlerts
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "cursor-pointer emobg-font-weight-semibold emobg-color-primary",
                                                attrs: {
                                                  "data-test-id":
                                                    "show_alerts-action",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.shownAlerts =
                                                      _vm.user.alerts.length
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Show all (" +
                                                    _vm._s(
                                                      _vm.user.alerts.length
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center",
                                          attrs: {
                                            "data-test-id": "no_alerts",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "emobg-border-radius-large p-2 emobg-background-color-ground-light d-inline-block mr-1",
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "emobg-color-ink-light",
                                            },
                                            [_vm._v(" No alerts ")]
                                          ),
                                        ]
                                      ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c("UserBookingLink", {
                                attrs: {
                                  booking: _vm.user.bookings.upcoming,
                                  label: "Upcoming booking",
                                },
                              }),
                              _c("UserBookingLink", {
                                attrs: {
                                  booking: _vm.user.bookings.inProgress,
                                  label: "Booking in progress",
                                },
                              }),
                              _c("UserBookingLink", {
                                attrs: {
                                  booking: _vm.user.bookings.lastFinished,
                                  label: "Last finished booking",
                                },
                              }),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Total finished bookings",
                                    "is-on-header": "",
                                    "data-test-id":
                                      "total_finished_bookings-text",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.user.bookings.totalFinished) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Premium user",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "ui-badge",
                                    {
                                      attrs: {
                                        color: _vm.GRAYSCALE.ground,
                                        "data-test-id": "is_premium-badge",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.premiumUserText) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-3" },
                            [
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Created",
                                    "is-on-header": "",
                                    "data-test-id": "created_at-text",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.info.createdAt
                                          ? _vm.reformatDateTime(
                                              _vm.user.info.createdAt,
                                              _vm.DATE_FORMAT.dob
                                            )
                                          : _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Activated",
                                    "is-on-header": "",
                                    "data-test-id": "activated_at-text",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.info.activationDate
                                          ? _vm.reformatDateTime(
                                              _vm.user.info.activationDate,
                                              _vm.DATE_FORMAT.dob
                                            )
                                          : _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Remaining promo credit",
                                    "is-on-header": "",
                                    "data-test-id": "promo_credit-text",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.invoicing.promoCredit ||
                                          _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Total revenue",
                                    "is-on-header": "",
                                    "data-test-id": "total_revenue-text",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.invoicing.totalRevenue ||
                                          _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Tariff",
                                    "is-on-header": "",
                                    "data-test-id": "tariff-text",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.invoicing.tariff
                                          ? _vm.sentenceCase(
                                              _vm.user.invoicing.tariff
                                            )
                                          : _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.isStatusModalVisible
        ? _c("UserStatusModal", {
            attrs: {
              profile: _vm.statusModalProfile,
              "on-success": _vm.onSuccess,
            },
            on: { closeModal: _vm.closeStatusModal },
          })
        : _vm._e(),
      _vm.isImpersonateModalVisible
        ? _c("UserImpersonationRequest", {
            attrs: {
              "on-success": () => (_vm.isImpersonateLogOutModalVisible = true),
            },
            on: {
              closeModal: function ($event) {
                _vm.isImpersonateModalVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.isImpersonateLogOutModalVisible
        ? _c("UserImpersonateLogOutModal", {
            on: {
              closeModal: function ($event) {
                _vm.isImpersonateLogOutModalVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }