var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "SiftScoreCell" }, [
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("h3", { staticClass: "mr-1" }, [_vm._v(" Sift score ")]),
      _c(
        "div",
        {
          on: {
            mouseleave: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c(
            "MuiPopper",
            {
              attrs: {
                show: _vm.isVisible,
                "arrow-class": `emobg-background-color-secondary`,
                options: {
                  placement: _vm.PLACEMENTS.top,
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 12],
                      },
                    },
                  ],
                },
                arrow: "",
                "popper-class": "fade",
              },
              scopedSlots: _vm._u([
                {
                  key: "popper",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "v1-MuiTooltip__tooltip px-2 py-3 emobg-font-weight-light emobg-border-radius-medium emobg-background-color-secondary emobg-color-white",
                        },
                        [
                          _c("p", [
                            _vm._v("Sift Score is a number between 0 and 100"),
                          ]),
                          _c("ul", { staticClass: "ml-4" }, [
                            _c("li", [_vm._v("0 indicates low risk")]),
                            _c("li", [_vm._v("100 indicates high risk")]),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                {
                  on: {
                    mouseenter: function ($event) {
                      _vm.isVisible = true
                    },
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                    attrs: {
                      icon: _vm.ICONS.infoFull,
                      size: _vm.SIZES.small,
                      "data-test-id": "icon",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "emobg-body-1 emobg-font-weight-semibold mt-1",
        class: _vm.scoreColor,
      },
      [
        _vm._v(
          " " +
            _vm._s(
              _vm.isNumber(_vm.score) ? _vm.score : _vm.FALLBACK_MESSAGE.noData
            ) +
            " "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }