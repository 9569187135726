import get from 'lodash/get';
import join from 'lodash/join';
import snakeCase from 'lodash/snakeCase';
import upperFirst from 'lodash/upperFirst';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  isFalsyString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';

import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS } from '@emobg/vue-base';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { USER_STATUS_COLORS } from '../constants/userStatus';

import {
  AlertsTemplate,
  EmailTemplate,
  PhoneTemplate,
} from '../../../../templates';

export function contentCells() {
  return [
    {
      title: 'ID',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.account,
          params: {
            userUuid: result.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `#${result.id}`,
      }),
    },
    {
      title: 'Name',
      displayPriority: 1,
      minWidth: 220,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.account,
          params: {
            userUuid: result.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: `${result.first_name} ${result.last_name}`,
      }),
    },
    {
      title: 'Status',
      displayPriority: 1,
      minWidth: 260,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: result => ({
        text: sentenceCase(result.open_fleet_status),
        color: USER_STATUS_COLORS[snakeCase(result.open_fleet_status)] || COLORS.danger,
      }),
    },
    {
      attributeName: 'city',
      title: 'City',
      displayPriority: 1,
      minWidth: 140,
    },
    {
      attributeName: 'created_at',
      title: 'Sign up',
      displayPriority: 2,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended),
    },
    {
      title: 'Alerts',
      attributeName: 'alerts',
      columnRatio: 1,
      displayPriority: 1,
      type: RECORD_TYPES.template,
      minWidth: 40,
      template: result => {
        const alertsNumber = get(result, 'alerts.length');
        if (alertsNumber) {
          return `<ui-badge color="warning" solid="true">${alertsNumber}</ui-badge>`;
        }
        return FALLBACK_MESSAGE.dash;
      },
      isCollapseHidden: true,
    },
    {
      title: 'Alert reasons',
      attributeName: 'alerts',
      minWidth: 245,
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: AlertsTemplate,
      props: result => ({
        result,
        alerts: result.alerts,
      }),
      columnRatio: 1,
      transformValue: alerts => join(alerts, ', '),
    },
    {
      attributeName: 'personal_email',
      title: 'Personal email',
      displayPriority: 0,
      minWidth: 250,
      type: RECORD_TYPES.component,
      component: EmailTemplate,
      props: result => ({
        result,
        email: result.personal_email,
      }),
    },
    {
      attributeName: 'personal_phone',
      title: 'Personal phone',
      displayPriority: 0,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PhoneTemplate,
      props: result => ({
        result,
        phone: result.personal_phone,
      }),
    },
    {
      attributeName: 'employee.company.commercial_name',
      title: 'Company',
      displayPriority: 0,
      minWidth: 120,
      transformValue: value => value || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'business_email',
      title: 'Business email',
      displayPriority: 0,
      minWidth: 250,
      type: RECORD_TYPES.component,
      component: EmailTemplate,
      props: result => ({
        result,
        email: result.business_email,
      }),
    },
    {
      attributeName: 'business_phone',
      title: 'Business phone',
      displayPriority: 0,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PhoneTemplate,
      props: result => ({
        result,
        phone: result.business_phone,
      }),
    },
  ];
}

export function userListFacets() {
  return [
    {
      type: 'refinementList',
      props: {
        title: upperFirst(this.$t('Common.Business.city')),
        attributeName: 'city',
      },
    },
    {
      type: 'rangedatetimepicker',
      props: {
        title: this.$t('Common.Actions.sign_up'),
        attributeName: 'created_at_ts',
        labels: {
          from: this.$t('Common.Comparisons.from'),
          to: this.$t('Common.Comparisons.to'),
          removeButton: this.$t('Common.Actions.clear_element', { element: this.$t('Common.Time.dates') }),
        },
        timezone: this.operatorTimezone || TIME_ZONE.default,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('Common.Descriptions.status_element', { element: upperFirst(this.$t('Common.Descriptions.personal')) }),
        attributeName: 'open_fleet_status',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('Common.Descriptions.status_element', { element: upperFirst(this.$t('Common.Descriptions.business')) }),
        attributeName: 'dedicated_fleet_status',
        transformValue: value => (isFalsyString(value) ? 'Not defined' : value),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Company',
        attributeName: 'employee.company.commercial_name',
        transformValue: value => value || FALLBACK_MESSAGE.dash,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Alerts',
        attributeName: 'alerts',
      },
    },
  ];
}
