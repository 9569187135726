var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ContentCellComponent",
    {
      staticClass: "mb-4",
      attrs: {
        label: _vm.label,
        "data-test-id": _vm.kebabCase(_vm.label),
        "is-on-header": "",
      },
    },
    [
      !_vm.booking
        ? _c("p", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")])
        : _c(
            "PermissionLink",
            {
              staticClass: "emobg-font-weight-semibold",
              attrs: {
                "link-permissions": [
                  _vm.CARSHARING_PERMISSIONS.viewCarsharingBookings,
                ],
                to: {
                  name: _vm.carsharing.bookings.detail.index,
                  params: {
                    bookingUuid: _vm.booking.uuid,
                  },
                },
                "data-test-id": "booking-link",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.reformatDateTime(_vm.booking.date, _vm.DATE_FORMAT.dob)
                  ) +
                  " "
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }