export const USE_CASE = Object.freeze({
  openPersonal: 'open_personal',
  openBusiness: 'open_business',
  dedicated: 'dedicated',
});

export const USE_CASE_OPTIONS = Object.freeze([
  {
    value: USE_CASE.openPersonal,
    label: 'Open Fleet User Tariff (B2C Tariff, Personal Profile)',
  },
  {
    value: USE_CASE.openBusiness,
    label: 'Open Fleet Company Tariff (Business Profile)',
  },
  {
    value: USE_CASE.dedicated,
    label: 'Dedicated Fleet (a dedicated fleet of a company)',
  },
]);
