<script>
import moment from 'moment';
import assign from 'lodash/assign';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT, DATE_FORMAT_KEYS, sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { USER_SCOPES } from '@domains/CRM/Users/store/UsersModule';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  ContentCellComponent,
  GalleryComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { DATE_INPUT_VALIDATIONS } from '@/utils';

import { isUK } from '../drivingLicense.const';

export default {
  name: 'DrivingLicenseEdit',
  components: {
    ContentCellComponent,
    GalleryComponent,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiDatePicker,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    startIndex: {
      type: Number,
      default: 0,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        birthdate: '',
        firstIssueDate: '',
        physicalIssueDate: '',
        expiration: '',
        licenseNumber: '',
        issuingCountryUuid: null,
        drivingCategories: '',
        dlvaCode: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.users, {
      drivingLicense: state => state.drivingLicense.data,
      user: state => state.detail.data,
      drivingLicenseEditStatus: state => state.drivingLicenseEdit.STATUS,
    }),
    userName() {
      return `${get(this, 'user.info.firstName')} ${get(this, 'user.info.lastName')}`;
    },
    isSaveDisabled() {
      return !this.isFormValid || isEqual(this.originalDrivingLicense, this.dataToSend);
    },
    dataToSend() {
      return {
        ...this.inputs,
        birthdate: moment.isMoment(this.inputs.birthdate)
          ? this.inputs.birthdate.format(DATE_FORMAT.date)
          : this.inputs.birthdate,
        firstIssueDate: moment.isMoment(this.inputs.firstIssueDate)
          ? this.inputs.firstIssueDate.format(DATE_FORMAT.date)
          : this.inputs.firstIssueDate,
        physicalIssueDate: moment.isMoment(this.inputs.physicalIssueDate)
          ? this.inputs.physicalIssueDate.format(DATE_FORMAT.date)
          : this.inputs.physicalIssueDate,
        expiration: moment.isMoment(this.inputs.expiration)
          ? this.inputs.expiration.format(DATE_FORMAT.date)
          : this.inputs.expiration,
      };
    },
    modalButtons() {
      return this.isPreview ? [] : [
        {
          text: 'Cancel',
          attributes: {
            face: this.FACES.text,
            color: this.GRAYSCALE.inkLight,
          },
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          text: 'Save',
          attributes: {
            disabled: this.isSaveDisabled,
            loading: this.drivingLicenseEditStatus.LOADING,
          },
          listeners: {
            click: () => this.saveDrivingLicense(),
          },
        },
      ];
    },
  },
  created() {
    this.resetState({ scopes: [USER_SCOPES.drivingLicenseEdit] });
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.USER_SCOPES = USER_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.drivingLicenseImages = [
      {
        label: 'Driving license (Front)',
        src: this.drivingLicense.frontDocumentImg,
      },
      {
        label: 'Driving license (Back)',
        src: this.drivingLicense.backDocumentImg,
      },
      {
        label: 'Selfie',
        src: this.drivingLicense.selfieImg,
      },
    ];

    const inputKeys = keys(this.inputs);
    this.originalDrivingLicense = pick(this.drivingLicense, inputKeys);
    assign(this.inputs, this.originalDrivingLicense);
    this.inputs.birthdate = this.inputs.birthdate ? moment(this.inputs.birthdate) : '';
    this.inputs.expiration = this.inputs.expiration ? moment(this.inputs.expiration) : '';
    this.inputs.firstIssueDate = this.inputs.firstIssueDate ? moment(this.inputs.firstIssueDate) : '';
    this.inputs.physicalIssueDate = this.inputs.physicalIssueDate ? moment(this.inputs.physicalIssueDate) : '';
  },
  methods: {
    get,
    isUK,
    sentenceCase,
    ...mapMutations(DOMAINS_MODEL.crm.users, [
      'resetState',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.users, ['putUserDrivingLicense']),
    async saveDrivingLicense() {
      await this.putUserDrivingLicense({
        userUuid: this.user.uuid,
        drivingLicenseUuid: this.drivingLicense.uuid,
        data: this.dataToSend,
      });

      if (!this.drivingLicenseEditStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Driving license successfully <span class="emobg-font-weight-semibold">edited</span>',
          textAction: '',
        });

        this.callback();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    v-bind="$attrs"
    :title="isPreview ? 'Driving license preview' : 'Edit driving license'"
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    class="DrivingLicenseEdit v1-MuiModal--adaptative"
    data-test-id="driving_license_edit-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.users"
        :scope="USER_SCOPES.drivingLicenseEdit"
        action="Edit driving license"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        class="row"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div
          v-if="!isPreview"
          class="col-3"
        >
          <ContentCellComponent
            label="Title, Full Name"
            class="mb-4"
            data-test-id="user-cell"
          >
            <span v-if="get(user, 'user.info.title.name')">
              {{ sentenceCase(user.info.title.name) }}
            </span>
            {{ userName }}
          </ContentCellComponent>
          <div class="mb-4">
            <MuiDatePicker
              v-model="inputs.birthdate"
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date-format-key="DATE_FORMAT_KEYS.dob"
              skip-time-step
              label="Date of birth*"
              data-test-id="birthdate-select"
              class="w-100"
            />
          </div>
          <div class="mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.issuingCountryUuid"
              v-validate="{
                isRequired: true,
              }"
              :index="ALGOLIA_INDEXES.countries"
              :title="country => country.name"
              path-value="uuid"
              label="Country of issue*"
              class="w-100"
              data-test-id="country-select"
              name="issuingCountryUuid"
            />
          </div>
          <div class="mb-4">
            <MuiDatePicker
              v-model="inputs.physicalIssueDate"
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date-format-key="DATE_FORMAT_KEYS.dob"
              skip-time-step
              label="Date of issue*"
              data-test-id="physsical_issue_date-select"
              class="w-100"
            />
          </div>
          <div class="mb-4">
            <MuiDatePicker
              v-model="inputs.expiration"
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date-format-key="DATE_FORMAT_KEYS.dob"
              skip-time-step
              label="Expiry date*"
              data-test-id="expiration-select"
              class="w-100"
            />
          </div>
          <div class="mb-4">
            <MuiInputText
              v-model="inputs.licenseNumber"
              v-validate="{
                isRequired: true,
              }"
              label="License number*"
              class="w-100"
              data-test-id="license_number-input"
              name="licenseNumber"
            />
          </div>
          <div class="mb-4">
            <MuiDatePicker
              v-model="inputs.firstIssueDate"
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date-format-key="DATE_FORMAT_KEYS.dob"
              skip-time-step
              label="First issuing date of category*"
              data-test-id="first_issue_date-select"
              class="w-100"
            />
          </div>
          <div class="mb-4">
            <MuiInputText
              v-model="inputs.drivingCategories"
              v-validate="{
                isRequired: true,
              }"
              label="Driving categories*"
              class="w-100"
              data-test-id="categories-input"
              name="drivingCategories"
            />
          </div>
          <div v-if="isUK(get(drivingLicense, 'issuingCountryName', ''))">
            <MuiInputText
              v-model="inputs.dlvaCode"
              v-validate="{
                isRequired: true,
              }"
              label="DVLA check code*"
              class="w-100"
              data-test-id="dlva-input"
              name="dlvaCode"
            />
          </div>
        </div>
        <div class="col">
          <GalleryComponent
            :images="drivingLicenseImages"
            :start-index="startIndex"
            data-test-id="gallery-component"
            class="h-100"
          />
        </div>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
