var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UsersLogs", attrs: { "data-test-id": "users_logs" } },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Impersonation requests ")]),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "impersonationLogs",
            attrs: {
              "data-test-id": "list",
              index: _vm.algoliaIndex,
              "table-config": _vm.algoliaConfig,
              "export-columns": _vm.algoliaConfig,
              filters: _vm.algoliaFilters,
              facets: _vm.algoliaFacets,
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }